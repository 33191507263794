<template>
  <SettingsCard
    :title="$t('settings.company.ai.title')"
    :subtitle="$t('settings.company.ai.subtitle')"
    icon="mdi-brain"
    :is-default-open="false"
  >
    <div class="px-4 pt-4">
      <v-row>
        <v-col
          cols="12"
          sm="6"
          class="px-0 px-md-2"
        >
          <div>
            <div class="d-flex align-center">
              <span class="font-weight-bold">
                {{ $t('settings.company.ai.body.title') }}
              </span>
            </div>
            <span class="grey--text">
              {{ $t('settings.company.ai.body.text') }}<br>
            </span>
          </div>
        </v-col>

        <v-col
          cols="12"
          sm="6"
          class="justify-center px-0 mt-4 d-flex px-md-2 d-flex flex-column"
        >
          <v-autocomplete
            v-model="country"
            :items="availableCountryItems"
            item-text="text"
            item-value="value"
            :label="$t('settings.company.ai.country-field.label')"
            outlined
            hide-details
            :placeholder="$t('product-finder.basic-info.country-placeholder')"
            class="pointer mb-6"
            :loading="loading.country"
            return-object
            @blur="update({ country: country?.value })"
          >
            <template v-slot:selection="data">
              <span>{{ data.item.text }}</span>
            </template>
            <template v-slot:item="data">
              <span class="ml-2">{{ data.item.text }}</span>
            </template>
          </v-autocomplete>

          <div class="w-full mb-6">
            <IndustryField
              v-model="industry"
              :loading="loading.industry"
              :label="$t('settings.company.ai.industry-field.label')"
              @blur="update({ industry: industry?.value })"
            />
          </div>
          <div class="w-full mb-6">
            <v-textarea
              v-model="businessDescription"
              :loading="loading.businessDescription"
              :label="$t('settings.company.ai.businessDescription-field.label')"
              :placeholder="$t('product-finder.enter-description-placeholder')"
              rows="4"
              hide-details
              outlined
              @blur="update({ businessDescription })"
            />
          </div>
          <CheckTextField
            v-model="website"
            :label="$t('settings.company.website.labels.url-website')"
            placeholder="https://www.max-makler.de"
            :rules="[rules.url]"
            outlined
            validate-on-input
            :loading="loading.websiteUrl"
            @blur="update({ websiteUrl: website ? formatHttpsUrl(website) : '' })"
          />
        </v-col>
      </v-row>
    </div>
  </SettingsCard>
</template>

<script>
import SettingsCard from '@/components/SettingsCard.vue'
import UPDATE_COMPANY_META_DATA from './queries/UpdateCompanyMetaData.gql'
import feature from '@/mixins/feature'

import bus, { eventNames } from '@/lib/eventBus'
import IndustryField from '@/components/product-finder/IndustryField.vue'
import countries from '@/mixins/countries'
import CheckTextField from '@/components/forms/CheckTextField.vue'
import { url } from '@/lib/validation'
import { formatHttpsUrl } from '@/lib/urlFormatter'

export default {
  components: {
    SettingsCard,
    IndustryField,
    CheckTextField
  },
  mixins: [feature, countries],
  props: {
    company: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      loading: {},
      rules: { url },
      industry: { },
      country: null,
      businessDescription: '',
      website: '',
      slugError: null,
      slugSuccess: null
    }
  },
  watch: {
    company: {
      handler () {
        this.industry = { value: this.company.meta?.industry, text: this.company.meta?.industry, key: this.company.meta?.industry }
        this.businessDescription = this.company.meta?.businessDescription
        this.website = this.company.links.website
        this.country = this.availableCountryItems.find(item => item.value === this.company.country?.toUpperCase())
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    formatHttpsUrl,
    setLoadingForInput (input, value) {
      this.loading = { ...this.loading, ...Object.keys(input).reduce((acc, key) => ({ ...acc, [key]: value }), {}) }
    },
    async update (input) {
      try {
        this.setLoadingForInput(input, true)
        await this.$apollo.mutate({
          mutation: UPDATE_COMPANY_META_DATA,
          variables: { input }
        })
        await new Promise(resolve => setTimeout(resolve, 1000))
        bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'success', text: this.$t('alerts.settings.company.success') })
      } catch (e) {
        bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'error', text: this.$t('alerts.settings.company.error') })
      } finally {
        this.setLoadingForInput(input, false)
      }
    }
  }
}
</script>
