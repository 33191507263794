<template>
  <div>
    <v-btn
      class="px-12 primary"
      @click.stop="show = true"
    >
      {{ $t('labels.activate') }}
    </v-btn>
    <v-dialog
      v-model="show"
      width="550"
    >
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar
            color="primary"
            dark
            flat
          >
            <v-toolbar-title>{{ $t('settings.user.activate-auth-package-dialog.title') }}</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-btn
                icon
                dark
                @click="show = false"
              >
                <v-icon large>
                  mdi-close
                </v-icon>
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>

        <v-card-text
          v-if="state === INITIAL"
          class="mt-4 text-body-1"
        >
          <div class="pb-4">
            <div class="mb-2 font-weight-bold">
              {{ $t('settings.user.activate-auth-package-dialog.body-texts.0') }}
            </div>
            <AuthorizationBenefits />
            <div class="mt-6 font-weight-bold">
              {{ $t('settings.user.activate-auth-package-dialog.body-texts.1') }}
            </div>
            <p
              v-html="$t('settings.user.activate-auth-package-dialog.runtime', { date: formatDate(contractDetails?.endDateIfCancelledNow, 'DATE_LONG') })"
            />
            <div class="font-weight-bold">
              {{ $t('settings.user.activate-auth-package-dialog.body-texts.2') }}
            </div>
            <p>
              <span
                v-html="$t('settings.user.activate-auth-package-dialog.price', { price: price?.price, currency: price?.currency })"
              />
              <a
                href="https://hilfe.bottimmo.com/portal/de/kb/articles/add-on"
                target="_blank"
              >{{ $t('settings.user.activate-auth-package-dialog.link') }}</a>
            </p>
            <span v-if="useIubenda">
              Es gelten im Übrigen die <IubendaDocumentLink type="terms" />.
            </span>
            <router-link
              v-else
              to="/license-agreement"
              class="no-border"
              target="_blank"
            >
              {{ $t('settings.user.activate-auth-package-dialog.disclaimer') }}
            </router-link>
          </div>
        </v-card-text>

        <v-card-text
          v-if="state === SPINNER"
          class="mt-4 text-body-1"
        >
          <div class="mb-16 d-flex flex-column">
            <v-progress-circular
              class="mx-auto my-16"
              color="primary"
              width="10"
              size="150"
              indeterminate
            />
            <p class="mx-12 my-4 text-center">
              {{ $t('alerts.settings.user.activate-auth-package-dialog.in-progress') }}...
            </p>
          </div>
        </v-card-text>

        <v-card-text
          v-if="state === SUCCESS"
          class="mt-4 text-body-1"
        >
          <div class="mb-8 d-flex flex-column">
            <v-icon
              size="150"
              color="primary"
              class="my-8 white"
            >
              mdi-check-circle
            </v-icon>
            <p
              class="mx-12 text-center"
              v-html="$t('alerts.settings.user.activate-auth-package-dialog.ready')"
            />
          </div>
        </v-card-text>

        <v-card-actions v-if="state === INITIAL">
          <v-btn
            class="mx-auto mb-8 pa-6"
            color="primary"
            :disabled="isLoading"
            :loading="isLoading"
            @click=" bookAuthorization"
          >
            {{ $t('buttons.activate-auth-package-dialog.cta') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import eventBus, { eventNames } from '@/lib/eventBus'
import featureMixin from '@/mixins/feature'
import BOOK_AUTHORIZATION from './queries/BookAuthorization.gql'
import USERS from './queries/Users.gql'
import AuthorizationBenefits from './AuthorizationBenefits.vue'
import billwerkPortalMixin from '@/mixins/billwerkPortal'
import IubendaDocumentLink from '@/modules/iubenda/DocumentLink.vue'

const STATES = {
  INITIAL: 'INITIAL',
  SPINNER: 'SPINNER',
  SUCCESS: 'SUCCESS'
}

export default {
  components: {
    AuthorizationBenefits,
    IubendaDocumentLink
  },
  mixins: [
    billwerkPortalMixin,
    featureMixin
  ],
  props: {
    price: { type: Object, default: () => ({}) }
  },
  data () {
    return {
      show: false,
      isLoading: false,
      state: STATES.INITIAL,
      ...STATES
    }
  },
  computed: {
    useIubenda () {
      return this.isFeatureActive(this.featureNames.IUBENDA)
    }
  },
  methods: {
    async  bookAuthorization () {
      try {
        this.isLoading = true
        await this.$apollo.mutate({
          mutation: BOOK_AUTHORIZATION,
          refetchQueries: [{ query: USERS, variables: { companyId: this.$auth.user.companyId } }]
        })
        this.state = STATES.SPINNER
        setTimeout(() => {
          this.state = STATES.SUCCESS
          setTimeout(async () => {
            await this.$features.loadAllFeaturesForCompany(this.$auth.user.companyId, { reload: true })
            this.$router.go(0)
            setTimeout(() => {
              this.show = false
              this.state = STATES.INITIAL
            }, 3000)
          }, 6000)
        }, 4000)
      } catch (e) {
        eventBus.$emit(eventNames.SHOW_SNACKBAR, { color: 'error', text: 'Upgrade konnte nicht gebucht werden.' })
      } finally {
        this.isLoading = false
      }
    }
  }
}
</script>
