<template>
  <v-container class="pa-0 layout-container">
    <AccountSection
      v-if="company"
      :company="company"
    />

    <v-divider
      class="my-4"
    />

    <SubscriptionSection
      v-if="company"
      :company="company"
    />
  </v-container>
</template>

<script>
import SubscriptionSection from './subscription'
import AccountSection from './account'
import COMPANY from '@/modules/company/queries/Company.gql'
import billwerkPortal from '@/mixins/billwerkPortal'
import brandingMixing from '@/mixins/branding'

export default {
  components: {
    SubscriptionSection,
    AccountSection
  },
  mixins: [billwerkPortal, brandingMixing], // provide contractDetails to children

  apollo: {
    company: {
      query: COMPANY,
      variables () {
        return {
          id: this.$auth.user.companyId
        }
      }
    }
  }
}
</script>
