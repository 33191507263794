<template>
  <v-container class="layout-container pa-0">
    <UserSettings />
  </v-container>
</template>

<script>
import UserSettings from './UserSettings.vue'

export default {
  components: {
    UserSettings
  }
}
</script>
