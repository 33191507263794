<template>
  <SettingsCard
    :title="$t('settings.email.sender-settings.title')"
    :subtitle="$t('settings.email.sender-settings.subtitle')"
    icon="mdi-email-fast-outline"
    :is-default-open="false"
  >
    <v-row class="ma-2">
      <v-col
        cols="12"
        md="6"
      >
        <div class="font-weight-bold">
          {{ $t('settings.email.sender-settings.body-texts.0') }}
        </div>
        <div class="grey--text">
          {{ $t('settings.email.sender-settings.body-texts.1') }}
        </div>
        <div class="font-weight-bold mt-4">
          {{ $t('settings.email.sender-settings.body-texts.2') }}
        </div>
        <div class="grey--text">
          {{ $t('settings.email.sender-settings.body-texts.3') }}
        </div>
        <div class="font-weight-bold mt-4">
          {{ $t('settings.email.sender-settings.body-texts.4') }}
        </div>
        <div class="grey--text">
          {{ $t('settings.email.sender-settings.body-texts.5') }}
        </div>
      </v-col>
      <v-col
        cols="12"
        offset="1"
        md="5"
      >
        <v-card
          outlined
          class="my-4 pa-2"
        >
          <v-img src="https://static.bottimmo.com/images/assets/app-branding/email-sender.png?h=800" />
        </v-card>
      </v-col>
    </v-row>
    <div class="ma-2 d-flex">
      <v-spacer />
      <CreateSenderDialog
        :company-name="company?.name"
        @opened="senderCreated = false"
        @created="senderCreated = true"
      />
    </div>
    <v-alert
      v-if="senderCreated"
      class="ma-4"
      border="left"
      colored-border
      type="info"
      elevation="2"
    >
      <h3 class="info--text">
        {{ $t('alerts.settings.email.success') }}
      </h3>
      <div>{{ $t('alerts.settings.email.info') }}</div>
    </v-alert>
    <v-simple-table class="ma-2">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">
              {{ $t('settings.email.sender-settings.labels.sender-name') }}
            </th>
            <th class="text-left">
              {{ $t('settings.email.sender-settings.labels.sender-email') }}
            </th>
            <th class="text-left">
              {{ $t('settings.email.sender-settings.labels.reply-to-email') }}
            </th>
            <th class="text-center">
              {{ $t('settings.email.sender-settings.labels.verified') }}
            </th>
            <th class="text-center">
              {{ $t('settings.email.sender-settings.labels.active') }}
            </th>
            <th class="text-center" />
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="sender of senders"
            :key="sender.id"
            :class="sender.isDefault ? 'font-weight-bold': 'grey--text'"
          >
            <td>{{ sender.name }}</td>
            <td>{{ sender.fromEmail }}</td>
            <td>{{ sender.replyToEmail }}</td>
            <td>
              <div class="d-flex justify-center">
                <v-icon
                  v-if="sender.isVerified"
                  color="success"
                >
                  mdi-check-circle
                </v-icon>
                <VerifySenderDialog
                  v-else
                  :sender="sender"
                />
              </div>
            </td>
            <td>
              <div class="d-flex justify-center">
                <v-icon
                  v-if="sender.isDefault"
                  color="success"
                >
                  mdi-check-circle
                </v-icon>
                <v-btn
                  v-else-if="sender.isVerified"
                  color="success"
                  outlined
                  :disabled="isLoading"
                  :loading="isLoading"
                  @click="setAsDefaultSender(sender.id)"
                >
                  {{ $t('labels.activate') }}
                </v-btn>
              </div>
            </td>
            <td>
              <div class="d-flex justify-center">
                <UpdateSenderDialog :sender="sender" />
              </div>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </SettingsCard>
</template>

<script>
import HOPPERMATION_SENDER from './queries/HoppermationSenders.gql'
import SET_DEFAULT_SENDER from './queries/SetDefaultSender.gql'
import COMPANY from './queries/Company.gql'
import SettingsCard from '@/components/SettingsCard.vue'
import CreateSenderDialog from './CreateSenderDialog.vue'
import featureMixin from '@/mixins/feature'
import VerifySenderDialog from './VerifySenderDialog.vue'
import eventBus, { eventNames } from '@/lib/eventBus'
import UpdateSenderDialog from './UpdateSenderDialog.vue'

export default {
  components: { SettingsCard, CreateSenderDialog, VerifySenderDialog, UpdateSenderDialog },
  mixins: [featureMixin],
  data () {
    return {
      senderCreated: false,
      isLoading: false,
      domain: {}
    }
  },
  computed: {
  },
  methods: {
    async setAsDefaultSender (senderId) {
      this.isLoading = true
      try {
        await this.$apollo.mutate({
          mutation: SET_DEFAULT_SENDER,
          variables: { senderId },
          refetchQueries: [{
            query: HOPPERMATION_SENDER,
            variables: { companyId: this.$auth.user.companyId }
          }]
        })
        eventBus.$emit(eventNames.SHOW_SNACKBAR, { color: 'success', text: this.$t('alerts.settings.email.default-sender.success') })
      } catch (err) {
        eventBus.$emit(eventNames.SHOW_SNACKBAR, { color: 'error', text: this.$t('alerts.settings.email.default-sender.error') })
      } finally {
        this.isLoading = false
      }
    }
  },
  apollo: {
    senders: {
      query: HOPPERMATION_SENDER,
      variables () {
        return {
          companyId: this.$auth.user.companyId
        }
      }
    },
    company: {
      query: COMPANY,
      variables () {
        return {
          id: this.$auth.user.companyId
        }
      }
    }
  }
}
</script>
