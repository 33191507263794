<template>
  <router-link
    v-if="route"
    :to="{ name: route }"
    class="text-decoration-none"
  >
    <HelpCardContent
      v-bind="topicContent"
      :horizontal="horizontal"
      :color="hasBackgroundColor ? color : ''"
      :is-logo-cutout="isLogoCutout"
    />
  </router-link>
  <a
    v-else
    :href="link"
    :target="topicContent.target"
    class="text-decoration-none"
  >
    <HelpCardContent
      v-bind="topicContent"
      is-external
      :horizontal="horizontal"
      :color="hasBackgroundColor ? color :''"
      :is-logo-cutout="isLogoCutout"
    />
  </a>
</template>

<script>
import brandingMixin from '@/mixins/branding'
import HelpCardContent from './HelpCardContent.vue'

const TOPIC = {
  bottimmo: {
    CALENDAR: {
      title: 'support.help-card.calendar.title',
      text: 'support.help-card.calendar.text',
      route: 'calendar',
      linkText: 'support.help-card.calendar.link-text',
      logo: 'img/helpcenter-kalender.png',
      logoCutout: 'img/helpcenter-kalender-cutout.png',
      color: '#8ECCDA'
    },
    HELPCENTER: {
      title: 'support.help-card.help-center.title',
      text: 'support.help-card.help-center.text-bottimmo',
      linkText: 'support.help-card.help-center.link-text',
      target: '_blank',
      logo: 'img/helpcenter-hilfe.png',
      logoCutout: 'img/helpcenter-hilfe-cutout.png',
      color: '#EAA25A'
    }
  },
  remax: {
    CALENDAR: {
      title: 'support.help-card.calendar.title',
      text: 'support.help-card.calendar.text',
      route: 'calendar',
      linkText: 'support.help-card.calendar.link-text',
      logo: 'img/helpcenter-kalender.png',
      logoCutout: 'img/helpcenter-kalender-cutout.png',
      color: '#8ECCDA'
    },
    HELPCENTER: {
      title: 'support.help-card.help-center.title',
      text: 'support.help-card.help-center.text-remax',
      llinkText: 'support.help-card.help-center.link-text',
      target: '_blank',
      logo: 'img/helpcenter-hilfe.png',
      logoCutout: 'img/helpcenter-hilfe-cutout.png',
      color: '#EAA25A'
    }
  }
}

export default {
  components: { HelpCardContent },
  mixins: [brandingMixin],
  props: {
    topic: {
      type: String,
      default: 'null'
    },
    config: {
      type: Object,
      default: () => ({})
    },
    horizontal: {
      type: Boolean,
      default: false
    },
    hasBackgroundColor: {
      type: Boolean,
      default: false
    },
    isLogoCutout: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    topicContent () {
      return TOPIC[this.platform][this.topic]
    },
    route () {
      return this.config.route || TOPIC[this.platform][this.topic].route
    },
    link () {
      return this.config.url || TOPIC[this.platform][this.topic].link
    },
    color () {
      return TOPIC[this.platform][this.topic]?.color
    }
  }
}
</script>
