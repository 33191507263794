<template>
  <v-container class="layout-container pa-0">
    <CrmSettings />
  </v-container>
</template>

<script>
import CrmSettings from './CrmSettings.vue'

export default {
  components: {
    CrmSettings
  }
}
</script>
