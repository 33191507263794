<template>
  <v-row class="mx-0 my-2 mx-md-2">
    <v-col
      cols="12"
      sm="6"
    >
      <div>
        <span class="font-weight-bold">
          {{ $t('settings.crm.onoffice-filter.title') }}<br>
        </span>
        <p class="pa-0 ma-0 grey--text">
          {{ $t('settings.crm.onoffice-filter.body-texts.0') }}<br>
          {{ $t('settings.crm.onoffice-filter.body-texts.1') }}
        </p>
        <p class="mt-2 grey--text">
          <b>{{ $t('labels.caution') }}:</b> {{ $t('settings.crm.onoffice-filter.body-texts.2') }}
          {{ $t('settings.crm.onoffice-filter.body-texts.3') }}
        </p>
      </div>
    </v-col>
    <v-col
      cols="12"
      offset-md="1"
      md="5"
      class="pt-2 pt-md-8"
    >
      <ContactFilter
        :contact-status="onofficeContactTypes"
        :initial-selected-contact-types="selectedContactTypes"
        :add-count="addCount"
        :count-is-loading="countIsLoading"
        :hint="$t('settings.crm.onoffice-filter.labels.hint')"
        :label="$t('settings.crm.onoffice-filter.labels.label')"
        @blur="updateContactTypes"
      />

      <v-alert
        v-if="countIsLoading || selectedContactTypes.length > 0"
        type="info"
        dense
        text
      >
        <span v-if="countIsLoading">
          <v-progress-circular
            :size="20"
            :width="3"
            class="px-4"
            indeterminate
          />
          {{ $t('settings.crm.onoffice-filter.info-texts.0') }} ...
        </span>
        <div v-else>
          <p>{{ $t('settings.crm.onoffice-filter.info-texts.1', { combinedOnofficeContactsCount }) }}</p>
          <p>
            {{ $t('settings.crm.onoffice-filter.info-texts.2') }}<br>
            {{ $t('settings.crm.onoffice-filter.info-texts.3') }}<br><br>
            {{ $t('settings.crm.onoffice-filter.info-texts.3', { readablePlatformName }) }}
          </p>
        </div>
      </v-alert>
    </v-col>
  </v-row>
</template>

<script>
import bus, { eventNames } from '@/lib/eventBus'
import SAVE_ONOFFICE_SELECTED_CONTACT_TYPES from './queries/SaveOnofficeSelectedContactTypes.gql'
import GET_ONOFFICE_CONFIG from './queries/OnofficeConfig.gql'
import ONOFFICE_CONTACT_COUNT from './queries/OnofficeContactCount.gql'
import ONOFFICE_CONTACT_COUNT_BY_TYPE from './queries/OnofficeContactsCountByType.gql'
import ONOFFICE_CONTACT_TYPES from './queries/OnofficeContactTypes.gql'
import brandingMixin from '@/mixins/branding'
import ContactFilter from '../ContactFilter.vue'

export default {
  components: { ContactFilter },
  mixins: [brandingMixin],
  data () {
    return {
      isLoading: false,
      selectedContactTypes: []
    }
  },
  computed: {
    countIsLoading () {
      return !this.onofficeContactsCountByType
    },
    combinedOnofficeContactsCount () {
      if (!this.onofficeContactsCountByType?.countPerStatus) return
      const count = Object.entries(this.onofficeContactsCountByType?.countPerStatus)
        .filter(([key]) => this.selectedContactTypes.includes(key))
        .reduce((count, [_, value]) => count + value, 0)
      return count
    }
  },
  watch: {
    selectedContactTypes: {
      handler (newValue, oldValue) {
        if (!oldValue.includes('ALL') && newValue.includes('ALL')) {
          this.selectedContactTypes = ['ALL']
        }
        if (oldValue.includes('ALL') && newValue.length > 1) {
          this.selectedContactTypes = this.selectedContactTypes.filter(item => item !== 'ALL')
        }
      },
      deep: true
    }
  },
  methods: {
    addCount ({ text, value }) {
      if (!value) return { divider: true }
      const count = this.onofficeContactsCountByType?.countPerStatus[value] || 0
      return {
        text: Number.isInteger(this.combinedOnofficeContactsCount) ? `${text} (${count})` : `${text} (...)`,
        value
      }
    },
    async updateContactTypes (selectedTypes) {
      this.selectedContactTypes = selectedTypes
      this.isLoading = true
      try {
        await this.$apollo.mutate({
          mutation: SAVE_ONOFFICE_SELECTED_CONTACT_TYPES,
          variables: {
            input: {
              selectedContactTypes: this.selectedContactTypes,
              companyId: this.$auth.user.companyId
            }
          }
        })
        bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'success', text: this.$t('alerts.settings.crm.onoffice-filter.success') })
      } catch (e) {
        await this.$apollo.queries.onofficeConfig.refetch()
        bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'error', text: this.$t('alerts.settings.crm.onoffice-filter.error') })
      } finally {
        this.$emit('isFilterSelected', this.selectedContactTypes.length > 0)
        this.isLoading = false
      }
    }
  },
  apollo: {
    onofficeContactTypes: {
      query: ONOFFICE_CONTACT_TYPES,
      variables () {
        return {
          companyId: this.$auth.user.companyId
        }
      },
      update: ({ onofficeContactTypes }) => onofficeContactTypes.map(({ id, name }) => ({ text: name, value: id }))
    },
    onofficeConfig: {
      query: GET_ONOFFICE_CONFIG,
      variables () {
        return {
          companyId: this.$auth.user.companyId
        }
      },
      update ({ onofficeConfig }) {
        this.selectedContactTypes = onofficeConfig.selectedContactTypes
        this.$emit('isFilterSelected', this.selectedContactTypes.length > 0)
        return onofficeConfig
      }
    },
    onofficeContactsCount: {
      query: ONOFFICE_CONTACT_COUNT,
      skip () {
        return !this.selectedContactTypes || this.selectedContactTypes.length === 0
      },
      variables () {
        return {
          input: {
            selectedContactTypes: this.selectedContactTypes,
            companyId: this.$auth.user.companyId
          }
        }
      }
    },
    onofficeContactsCountByType: {
      query: ONOFFICE_CONTACT_COUNT_BY_TYPE
    }
  }
}
</script>
