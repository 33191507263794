<template>
  <div class="d-flex flex-column align-center">
    <h1 class="text-center mb-2">
      {{ $t('settings.upgrade-onboarding.title', { newPackage }) }}
    </h1>

    <v-stepper
      v-model="step"
      flat
      class="w-full"
    >
      <v-stepper-items>
        <v-stepper-content
          v-if="hasGuideStep"
          :step="getStep('Landingpages')"
        >
          <div class="d-flex justify-center">
            <span class="text-subtitle-2 text-center">
              {{ $t('settings.upgrade-onboarding.subtitle', { newPackage }) }}<br>
              {{ $t('settings.upgrade-onboarding.info') }}
            </span>
          </div>

          <Landingpages
            class="px-4"
            :count-of-guides="1"
            v-bind="landingpageDataProps"
            hide-description
            hide-secondary-actions
            @nextStep="handleNextStep"
            @dirty="handleDirty"
          />
        </v-stepper-content>
        <v-stepper-content
          :step="getStep('Publikation')"
        >
          <div
            id="publicationForm"
            ref="publicationForm"
            class="d-flex justify-center mb-4"
          >
            <span class="text-subtitle-2 text-center">
              {{ $t('settings.upgrade-onboarding.body-texts.0') }}<br>
              {{ $t('settings.upgrade-onboarding.body-texts.1') }}
            </span>
          </div>
          <v-alert type="warning">
            {{ $t('settings.upgrade-onboarding.body-texts.2') }}
          </v-alert>

          <Publication
            v-if="step === getStep('Publikation')"
            class="px-4"
            v-bind="publicationDataProps"
            is-onboarding
            hide-secondary-actions
            @nextStep="$emit('submit')"
            @dirty="handleDirty"
          />
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </div>
</template>

<script>
import featureMixin from '@/mixins/feature'
import Landingpages from '@/modules/onboarding/Landingpages.vue'
import Publication from '@/modules/company/Publication'

const PREVIEWS = {
  ATV1: 'https://static.bottimmo.com/assets/onboarding/previews/A4C_Ratgeber-Vorschau/Ratgeber-Vorschau.pdf',
  CHV1: 'https://static.bottimmo.com/assets/onboarding/previews/C0X_Ratgeber-Vorschau/Ratgeber-Vorschau.pdf',
  CHV2: 'https://static.bottimmo.com/assets/onboarding/previews/C3BE_Ratgeber-Vorschau/Ratgeber-Vorschau.pdf',
  DV1: 'https://static.bottimmo.com/assets/onboarding/previews/D4AL_Ratgeber-Vorschau/Ratgeber-Vorschau.pdf',
  DV2: 'https://static.bottimmo.com/assets/onboarding/previews/D6EM_Ratgeber-Vorschau/Ratgeber-Vorschau.pdf',
  DV3: 'https://static.bottimmo.com/assets/onboarding/previews/D8QT_Ratgeber-Vorschau/Ratgeber-Vorschau.pdf',
  DV4: 'https://static.bottimmo.com/assets/onboarding/previews/D91RL_Ratgeber-Vorschau/Ratgeber-Vorschau.pdf',
  REMAX: 'https://static.bottimmo.com/assets/onboarding/previews/R13PO_Ratgeber-Vorschau/Ratgeber-Vorschau.pdf',
  VOBA: 'https://static.bottimmo.com/assets/onboarding/previews/V99F7_Ratgeber-Vorschau/Ratgeber-Vorschau.pdf'
}

export default {
  components: {
    Landingpages,
    Publication
  },
  mixins: [featureMixin],
  props: {
    newPackage: {
      type: String,
      default: ''
    },
    company: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      step: 1,
      isDirty: false,
      missingData: {},
      products: []
    }
  },
  computed: {
    publicationDataProps () {
      const {
        id: companyId, slogan, ambassador, name, links, address, mailProvider,
        images: { team, logo, office, region },
        variant,
        theme,
        isReferenceCustomer
      } = this.company
      ambassador.image = ambassador.image || {}
      theme.colors.primary = theme.colors.primary || '#000000'
      theme.colors.secondary = theme.colors.secondary || '#000000'
      const previewLink = PREVIEWS[variant]
      return {
        companyId,
        slogan,
        ambassador,
        name,
        links,
        address,
        mailProvider,
        previewLink,
        theme,
        isReferenceCustomer,
        images: {
          team: team || {},
          logo: logo || {},
          office: office || {},
          region: region || {}
        }
      }
    },
    landingpageDataProps () {
      const {
        id: companyId,
        variant,
        country,
        bookedLandingpageSlugs
      } = this.company
      return {
        companyId,
        variant,
        country,
        bookedLandingpageSlugs
      }
    },
    steps () {
      const steps = [
        { route: 'landigpages', title: 'Landingpages', disabled: !this.hasGuideStep },
        { route: 'publication', title: 'Publikation' }
      ]
      return steps
        .filter(({ disabled }) => !disabled)
        .map((step, index) => ({ ...step, step: index + 1 }))
    },
    hasGuideStep () {
      return this.isFeatureActive(this.featureNames.LANDINGPAGE) && this.getFeature(this.featureNames.LANDINGPAGE)?.config.canBookLandingpages
    }
  },
  methods: {
    handleNextStep (value = 1) {
      this.$emit('scrollToTop')
      const nextStep = this.step + value
      this.step = nextStep < 1 ? 1 : nextStep
    },
    handleDirty (value) {
      this.isDirty = value
    },
    getStep (title) {
      return this.steps.find((step) => step.title === title)?.step
    }
  }
}
</script>
