<template>
  <v-container class="layout-container pa-0">
    <SenderSettings />

    <Feature
      v-slot="{feature}"
      :feature-slug="featureNames.COMPANY"
    >
      <div v-if="company && feature.config.isSalutationEditable">
        <v-divider class="my-4" />
        <SalutationSettings
          :company="company"
          :refetch-company="refetchCompany"
        />
      </div>
    </Feature>

    <v-divider
      class="my-4"
    />
    <DomainSettings />

    <v-divider
      v-if="company"
      class="my-4"
    />
    <SignatureSettings
      v-if="company"
      :company="company"
      :refetch-company="refetchCompany"
    />
  </v-container>
</template>

<script>
import DomainSettings from './DomainSettings.vue'
import SenderSettings from './SenderSettings.vue'
import SalutationSettings from './SalutationSettings.vue'
import SignatureSettings from './SignatureSettings.vue'
import COMPANY from './queries/Company.gql'
import feature from '@/mixins/feature'

export default {
  components: {
    DomainSettings,
    SenderSettings,
    SalutationSettings,
    SignatureSettings
  },
  mixins: [feature],
  methods: {
    refetchCompany () {
      this.$apollo.queries.company.refetch()
    }
  },
  apollo: {
    company: {
      query: COMPANY,
      variables () {
        return {
          id: this.$auth.user.companyId
        }
      }
    }
  }
}
</script>
