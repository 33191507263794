<template>
  <div>
    <p class="text-subtitle-1">
      {{ $t('settings.delete-account.feedback.title') }}
    </p>

    <v-row>
      <v-col
        cols="12"
        md="8"
      >
        <v-checkbox
          v-for="item in readableCancelationReasons"
          :key="item.value"
          v-model="cancelationReasons.reasons"
          :label="$t(item.label)"
          :value="item.value"
          small
          hide-details
        />
        <div class="mt-6">
          <v-text-field
            v-model="cancelationReasons.other"
            :label="$t('settings.delete-account.feedback.other-label')"
            :placeholder="$t('settings.delete-account.feedback.other-placeholder')"
            persistent-placeholder
            outlined
          />
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import brandingMixin from '@/mixins/branding'
import CancelationReason from './enums/CancelationReason'

const readableCancelationReasons = [
  { value: CancelationReason.TECHNICAL_ISSUES, label: 'settings.delete-account.feedback.reasons.0' },
  { value: CancelationReason.MISSING_FEATURES, label: 'settings.delete-account.feedback.reasons.1' },
  { value: CancelationReason.SERVICE_AND_HELP, label: 'settings.delete-account.feedback.reasons.2' },
  { value: CancelationReason.NO_SUCCESS, label: 'settings.delete-account.feedback.reasons.3' },
  { value: CancelationReason.NO_NEED, label: 'settings.delete-account.feedback.reasons.4' },
  { value: CancelationReason.HIGH_PRICING, label: 'settings.delete-account.feedback.reasons.5' }
]

export default {
  mixins: [brandingMixin],
  data () {
    return {
      cancelationReasons: {
        reasons: [],
        other: ''
      },
      readableCancelationReasons
    }
  },
  watch: {
    cancelationReasons: {
      deep: true,
      handler () {
        this.$emit('updateCancelationReasons', this.cancelationReasons)
      }
    }
  }
}
</script>
