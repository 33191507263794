<template>
  <v-container
    v-if="company"
    class="layout-container pa-0"
  >
    <ContactSettings
      :settings="settings"
      :company="company"
    />

    <Feature
      v-slot="{feature}"
      :feature-slug="featureNames.COMPANY"
    >
      <div v-if="feature.config.isMetaCompanyDataEditable">
        <div>
          <v-divider class="my-4" />
          <AiSettings
            :settings="settings"
            :company="company"
            class="my-4"
          />
        </div>
      </div>
    </Feature>

    <Feature :feature-slug="featureNames.LANDINGPAGE">
      <div>
        <v-divider class="my-4" />
        <WebSettings
          :settings="settings"
          :company="company"
          class="my-4"
        />
      </div>
    </Feature>

    <Feature :feature-slug="featureNames.LANDINGPAGE">
      <div>
        <v-divider class="my-4" />
        <ImageSettings :company="company" />
      </div>
    </Feature>

    <Feature
      v-slot="{feature}"
      :feature-slug="featureNames.COMPANY"
    >
      <div v-if="feature.config.isColorEditable">
        <v-divider class="my-4" />

        <ColorSettings :company="company" />
      </div>
    </Feature>

    <Feature
      v-slot="{feature}"
      :feature-slug="featureNames.COMPANY"
    >
      <div v-if="feature.config.isCompanyLanguageEditable">
        <v-divider class="my-4" />
        <LanguageSettings
          :company="company"
          @updateCompanyLocale="updateCompanyLocale"
        />
      </div>
    </Feature>

    <Feature
      v-slot="{feature}"
      :feature-slug="featureNames.COMPANY"
    >
      <div v-if="feature.config.isOtherCompanyDataEditable">
        <v-divider class="my-4" />

        <OtherSettings :company="company" />
      </div>
    </Feature>
  </v-container>
</template>

<script>
import ContactSettings from './ContactSettings.vue'
import WebSettings from './WebSettings.vue'
import AiSettings from './AiSettings.vue'
import ImageSettings from './ImageSettings.vue'
import ColorSettings from './ColorSettings.vue'
import LanguageSettings from './LanguageSettings.vue'
import OtherSettings from './OtherSettings.vue'
import featureMixin from '@/mixins/feature'
import SETTINGS from './queries/Settings.gql'
import COMPANY from './queries/CompanyData.gql'

export default {
  components: {
    LanguageSettings,
    ContactSettings,
    WebSettings,
    AiSettings,
    ImageSettings,
    ColorSettings,
    OtherSettings
  },
  mixins: [featureMixin],
  methods: {
    async updateCompanyLocale () {
      await this.$apollo.queries.company.refetch()
    }
  },
  apollo: {
    settings: {
      query: SETTINGS,
      variables () {
        return {
          companyId: this.$auth.user.companyId
        }
      }
    },
    company: {
      query: COMPANY,
      variables () {
        return {
          id: this.$auth.user.companyId
        }
      },
      update: ({ company }) => {
        company.images.logo = company.images.logo || {}
        company.images.team = company.images.team || {}
        company.images.region = company.images.region || {}
        company.images.lpValuationHero = company.images.lpValuationHero || {}
        company.ambassador.image = company.ambassador.image || {}
        return company
      }
    }
  }
}
</script>
