<template>
  <Feature
    v-slot="{feature}"
    :feature-slug="featureNames.VALUATION"
  >
    <SettingsCard
      v-if="feature.config.isPlotValuationIntegrationEnabled || (feature.config.isPhoneNumberVerificationEnabled && feature.config.canChangePhoneNumberVerification)"
      :title="$t('settings.features.valuation.title')"
      :subtitle="subtitle"
      icon="mdi-home-variant"
      :is-default-open="false"
    >
      <div class="pt-4 px-4">
        <v-row v-if="feature.config.isPlotValuationIntegrationEnabled">
          <v-col
            cols="12"
            sm="6"
            class="px-0 px-md-2"
          >
            <div>
              <div class="d-flex align-center">
                <span class="font-weight-bold">
                  {{ $t('settings.features.valuation.body-texts.0') }}
                </span>
              </div>
              <span class="grey--text">
                {{ $t('settings.features.valuation.body-texts.1') }}
              </span>
            </div>
          </v-col>
          <v-col
            cols="12"
            sm="6"
            class="d-flex justify-center px-0 px-md-2"
          >
            <div
              class="d-flex flex-column"
              :style="{'max-width':$vuetify.breakpoint.smAndDown ? '100%' : '70%'}"
            >
              <div class="d-flex">
                <span>{{ $t('settings.features.valuation.labels.add-plot-valuation') }}: </span>
                <v-switch
                  v-model="isPlotValuationInValuation"
                  class="ma-0 mx-2"
                  dense
                  hide-details
                  @change="updatePlotValuationInValuation"
                />
              </div>
              <v-img
                :src="plotValuationSettingSrc"
                alt="plot valuation image"
                contain
                max-width="100%"
              />
            </div>
          </v-col>
        </v-row>
        <v-row v-if="feature.config.isPhoneNumberVerificationEnabled && feature.config.canChangePhoneNumberVerification">
          <v-col
            cols="12"
            sm="6"
            class="px-0 px-md-2"
          >
            <div>
              <div class="d-flex align-center">
                <span class="font-weight-bold">
                  {{ $t('settings.features.valuation.body-texts.2') }}
                </span>
              </div>
              <span class="grey--text">
                {{ $t('settings.features.valuation.body-texts.3') }}<br>
              </span>
              <p class="ma-0 mt-2 grey--text">
                <b>{{ $t('labels.caution') }}:</b> {{ $t('settings.features.valuation.body-texts.4') }} <br>
                <b>{{ $t('settings.features.valuation.body-texts.5') }}:</b> {{ $t('settings.features.valuation.body-texts.6') }} <br><br>
              </p>
            </div>
          </v-col>
          <v-col
            cols="12"
            sm="6"
            class="d-flex justify-center"
            :class="{'pa-0 px-0': $vuetify.breakpoint.smAndDown}"
          >
            <div
              class="d-flex flex-column"
              :style="{'max-width':$vuetify.breakpoint.smAndDown ? '100%' : '70%'}"
            >
              <div class="d-flex mb-6">
                <span>{{ $t('settings.features.valuation.labels.activate-phone-verification') }}: </span>
                <v-switch
                  v-model="isPhoneVerificationEnabled"
                  class="ma-0 mx-2"
                  dense
                  hide-details
                  @change="updatePhoneVerificationEnabled"
                />
              </div>
              <v-img
                :src="phoneVerificationSettingSrc"
                alt="phone verification image"
                contain
                max-width="100%"
              />
            </div>
          </v-col>
        </v-row>
        <ChangeSettingsAlert />
      </div>
    </SettingsCard>
  </Feature>
</template>

<script>

import SettingsCard from '@/components/SettingsCard.vue'
import ChangeSettingsAlert from '@/components/ChangeSettingsAlert.vue'
import UPDATE_PHONE_VERIFICATION from './queries/UpdatePhoneVerification.gql'
import UPDATE_PLOT_VALUATION from './queries/UpdatePlotValuation.gql'
import bus, { eventNames } from '@/lib/eventBus'
import featureMixin from '@/mixins/feature'

export default {
  components: {
    SettingsCard,
    ChangeSettingsAlert
  },
  mixins: [featureMixin],
  props: {
    settings: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      plotValuationSettingSrc: 'img/settings-plot-valuation.png',
      phoneVerificationSettingSrc: 'img/settings-phone-verification.png',
      isPhoneVerificationEnabled: this.settings.company.enabledPhoneVerification.VALUATION,
      isPlotValuationInValuation: this.settings.company.plotValuation.showInPropertyValuation
    }
  },
  computed: {
    subtitle () {
      return this.getFeature(this.featureNames.VALUATION).config.isPlotValuationIntegrationEnabled
        ? this.$t('settings.features.valuation.subtitle-active')
        : this.$t('settings.features.valuation.subtitle')
    }
  },
  methods: {
    updatePhoneVerificationEnabled (value) {
      this.isPhoneVerificationEnabled = value
      this.saveSettings(this.savePhoneVerificationSetting)
    },
    updatePlotValuationInValuation (value) {
      this.isPlotValuationInValuation = value
      this.saveSettings(this.savePlotValuationSetting)
    },
    async savePhoneVerificationSetting () {
      await this.$apollo.mutate({
        mutation: UPDATE_PHONE_VERIFICATION,
        variables: {
          input: { enabled: this.isPhoneVerificationEnabled, companyId: this.$auth.user.companyId }
        }
      })
    },
    async savePlotValuationSetting () {
      await this.$apollo.mutate({
        mutation: UPDATE_PLOT_VALUATION,
        variables: {
          input: { showInPropertyValuation: this.isPlotValuationInValuation, companyId: this.$auth.user.companyId }
        }
      })
    },
    async saveSettings (save) {
      try {
        await save()
        bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'success', text: this.$t('alerts.settings.features.success') })
      } catch (e) {
        bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'error', text: this.$t('alerts.settings.features.error') })
      }
    }
  }
}
</script>
