export const BasicPackagePrice = Object.freeze({
  Price: 0
})

export const AdvancedPackagePrice = Object.freeze({
  MONTHLY: 19,
  ANNUALLY: 199
})

export const AmbitiousPackagePrice = Object.freeze({
  MONTHLY: 49,
  ANNUALLY: 468
})

export const AiContentPackage = Object.freeze({
  MONTHLY: 15,
  ANNUALLY: 144
})

export const AiGeneratedWebsite = Object.freeze({
  MONTHLY: 15,
  ANNUALLY: 144
})

export const AiBasedCustomerCare = Object.freeze({
  MONTHLY: 15,
  ANNUALLY: 144
})

export const Personalization = Object.freeze({
  MONTHLY: 15,
  ANNUALLY: 144
})

export const WebsiteWidgets = Object.freeze({
  MONTHLY: 9,
  ANNUALLY: 84
})
