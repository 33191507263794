<template>
  <v-row>
    <v-col
      v-for="(variant, index) in variants"
      :key="index"
      cols="12"
      md="6"
    >
      <v-card
        class="elevation-0 h-full"
        :class="{
          'primary': variant.isActive,
          'grey lighten-3': !variant.isActive
        }"
        :ripple="false"
        @click="switchVariant(variant.name)"
      >
        <div class="d-flex">
          <v-radio-group
            v-model="selectedPlan"
            class="ml-4"
          >
            <v-radio
              :value="variant.name"
              color="black"
            />
          </v-radio-group>
          <v-card-subtitle>
            {{ getVariantHeaders(variant.name) }}
          </v-card-subtitle>
        </div>
        <v-card-text>
          <ul>
            <li
              v-for="(text, i) in getVariantTexts(variant.name)"
              :key="i"
            >
              {{ text }}
            </li>
          </ul>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import brandingMixin from '@/mixins/branding'

export default {
  mixins: [brandingMixin],
  props: {
    selectedVariant: {
      type: String,
      default: 'free'
    },
    nextPossibleTerminationDate: {
      type: Date,
      default: ''
    }
  },
  data () {
    return {
      variants: [
        {
          name: 'free',
          isActive: true
        },
        {
          name: 'delete',
          isActive: false
        }
      ],
      selectedPlan: this.selectedVariant
    }
  },
  methods: {
    switchVariant (name) {
      this.selectedPlan = name
      this.variants.forEach(variant => {
        variant.isActive = variant.name === name
      })
      this.$emit('handleSwitchplan', this.selectedPlan)
    },
    daysUntil (nextDate) {
      const currentDate = new Date()
      const futureDate = new Date(nextDate)

      // Calculate the difference in milliseconds
      const diffInMs = futureDate - currentDate

      // Convert milliseconds to days
      const diffInDays = Math.ceil(diffInMs / (1000 * 60 * 60 * 24))

      return diffInDays
    },
    getVariantHeaders (name) {
      return {
        free: this.$t('settings.delete-account.switchplan.header.free', { date: this.formatDate(this.nextPossibleTerminationDate, 'DATE_LONG') }),
        delete: this.$t('settings.delete-account.switchplan.header.delete', { date: this.formatDate(this.nextPossibleTerminationDate, 'DATE_LONG') })
      }[name]
    },
    getVariantTexts (name) {
      return {
        free: [
          this.$t('settings.delete-account.switchplan.list-free.0', { platform: this.readablePlatformName }),
          this.$t('settings.delete-account.switchplan.list-free.1'),
          this.$t('settings.delete-account.switchplan.list-free.2'),
          this.$t('settings.delete-account.switchplan.list-free.3', { platform: this.readablePlatformName }),
          this.$t('settings.delete-account.switchplan.list-free.4'),
          this.$t('settings.delete-account.switchplan.list-free.5')
        ],
        delete: [
          this.$t('settings.delete-account.switchplan.list-delete.0'),
          this.$t('settings.delete-account.switchplan.list-delete.1'),
          this.$t('settings.delete-account.switchplan.list-delete.2', { platform: this.readablePlatformName }),
          this.$t('settings.delete-account.switchplan.list-delete.3', {
            days: this.daysUntil(this.nextPossibleTerminationDate),
            date: this.formatDate(this.nextPossibleTerminationDate, 'DATE_LONG')
          })
        ]
      }[name]
    }
  }
}
</script>
