<template>
  <Step
    :title="$t('settings.delete-account.step-cancelled.title')"
    icon="mdi-account-reactivate"
    type="success"
    :loading="loading"
    :action="reactivateContract"
  >
    <div class="black--text">
      <p>
        {{ $t('settings.delete-account.step-cancelled.body-texts.0', { date: formatDate(endDate,'DATE_LONG') }) }}<br>
      </p>
      <p class="font-weight-bold">
        {{ $t('settings.delete-account.step-cancelled.body-texts.1', { date: formatDate(endDate,'DATE_LONG'), readablePlatformName }) }}
      </p>
      <p>
        {{ $t('settings.delete-account.step-cancelled.body-texts.2', { date: formatDate(endDate,'DATE_LONG') }) }}
        {{ $t('settings.delete-account.step-cancelled.body-texts.3', { date: formatDate(endDate,'DATE_LONG'), readablePlatformName }) }}
      </p>
    </div>
  </Step>
</template>

<script>
import brandingMixin from '@/mixins/branding'
import bus, { eventNames } from '@/lib/eventBus'
import billwerkPortal from '@/mixins/billwerkPortal'

import REVOKE_CANCELLATION from '../../queries/RevokeCancellation.gql'

import Step from './Step.vue'

export default {
  components: { Step },
  mixins: [brandingMixin, billwerkPortal],
  data () {
    return {
      loading: false
    }
  },
  computed: {
    endDate () {
      return this.contractDetails?.endDate ? new Date(this.contractDetails.endDate) : null
    }
  },
  methods: {
    async reactivateContract () {
      this.loading = true
      try {
        await this.$apollo.mutate({
          mutation: REVOKE_CANCELLATION,
          variables: { companyId: this.$auth.user.companyId }
        })
        this.$emit('actionSuccess')
        bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'success', text: this.$t('alerts.settings.reactivate-account.success') })
      } catch (err) {
        bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'error', text: this.$t('alerts.settings.reactivate-account.error') })
      } finally {
        this.getContractDetails()
        this.loading = false
      }
    }
  }
}
</script>
