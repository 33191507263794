<template>
  <div
    v-if="isSlugEditable"
    class="w-full"
  >
    <v-form
      ref="linksForm"
      v-model="isFormValid"
      @submit="submit"
    >
      <v-row class="mb-2 ma-0 pa-0">
        <v-text-field
          ref="slug"
          v-model="slugData"
          outlined
          type="url"
          label="Ihre persönliche Landinpage URL"
          prefix="https://"
          :error="hasParentError"
          :error-messages="errorMessages"
          :succes="hasParentSuccess"
          :success-messages="successMessages"
          :suffix="landingpageDomain"
          :rules="[
            rules.required,
            rules.length({ min: 5 }),
            rules.matchPattern(
              /^[a-z0-9](-?[a-z0-9])*$/,
              $t('alerts.settings.company.slug-data.error-validation')
            )
          ]"
          :loading="isLoading"
          @input="update"
          @update:error="isFieldValid = !$event"
          @submit="submit"
        />
      </v-row>
    </v-form>
  </div>
</template>

<script>
import { required, length, matchPattern } from '@/lib/validation'
import featureNames from '@/lib/featureNames'
import feature from '@/mixins/feature'

export default {
  mixins: [feature],
  props: {
    slug: {
      type: String,
      default: () => ''
    },
    hasDebounceTimer: {
      type: Boolean,
      default: true
    },
    parentError: {
      type: String,
      default: null
    },
    parentSuccess: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      slugData: this.slug,
      debounceTimeout: null,
      spinnerTimeout: null,
      isLoading: false,
      isFormValid: false,
      isFieldValid: false,
      inputError: this.parentError,
      inputSuccess: this.parentSuccess
    }
  },
  computed: {
    isSlugEditable () {
      return this.getFeature(featureNames.COMPANY).config.isSlugEditable
    },
    landingpageDomain () {
      return `.${this.$features.feature(featureNames.LANDINGPAGE)?.config?.baseDomain}`
    },
    hasParentError () {
      return Boolean(this.inputError)
    },
    errorMessages () {
      return this.inputError ? [this.inputError] : []
    },
    hasParentSuccess () {
      return Boolean(this.inputSuccess)
    },
    successMessages () {
      return this.inputSuccess ? [this.inputSuccess] : []
    }
  },

  watch: {
    slug () {
      this.slugData = this.slug
    },
    parentError () {
      this.inputError = this.parentError
    },
    parentSuccess () {
      this.inputSuccess = this.parentSuccess
    }
  },
  created () {
    this.rules = { required, length, matchPattern }
  },

  unmounted () {
    clearTimeout(this.spinnerTimeout)
    clearTimeout(this.debounceTimeout)
  },
  methods: {
    async submit (event) {
      event.preventDefault()
    },
    async update (value) {
      this.inputError = null
      this.inputSuccess = null
      this.slugData = value
      if (!this.hasDebounceTimer) {
        this.$emit('updateSlug', this.slugData)
      } else {
        clearTimeout(this.debounceTimeout)
        this.debounceTimeout = setTimeout(() => {
          this.isLoading = true
          this.spinnerTimeout = setTimeout(async () => {
            // save slug when it is valid and different to the initial slug
            const isFormValid = await this.$refs.linksForm.validate() && this.slugData.toLowerCase() !== this.slug.toLowerCase()
            if (isFormValid) {
              this.$emit('updateSlug', this.slugData)
            }
            this.isLoading = false
          }, 1000)
        }, 2000)
      }
    }
  }
}
</script>
