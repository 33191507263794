<template>
  <v-row class="mx-0 my-2 mx-md-2">
    <v-col
      cols="12"
      sm="6"
    >
      <div>
        <span class="font-weight-bold">
          {{ $t('settings.crm.propstack-filter.title') }}<br>
        </span>
        <p class="pa-0 ma-0 grey--text">
          {{ $t('settings.crm.propstack-filter.body-texts.0') }}<br>
          {{ $t('settings.crm.propstack-filter.body-texts.1') }}
        </p>
        <p class="mt-2 grey--text">
          <b>{{ $t('labels.caution') }}:</b> {{ $t('settings.crm.propstack-filter.body-texts.2') }}
          {{ $t('settings.crm.propstack-filter.body-texts.3') }}
        </p>
      </div>
    </v-col>
    <v-col
      cols="12"
      offset-md="1"
      md="5"
      class="pt-2 pt-md-8"
    >
      <ContactFilter
        :contact-status="contactStatuses"
        :initial-selected-contact-types="selectedContactTypes"
        :add-count="addCount"
        :count-is-loading="countIsLoading"
        :hint="$t('settings.crm.propstack-filter.labels.hint')"
        :label="$t('settings.crm.propstack-filter.labels.label')"
        @blur="updateContactTypes"
      />

      <v-alert
        v-if="countIsLoading || selectedContactTypes.length > 0"
        type="info"
        dense
        text
      >
        <span v-if="countIsLoading">
          <v-progress-circular
            :size="20"
            :width="3"
            class="px-4"
            indeterminate
          />
          {{ $t('settings.crm.propstack-filter.info-texts.0') }} ...
        </span>
        <div v-else>
          <p>{{ $t('settings.crm.propstack-filter.body-texts.1', { combinedPropstackContactsCount }) }}</p>
          <p>
            {{ $t('settings.crm.propstack-filter.body-texts.2') }}<br>
            {{ $t('settings.crm.propstack-filter.body-texts.3') }}<br><br>
            {{ $t('settings.crm.propstack-filter.info-texts.4', { readablePlatformName }) }}
          </p>
        </div>
      </v-alert>
    </v-col>
  </v-row>
</template>

<script>
import bus, { eventNames } from '@/lib/eventBus'
import SAVE_PROPSTACK_SELECTED_CONTACT_STATUSES from './queries/SavePropstackSelectedContactStatuses.gql'
import GET_PROPSTACK_CONFIG from './queries/PropstackConfig.gql'
import PROPSTACK_CONTACT_COUNT from './queries/PropstackContactCount.gql'
import PROPSTACK_CONTACT_STATUSES from './queries/PropstackContactStatuses.gql'
import brandingMixin from '@/mixins/branding'
import ContactFilter from '../ContactFilter.vue'

export default {
  components: { ContactFilter },
  mixins: [brandingMixin],
  data () {
    return {
      isLoading: false,
      selectedContactTypes: [],
      stopCountPoll: false
    }
  },
  computed: {
    countIsLoading () {
      return !this.propstackContactsCount
    },
    totalCount () {
      return Object.values(this.propstackContactsCount?.countPerStatus || []).reduce((acc, cur) => acc + cur, 0)
    },
    combinedPropstackContactsCount () {
      if (!this.propstackContactsCount?.countPerStatus) return
      const count = Object.entries(this.propstackContactsCount?.countPerStatus)
        .filter(([key]) => this.selectedContactTypes.includes(key) || this.selectedContactTypes.includes('ALL'))
        .reduce((count, [_, value]) => count + value, 0)
      return count
    }
  },
  watch: {
    selectedContactTypes: {
      handler (newValue, oldValue) {
        if (!oldValue.includes('ALL') && newValue.includes('ALL')) {
          this.selectedContactTypes = ['ALL']
        }
        if (oldValue.includes('ALL') && newValue.length > 1) {
          this.selectedContactTypes = this.selectedContactTypes.filter(item => item !== 'ALL')
        }
      },
      deep: true
    }
  },
  methods: {
    addCount ({ text, value }) {
      if (!value) return { divider: true }
      const count = value === 'ALL'
        ? this.totalCount
        : (this.propstackContactsCount?.countPerStatus[value] || 0)
      return {
        text: Number.isInteger(this.combinedPropstackContactsCount) ? `${text} (${count})` : `${text} (...)`,
        value
      }
    },

    async updateContactTypes (selectedTypes) {
      this.selectedContactTypes = selectedTypes
      this.isLoading = true
      try {
        await this.$apollo.mutate({
          mutation: SAVE_PROPSTACK_SELECTED_CONTACT_STATUSES,
          variables: {
            input: {
              selectedContactStatusIds: this.selectedContactTypes,
              companyId: this.$auth.user.companyId
            }
          }
        })
        bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'success', text: this.$t('alerts.settings.crm.propstack-filter.success') })
      } catch (e) {
        await this.$apollo.queries.propstackConfig.refetch()
        bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'error', text: this.$t('alerts.settings.crm.propstack-filter.error') })
      } finally {
        this.$emit('isFilterSelected', this.selectedContactTypes.length > 0)
        this.isLoading = false
      }
    }
  },
  apollo: {
    contactStatuses: {
      query: PROPSTACK_CONTACT_STATUSES,
      variables () {
        return {
          companyId: this.$auth.user.companyId
        }
      },
      update: ({ contactStatuses }) => contactStatuses.map(({ id, name }) => ({ text: name, value: id }))
    },
    propstackConfig: {
      query: GET_PROPSTACK_CONFIG,
      variables () {
        return {
          companyId: this.$auth.user.companyId
        }
      },
      update ({ propstackConfig }) {
        this.selectedContactTypes = propstackConfig.selectedContactStatusIds
        this.$emit('isFilterSelected', this.selectedContactTypes && this.selectedContactTypes.length > 0)
        return propstackConfig
      }
    },
    propstackContactsCount: {
      query: PROPSTACK_CONTACT_COUNT,
      variables () {
        return {
          input: {
            companyId: this.$auth.user.companyId,
            selectedContactStatusIds: this.selectedContactTypes
          }
        }
      },
      skip () { return this.stopCountPoll },
      result (data) {
        if (data?.data?.propstackContactsCount) { this.stopCountPoll = true }
      },
      pollInterval: 10000
    }
  }
}
</script>
