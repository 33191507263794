<template>
  <div>
    <v-btn
      color="success"
      :loading="isLoading"
      :disabled="isLoading"
      outlined
      @click="checkStatus"
    >
      {{ $t('buttons.verify.cta') }}
    </v-btn>
    <v-dialog
      v-model="show"
      width="1000"
    >
      <v-card>
        <v-card-title
          class="pa-0"
        >
          <v-toolbar
            color="primary"
            dark
            flat
          >
            <v-toolbar-title>{{ $t('settings.email.verify-sender-dialog.title') }}</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-btn
                icon
                dark
                @click="show = false"
              >
                <v-icon large>
                  mdi-close
                </v-icon>
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>

        <v-card-text class="mt-4 text-body-1">
          <v-row>
            <v-col
              cols="12"
              md="8"
            >
              <h3>{{ $t('settings.email.verify-sender-dialog.body-texts.0') }}</h3>
              <ol class="py-2">
                <li class="my-2">
                  {{ $t('settings.email.verify-sender-dialog.body-texts.1') }} (<strong>{{ sender?.fromEmail }}</strong>).
                </li>
                <li
                  class="my-2"
                  v-html="$t('settings.email.verify-sender-dialog.body-texts.2')"
                />
                <li
                  class="my-2"
                  v-html="$t('settings.email.verify-sender-dialog.body-texts.3')"
                />
              </ol>
              <p>{{ $t('settings.email.verify-sender-dialog.body-texts.4') }}</p>
              <h3 class="mt-6 mb-2">
                {{ $t('settings.email.verify-sender-dialog.body-texts.5') }}
              </h3>
              <p>
                {{ $t('settings.email.verify-sender-dialog.body-texts.6') }}
                <v-btn
                  color="primary"
                  class="text-none px-0 pb-1 body-1"
                  :loading="isLoading"
                  :disabled="isLoading"
                  text
                  x-small
                  @click="resendVerificationMail"
                >
                  {{ $t('settings.email.verify-sender-dialog.body-texts.7') }}
                </v-btn><br>
                {{ $t('settings.email.verify-sender-dialog.body-texts.8') }}
              </p>
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <v-img src="https://static.bottimmo.com/images/assets/app-branding/email-verification-mail.png?h=600" />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import CHECK_SENDER_VERIFICATION from './queries/CheckSenderVerification.gql'
import HOPPERMATION_SENDER from './queries/HoppermationSenders.gql'
import RESEND_HOPPERMATION_SENDER_VALIDATION from './queries/ResendHoppermationSenderValidation.gql'
import eventBus, { eventNames } from '@/lib/eventBus'
import { email, required } from '@/lib/validation'

export default {
  props: {
    sender: { type: Object, required: true }
  },
  data () {
    return {
      rules: { email, required },
      show: false,
      isLoading: false,
      valid: false,
      name: null,
      fromEmail: null,
      replyToEmail: null
    }
  },
  methods: {
    async resendVerificationMail () {
      this.isLoading = true
      try {
        await this.$apollo.mutate({
          mutation: RESEND_HOPPERMATION_SENDER_VALIDATION,
          variables: { senderId: this.sender.id }
        })
      } catch (err) {
        eventBus.$emit(eventNames.SHOW_SNACKBAR, { color: 'error', text: this.$t('alerts.settings.email.verify-sender.error-send') })
      } finally {
        this.isLoading = false
        this.show = false
      }
    },
    async checkStatus () {
      this.isLoading = true
      try {
        const { data: { sender } } = await this.$apollo.mutate({
          mutation: CHECK_SENDER_VERIFICATION,
          variables: { senderId: this.sender.id },
          refetchQueries: [{
            query: HOPPERMATION_SENDER,
            variables: { companyId: this.$auth.user.companyId }
          }]
        })
        if (sender.isVerified) {
          eventBus.$emit(eventNames.SHOW_SNACKBAR, { color: 'success', text: this.$t('alerts.settings.email.verify-sender.success') })
        } else {
          this.show = true
        }
      } catch (err) {
        eventBus.$emit(eventNames.SHOW_SNACKBAR, { color: 'error', text: this.$t('alerts.settings.email.verify-sender.error') })
      } finally {
        this.isLoading = false
      }
    }
  }
}
</script>
