<template>
  <div class="w-full">
    <v-btn
      class="w-full"
      color="primary"
      :disabled="disabled"
      :loading="loading"
      @click="show = true"
    >
      {{ $t('buttons.activate-sync-dialog.cta') }}
    </v-btn>
    <v-dialog
      v-model="show"
      width="700"
    >
      <v-card>
        <v-card-title
          class="pa-0"
        >
          <v-toolbar
            color="primary"
            dark
            flat
          >
            <v-toolbar-title v-if="automaticSync">
              {{ $t('settings.crm.activate-sync-dialog.title') }}
            </v-toolbar-title>
            <v-toolbar-title v-else>
              {{ $t('settings.crm.activate-sync-dialog.title-manual') }}
            </v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-btn
                icon
                dark
                @click="show = false"
              >
                <v-icon large>
                  mdi-close
                </v-icon>
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>

        <v-card-text class="mt-4 text-body-1">
          <v-row>
            <v-col>
              <p>
                {{ $t('settings.crm.activate-sync-dialog.body-texts.0', { readablePlatformName, readableCrmName }) }}
              </p>
              <p v-if="!automaticSync">
                {{ $t('settings.crm.activate-sync-dialog.body-texts.1', { readablePlatformName, readableCrmName }) }}
              </p>
              <p v-if="automaticSync">
                {{ $t('settings.crm.activate-sync-dialog.body-texts.2', { readablePlatformName, readableCrmName }) }}
              </p>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer />

          <v-btn
            text
            @click="show = false"
          >
            {{ $t('labels.cancel') }}
          </v-btn>

          <v-btn
            color="primary"
            @click="activate"
          >
            {{ $t('labels.activate') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import brandingMixin from '@/mixins/branding'
import crmMixin from '@/mixins/crm'

export default {
  mixins: [brandingMixin, crmMixin],
  props: {
    automaticSync: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false }
  },
  data () {
    return {
      show: false
    }
  },
  methods: {
    activate () {
      this.$emit('activate')
      this.show = false
    }
  }
}
</script>
