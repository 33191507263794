<template>
  <Step
    :action="revokeDeletion"
    :title="$t('settings.delete-account.step-revoke.title')"
    icon="mdi-restore"
    type="success"
  >
    <div class="black--text">
      <div class="pt-2">
        {{ $t('settings.delete-account.step-revoke.subtitle', { date: formatDate(company?.deleteOn) }) }}:
      </div>
      <ul>
        <li>{{ $t('settings.delete-account.step-free.list.0') }}</li>
        <li>{{ $t('settings.delete-account.step-free.list.1') }}</li>
        <li>{{ $t('settings.delete-account.step-free.list.2', { readablePlatformName }) }}</li>
        <li v-if="!isPlatformMarkero">
          {{ $t('settings.delete-account.step-free.list.3') }}
        </li>
        <li v-if="!isPlatformMarkero">
          {{ $t('settings.delete-account.step-free.list.4') }}
        </li>
      </ul>
    </div>
  </Step>
</template>

<script>
import brandingMixin from '@/mixins/branding'
import bus, { eventNames } from '@/lib/eventBus'

import COMPANY from '@/modules/company/queries/Company.gql'
import REVOKE_COMPANY_DELETION_WITH_DELAY from '../../queries/RevokeCompanyDeletionWithDelay.gql'
import Step from './Step.vue'

export default {
  components: { Step },
  mixins: [brandingMixin],
  methods: {
    async revokeDeletion () {
      this.loading = true
      try {
        await this.$apollo.mutate({
          mutation: REVOKE_COMPANY_DELETION_WITH_DELAY,
          variables: { id: this.$auth.user.companyId },
          refetchQueries: [{ query: COMPANY, variables: { id: this.$auth.user.companyId } }]
        })
        bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'success', text: this.$t('alerts.settings.reactivate-account.success') })
      } catch (err) {
        bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'error', text: this.$t('alerts.settings.reactivate-account.error') })
      } finally {
        this.loading = false
      }
    }
  },
  apollo: {
    company: {
      query: COMPANY,
      variables () {
        return {
          id: this.$auth.user.companyId
        }
      }
    }
  }
}
</script>
