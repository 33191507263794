<template>
  <v-row class="mx-0 my-2 mx-md-2">
    <v-col
      cols="12"
      md="6"
    >
      <div class="font-weight-bold">
        {{ $t('settings.crm.credentials.title') }}
      </div>
      <div
        v-if="onofficeConfig && onofficeConfig.isTokenVerified"
        class="grey--text"
      >
        {{ $t('settings.crm.credentials.on-office-texts.0') }}
      </div>
      <div
        v-else
        class="grey--text"
      >
        <p>
          {{ $t('settings.crm.credentials.on-office-texts.1', { platform: readablePlatformName }) }}
          <a
            href="https://smart.onoffice.de/smart/smart.php"
            target="_blank"
          >
            {{ $t('settings.crm.credentials.on-office-texts.2') }}
          </a>
          {{ $t('settings.crm.credentials.on-office-texts.3') }}
        </p>
        <v-alert
          type="info"
          dense
          text
        >
          <p
            v-html="$t('settings.crm.credentials.on-office-texts.4', { platform: readablePlatformName })"
          />
        </v-alert>
      </div>
    </v-col>
    <v-col
      cols="12"
      offset-md="1"
      md="5"
      class="pt-2 pt-md-8 d-flex flex-column align-end"
    >
      <v-chip
        v-if="onofficeConfig && onofficeConfig.isTokenVerified"
        color="success"
      >
        {{ $t('labels.connection-verified') }}
        <v-icon class="ml-4">
          mdi-check-circle
        </v-icon>
      </v-chip>
      <a
        v-else
        href="https://smart.onoffice.de/smart/smart.php"
        target="_blank"
      >
        <v-btn color="primary">{{ $t('buttons.on-office.cta') }}</v-btn>
      </a>
    </v-col>
  </v-row>
</template>

<script>
import branding from '@/mixins/branding'
import GET_ONOFFICE_CONFIG from './queries/OnofficeConfig.gql'

export default {
  mixins: [branding],
  apollo: {
    onofficeConfig: {
      query: GET_ONOFFICE_CONFIG,
      variables () {
        return {
          companyId: this.$auth.user.companyId
        }
      },
      update ({ onofficeConfig }) {
        this.$emit('isVerified', onofficeConfig && onofficeConfig.isTokenVerified)
        return onofficeConfig
      }
    }
  }
}
</script>
