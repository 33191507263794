
<template>
  <v-card
    outlined
    height="100%"
    class="d-flex justify-space-around"
    :style="{'background-color': color}"
    :class="{'flex-column':!horizontal, 'flex-column flex-md-row':horizontal}"
  >
    <v-img
      v-if="!horizontal"
      max-height="150"
      :src="isLogoCutout ? logoCutout : logo"
    />

    <div
      v-else
      class="d-flex justify-center"
    >
      <v-img
        max-width="150"
        contain
        :src="`/${isLogoCutout ? logoCutout : logo}`"
      />
    </div>

    <div class="d-flex flex-column">
      <v-card-title
        class="d-flex "
        :class="{'justify-center my-md-4':!horizontal}"
      >
        <h4>
          {{ $t(title) }}
        </h4>
      </v-card-title>

      <v-card-text
        class=" content text-body-1"
        :class="{'px-6':!horizontal}"
      >
        {{ $t(text) }}
      </v-card-text>

      <v-row
        class="mr-4 footer"
        :class="{'mt-2 mt-md-6':!horizontal, 'mt-2':horizontal}"
      >
        <v-btn
          class="primary"
        >
          {{ $t(linkText) }}
          <v-icon
            v-if="isExternal"
            class="ml-2"
            size="14"
          >
            mdi-open-in-new
          </v-icon>
        </v-btn>
      </v-row>
    </div>
  </v-card>
</template>

<script>
export default {
  props: {
    logo: {
      type: String,
      default: ''
    },
    logoCutout: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: ''
    },
    linkText: {
      type: String,
      default: ''
    },
    isExternal: {
      type: Boolean,
      default: false
    },
    horizontal: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: ''
    },
    isLogoCutout: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>
.content {
  flex: 1 1 auto;
}

.footer {
  flex: 0 1 80px;
  justify-content: flex-end;
}
</style>
