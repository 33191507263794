<template>
  <ul class="condition-list">
    <li
      v-for="(benefit, index) in benefits"
      :key="index"
      class="my-1 d-flex align-start"
    >
      <v-icon
        color="primary"
        class="mr-2 white"
      >
        mdi-check-circle
      </v-icon>

      <div>{{ $t(benefit) }}</div>
    </li>
  </ul>
</template>

<script>

const BENEFITS = [
  'settings.user.authorization-benefits.0',
  'settings.user.authorization-benefits.1',
  'settings.user.authorization-benefits.2'
]

export default {
  data () {
    return {
      benefits: BENEFITS
    }
  }
}
</script>

<style scoped>
.condition-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

</style>
