<template>
  <div
    class="d-flex justify-center flex-column align-center w-full"
  >
    <div class="px-4 text-center">
      <h1 class="display-1 font-weight-black">
        {{ $t('labels.caution') }}!
      </h1>
      <p class="mt-1 mb-2 text-subtitle-2">
        {{ $t('settings.downgrade-warning.body-texts.0', { date: formatDate(downgradeAt,'DATE_LONG') }) }}<br>
        {{ $t('settings.downgrade-warning.body-texts.1', { newPackage }) }}<br>
        {{ $t('settings.downgrade-warning.body-texts.2') }}
      </p>
    </div>
    <div class="d-flex flex-column align-center w-full">
      <ul>
        <li
          v-for="(feature,index) in lostFeatures"
          :key="index"
        >
          {{ $t(feature) }}
        </li>
      </ul>
      <v-alert
        type="warning"
        class="mt-4"
      >
        <b>{{ $t('settings.downgrade-warning.body-texts.3') }}</b> {{ $t('settings.downgrade-warning.body-texts.4') }}
      </v-alert>
    </div>

    <div
      class="d-flex justify-end mb-2 w-full"
      :class="{'flex-column': $vuetify.breakpoint.smAndDown}"
    >
      <v-btn
        :class="{'w-full mb-4': $vuetify.breakpoint.smAndDown, 'mr-4':$vuetify.breakpoint.mdAndUp}"
        @click="cancel"
      >
        {{ $t('labels.cancel') }}
      </v-btn>
      <v-btn
        color="primary"
        :class="{'w-full': $vuetify.breakpoint.smAndDown}"
        @click="submit"
      >
        {{ $t('buttons.downgrade-warning.cta') }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import { products } from '@/lib/product'
import { ProductType } from '@/lib/productTypes'
import branding from '@/mixins/branding'

export default {
  mixins: [branding],
  inject: ['contractDetails'],
  props: {
    currentPackage: {
      type: String,
      default: ''
    },
    newPackage: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      products
    }
  },
  computed: {
    lostFeatures () {
      const basicFeatures = Object.values(this.products[this.platform]).find(product => product.productName === ProductType.BASIC).features.slice(1)
      const expertFeatures = Object.values(this.products[this.platform]).find(product => product.productName === ProductType.EXPERT).features.slice(1)

      if (this.currentPackage === ProductType.EXPERT && this.newPackage === ProductType.BASIC) return expertFeatures
      else if (this.currentPackage === ProductType.EXPERT && this.newPackage === ProductType.LIGHT) return [...basicFeatures, ...expertFeatures]
      else return basicFeatures
    },
    downgradeAt () {
      return this.contractDetails?.endDate || this.contractDetails?.endDateIfCancelledNow
    }
  },
  methods: {
    cancel () {
      this.$emit('cancel')
    },
    submit () {
      this.$emit('submit')
    }
  }
}
</script>
