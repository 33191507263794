<template>
  <div class="w-full">
    <v-form
      ref="ambassadorForm"
      v-model="isFormValid"
      @change="error = ''"
    >
      <v-row class="ma-0 pa-0 mb-2">
        <v-text-field
          id="contact_person_system_name"
          ref="name"
          v-model.lazy="ambassadorData.name"
          outlined
          :label="$t('labels.name')"
          placeholder="Max Makler"
          :loading="isLoading.name"
          @click="onClick('Name', ambassadorData.name)"
          @input="(val) => update('name', val, 'Name')"
        />
      </v-row>
      <v-row class="ma-0 pa-0 mb-2">
        <PhoneField
          v-model="ambassadorData.phone"
          :loading="isLoading.phone"
          update-on-input
          @input="(val) => update('phone', val, 'Country Calling Code')"
        />
      </v-row>

      <v-row class="ma-0 pa-0 mb-2">
        <v-text-field
          ref="email"
          v-model.lazy="ambassadorData.email"
          class="pt-6"
          type="email"
          outlined
          :label="$t('labels.email')"
          placeholder="info@max-makler.de"
          :rules="[rules.email]"
          :loading="isLoading.email"
          @click="onClick('E-Mail Address', ambassadorData.email)"
          @input="(val) => update('email', val, 'E-Mail Address')"
        />
      </v-row>
    </v-form>
  </div>
</template>
<script>

import { email } from '@/lib/validation'
import featureNames from '@/lib/featureNames'
import PhoneField from '@/components/PhoneField.vue'

export default {
  components: { PhoneField },
  props: {
    ambassador: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      ambassadorData: { ...this.ambassador },
      debounceTimeout: null,
      spinnerTimeout: null,
      isLoading: {
        name: false,
        phone: false,
        email: false
      },
      isFormValid: false
    }
  },
  watch: {
    ambassador () {
      this.ambassadorData = { ...this.ambassador }
    }
  },
  created () {
    this.rules = { email }
    this.featureNames = featureNames
  },
  unmounted () {
    clearTimeout(this.spinnerTimeout)
    clearTimeout(this.debounceTimeout)
  },
  methods: {
    onClick (label, value) {
      this.$tracking.event('Settings', 'Clicked', label, value)
    },
    async update (name, value, label) {
      this.ambassadorData[name] = value
      clearTimeout(this.debounceTimeout)
      this.debounceTimeout = setTimeout(() => {
        this.isLoading[name] = true
        this.spinnerTimeout = setTimeout(async () => {
          const isFormValid = await this.$refs.ambassadorForm.validate()
          if (isFormValid) {
            this.$emit('saveSettings', { ...this.ambassadorData })
            this.$tracking.event('Settings', 'Edited', label)
          }
          this.isLoading[name] = false
        }, 1000)
      }, 2000)
    }
  }
}
</script>
