export const formatHttpsUrl = (url) => {
  if (url.startsWith('http://')) {
    const newUrl = url.split('http://')[1]
    return 'https://' + newUrl
  } else if (url.startsWith('https://')) {
    return url
  } else {
    return 'https://' + url
  }
}
