<template>
  <div>
    <p class="text-subtitle-1">
      {{ $t('settings.delete-account.help.title', { readablePlatformName }) }}:
    </p>
    <div>
      <Feature
        v-for="(featureSlug, topic) in topics"
        v-slot="{feature}"
        :key="topic"
        :feature-slug="featureSlug"
      >
        <v-row class="mt-8">
          <HelpCard
            :topic="topic"
            :config="feature.config"
            horizontal
            has-background-color
            is-logo-cutout
          />
        </v-row>
      </Feature>
    </div>
  </div>
</template>

<script>
import brandingMixin from '@/mixins/branding'
import HelpCard from '@/modules/helpCenter/HelpCard'
import featureMixin from '@/mixins/feature'

export default {
  components: { HelpCard },
  mixins: [brandingMixin, featureMixin],
  computed: {
    topics () {
      return { CALENDAR: this.featureNames.EVENT_CALENDAR, HELPCENTER: this.featureNames.HELPCENTER }
    }
  }
}
</script>
