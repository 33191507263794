<template>
  <div
    class="d-flex justify-center flex-column align-center w-full"
  >
    <div class="d-flex flex-column align-center w-full">
      <v-alert
        :type="type"
        icon="mdi mdi-information"
        class="mt-4 text-left"
      >
        <template>
          <b>{{ $t('settings.upgrade-warning.body-texts.2') }}</b>
          {{ $t('settings.upgrade-warning.body-texts.3') }}
        </template>
      </v-alert>
    </div>
  </div>
</template>
<script>
import branding from '@/mixins/branding'
import { ProductType } from '../enums/ProductType'

export default {
  mixins: [branding],
  props: {
    packageType: {
      type: String,
      default: null
    },
    type: {
      type: String,
      default: null
    }
  },
  computed: {
    activePackageType () {
      return this.$auth?.user?.productType
    },
    isBasicPackageActive () {
      return this.activePackageType === ProductType.BEGINNER
    }
  }
}
</script>
