<template>
  <div>
    <Step
      :action="() => { showDiaolog = true }"
      :title="$t('settings.subscription.delete-account')"
      icon="mdi-account-cancel"
    >
      <div class="black--text">
        {{ $t('settings.delete-account.step-active.body-texts.0',{ readablePlatformName }) }}

        {{ $t('settings.delete-account.step-active.body-texts.1') }}<br><br>
        {{ $t('settings.delete-account.step-active.body-texts.2') }}: {{ formatDate(effectiveCancellationDate,'DATE_LONG') }}<br><br>
        {{ $t('settings.delete-account.step-active.body-texts.3', { date: formatDate(contractDetails?.endDateIfCancelledNow, 'DATE_LONG') }) }}:
        <ul>
          <li>{{ $t('settings.delete-account.step-active.list.0') }}</li>
          <li>{{ $t('settings.delete-account.step-active.list.1') }}</li>
          <li>{{ $t('settings.delete-account.step-active.list.2',{ readablePlatformName }) }}</li>
          <li>{{ $t('settings.delete-account.step-active.list.3') }}</li>
        </ul>
      </div>
    </Step>

    <CancelSubscription
      :dialog="showDiaolog"
      :next-possible-termination-date="contractDetails?.endDateIfCancelledNow"
      @handleDowngrade="$emit('handleDowngrade')"
      @cancelContract="cancelContract"
      @downgradeToFreeLight="downgradeToFreeLight"
      @close="showDiaolog=false"
    />
  </div>
</template>

<script>
import brandingMixin from '@/mixins/branding'
import bus, { eventNames } from '@/lib/eventBus'
import billwerkPortal from '@/mixins/billwerkPortal'
import CancelSubscription from '../CancelSubscription'
import DOWNGRADE_TO_FREE_LIGHT from '../../queries/DowngradeToFreeLight.gql'
import UPDATE_CANCELATION from '../../queries/CreateOrUpdateCancelation.gql'

import Step from './Step.vue'

export default {
  components: { Step, CancelSubscription },
  mixins: [brandingMixin, billwerkPortal],
  data () {
    return {
      loading: false,
      showDiaolog: false
    }
  },
  computed: {
    effectiveCancellationDate () {
      const date = new Date(this.contractDetails?.endDateIfCancelledNow)
      const newDate = date.setDate(date.getDate() - 1)
      return newDate
    }
  },
  methods: {
    cancelContract () {
      if (!this.portal) return
      this.loading = true
      this.portal.contractCancel(
        async () => {
          this.getContractDetails()
          bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'success', text: this.$t('alerts.delete-account.success') })
          this.$emit('actionSuccess')
          this.loading = false
          await this.$apollo.mutate({
            mutation: UPDATE_CANCELATION,
            variables: {
              input: {
                companyId: this.$auth.user.companyId,
                completedAt: new Date()
              }
            }
          })
        },
        () => {
          bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'error', text: this.$t('alerts.delete-account.error') })
          this.loading = false
        }
      )
    },
    async downgradeToFreeLight () {
      if (!this.portal) return
      this.contractCancelLoading = true
      try {
        await this.$apollo.mutate({
          mutation: DOWNGRADE_TO_FREE_LIGHT,
          variables: {
            input: {
              companyId: this.$auth.user.companyId
            }
          }
        })

        await this.$auth.getTokenSilently({
          ignoreCache: true
        })

        this.contractCancelLoading = false
        this.$router.go(0)
      } catch (e) {
        bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'error', text: this.$t('alerts.delete-account.error-cancel') })
        this.contractCancelLoading = false
      }
    }
  }
}
</script>
