const CancelationReason = Object.freeze({
  TECHNICAL_ISSUES: 'TECHNICAL_ISSUES',
  MISSING_FEATURES: 'MISSING_FEATURES',
  SERVICE_AND_HELP: 'SERVICE_AND_HELP',
  NO_SUCCESS: 'NO_SUCCESS',
  NO_NEED: 'NO_NEED',
  HIGH_PRICING: 'HIGH_PRICING'
})

export default CancelationReason
