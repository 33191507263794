<template>
  <span
    :style="cssProps"
    class="currency"
    :class="{ 'col-4': isMobile }"
  >
    <v-autocomplete
      v-model="selectedCurrencySymbol"
      :style="{
        'width': isMobile ? '' : '85px',
        'border-radius': isMobile ? '8px !important' : '',
        'background-color': 'white',
        'border': `1px solid ${$vuetify.theme.themes.light.markero.blue}`,
        'color': $vuetify.theme.themes.light.markero.blue,
      }"
      :items="currenciesSymbol"
      outlined
      flat
      hide-details
      @change="updateCurrencySymbol"
      @mousedown="onClick"
      @blur="onBlur"
    />
  </span>
</template>
<script>
import { CurrencySymbol } from './enums/CurrencySymbol'

export default {
  props: {
    currencySymbol: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      selectedCurrencySymbol: this.currencySymbol,
      currenciesSymbol: [
        CurrencySymbol.USDOLLAR,
        CurrencySymbol.EURO,
        CurrencySymbol.SWISSFRANC
      ]
    }
  },
  computed: {
    cssProps () {
      return {
        '--blue': this.$vuetify.theme.themes.light.markero.blue
      }
    },
    isMobile () {
      return this.$vuetify.breakpoint.smAndDown
    }
  },
  watch: {
    currencySymbol (newValue) {
      this.selectedCurrencySymbol = newValue
    }
  },
  methods: {
    onBlur () {
      this.$tracking.event('Plan Selection', 'Clicked', 'Change currency close')
    },
    onClick () {
      this.$tracking.event('Plan Selection', 'Clicked', 'Change currency open')
    },
    updateCurrencySymbol () {
      this.$emit('updateCurrencySymbol', this.selectedCurrencySymbol)
    }
  }
}
</script>

<style scoped>
/* Currency Styles */
.currency {
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: normal;
  cursor: pointer;
}

.currency .v-text-field--outlined > .v-input__control > .v-input__slot {
  cursor: pointer;
}

/* Vuetify Overrides */

/* General Font Overrides */
::v-deep .theme--light.v-icon,
::v-deep .theme--light.v-input input {
  font-family: 'Poppins', sans-serif;
  color: var(--blue);
}

::v-deep .v-list-item .v-list-item__title {
  font-family: 'Poppins', sans-serif;

}

/* List Item Overrides */
::v-deep .v-list-item--highlighted {
  color: #377df6 !important;
  font-weight: 700 !important;
}

::v-deep .v-list-item--active {
  background: transparent !important;
}

::v-deep .v-list-item--link:before {
  background-color: transparent !important;
}

/* Input Field Overrides */
::v-deep .v-text-field--outlined.v-input--is-focused fieldset,
::v-deep .v-text-field--outlined fieldset {
  border: none !important;
  border-width: 0 !important;
}

/* Icon Overrides */
::v-deep .theme--light.v-icon {
  cursor: pointer;
  color: var(--blue) !important;
  caret-color: var(--blue) !important;;
}

::v-deep .theme--light.v-list-item:hover {
  background-color: #377df6;
  color: white;
}

</style>
