<template>
  <div
    class="w-full d-flex flex-column align-end"
    @mouseover="onMouseOver"
    @mouseleave="onMouseLeave"
  >
    <v-tooltip
      top
      :disabled="!disabled"
    >
      <template v-slot:activator="{ on, attrs }">
        <div
          class="w-full"
          :style="cssProps"
          v-on="on"
        >
          <v-text-field
            v-model.lazy="value"
            v-bind="attrs"
            class="w-full"
            :class="{ 'verified': isTokenVerified }"
            :counter="tokenLength"
            outlined
            :placeholder="placeholder"
            :hint="hint"
            :label="$t('settings.crm.credentials.label')"
            :disabled="isLoading || isTokenVerified"
            :loading="isLoading"
            :rules="validationRules"
          >
            <template v-slot:append>
              <v-tooltip top>
                <template v-slot:activator="{ on: onIcon, attrs: attrsIcon }">
                  <div class="mt-n2">
                    <v-btn
                      icon
                      :disabled="isLoading || disabled"
                      @click="() => save(null)"
                    >
                      <v-icon
                        v-bind="attrsIcon"
                        width="24"
                        height="24"
                        :color="isTokenVerified && !showDelete ? 'success': ''"
                        v-on="onIcon"
                      >
                        {{ isTokenVerified && !showDelete ? 'mdi-check-circle' : 'mdi-close-circle' }}
                      </v-icon>
                    </v-btn>
                  </div>
                </template>
                <span>{{ $t('settings.crm.credentials.delete') }}</span>
              </v-tooltip>
            </template>
          </v-text-field>
        </div>
      </template>
      <span>{{ $t('settings.crm.credentials.tooltip') }}</span>
    </v-tooltip>

    <v-btn
      v-if="!isTokenVerified"
      color="primary"
      :disabled="!validationRules.every((rule) => rule(value) === true) || isLoading"
      @click="validate"
    >
      <v-icon>
        mdi-sync
      </v-icon>
      {{ $t('buttons.verify.cta') }}
    </v-btn>
  </div>
</template>

<script>
import * as Sentry from '@sentry/vue'
import eventBus, { eventNames } from '@/lib/eventBus'

export default {
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    isTokenVerified: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ''
    },
    hint: {
      type: String,
      default: ''
    },
    token: {
      type: String,
      default: ''
    },
    tokenLength: {
      type: Number,
      default: 0
    },
    validationRules: {
      type: Array,
      default: () => []
    },
    saveCredentials: {
      type: Function,
      required: true
    },
    validateCredentials: {
      type: Function,
      required: true
    }
  },
  data () {
    return {
      showDelete: false,
      isLoading: false,
      value: ''
    }
  },
  computed: {
    cssProps () {
      return {
        '--succcess-color': this.$vuetify.theme.themes.light.success
      }
    }
  },
  watch: {
    token () {
      this.value = this.token
    },
    isTokenVerified (isTokenVerified) {
      if (isTokenVerified && this.value) {
        this.validate(false)
      }
    }
  },
  methods: {
    async onMouseOver () {
      this.showDelete = true
    },
    async onMouseLeave () {
      this.showDelete = false
    },
    async save (token) {
      this.isLoading = true
      this.value = token
      try {
        await this.saveCredentials(token)
      } catch (err) {
        eventBus.$emit(eventNames.SHOW_SNACKBAR, { color: 'error', text: this.$t('alerts.settings.crm.error') })
      } finally {
        this.isLoading = false
      }
    },
    async validate (showSuccess = true) {
      this.isLoading = true
      try {
        await this.validateCredentials(this.value)
        if (showSuccess) {
          eventBus.$emit(eventNames.SHOW_SNACKBAR, { color: 'success', text: this.$t('alerts.settings.crm.credentials.success') })
        }
      } catch (err) {
        eventBus.$emit(eventNames.SHOW_SNACKBAR, { color: 'error', text: this.$t('alerts.settings.crm.credentials.error') })
        Sentry.captureMessage(`Invalid Token for company: ${this.$auth.user.companyId}`)
      } finally {
        this.isLoading = false
      }
    }
  }
}
</script>

<style scoped>
  .verified >>> .v-text-field__slot input {
    color: var(--succcess-color);
  }
  .verified >>> .v-text-field__slot label {
    color: var(--succcess-color);
  }
  .verified.v-text-field--outlined >>> fieldset {
    border-color: var(--succcess-color);
  }
</style>
