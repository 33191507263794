export const TOOLS = [
  {
    type: 'VALUATION',
    action: 'labels.valuated',
    order: 1,
    icon: 'mdi-home',
    key: 'valuations',
    label: 'labels.tools.valuation'
  },
  {
    type: 'RETURN_CALL',
    action: 'labels.arranged',
    order: 2,
    icon: 'mdi-phone',
    key: 'returnCalls',
    label: 'labels.tools.return-call'
  },
  {
    type: 'PROPERTY_REQUEST',
    action: 'labels.issued',
    order: 3,
    icon: 'mdi-home-search',
    key: 'propertyRequests',
    label: 'labels.tools.property-request'
  },
  {
    type: 'QUIZZARD',
    action: 'labels.conducted',
    order: 4,
    icon: 'mdi-home-analytics',
    key: 'quizPreConversion',
    label: 'labels.tools.quizzard'
  },
  {
    type: 'LIFE_ANNUITY',
    action: 'labels.conducted',
    order: 5,
    icon: 'mdi-checkbox-marked-circle',
    key: 'lifeAnnuities',
    label: 'labels.tools.life-annuity'
  },
  {
    type: 'PARTIAL_SALE',
    action: 'labels.conducted',
    order: 6,
    icon: 'mdi-checkbox-marked-circle',
    key: 'partialSales',
    label: 'labels.tools.partial-sale'
  },
  {
    type: 'PLOT_VALUATION',
    action: 'labels.conducted',
    order: 7,
    icon: 'mdi-image-filter-hdr',
    key: 'plotValuations',
    label: 'labels.tools.plot-valuation'
  }
]

export const TOOLS_WITH_DOWNLOADS = [...TOOLS, {
  type: 'DOWNLOAD',
  action: 'labels.downloaded',
  order: 8,
  icon: 'mdi-file-document-outline',
  key: 'downloads',
  label: 'labels.tools.download'
}]
