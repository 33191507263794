<template>
  <div>
    <v-dialog
      :value="show"
      width="550"
      @input="close"
    >
      <v-card>
        <v-card-title
          class="pa-0"
        >
          <v-toolbar
            color="primary"
            dark
            flat
          >
            <v-toolbar-title v-if="create">
              {{ $t('buttons.add-user.cta') }}
            </v-toolbar-title>
            <v-toolbar-title v-else>
              {{ $t('settings.user.upsert-user-dialog.label') }}
            </v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-btn
                icon
                dark
                @click="close"
              >
                <v-icon large>
                  mdi-close
                </v-icon>
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>

        <v-card-text class="mt-6 pt-2 text-body-1 user-form">
          <div>
            <v-form
              ref="form"
              v-model="valid"
              @submit.prevent="editUser"
            >
              <v-text-field
                v-model="firstname"
                :label="$t('labels.first-name')"
                outlined
                :rules="[rules.required]"
                @click="onClick('First Name', firstname)"
              />
              <v-text-field
                v-model="lastname"
                :label="$t('labels.last-name')"
                outlined
                :rules="[rules.required]"
                @click="onClick('Last Name', lastname)"
              />
              <v-text-field
                v-model="email"
                :rules="[rules.email, rules.required]"
                outlined
                :label="$t('labels.email')"
                required
                @click="onClick('Email', email)"
              />
              <PhoneField
                v-model="phone"
                :loading="isLoading.phone"
                update-on-input
                :hint="$t('settings.user.upsert-user-dialog.mobile-hint')"
              />
              <Feature :feature-slug="featureNames.AUTHORIZATION">
                <template>
                  <div class="pt-6">
                    <h3>{{ $t('settings.user.upsert-user-dialog.title') }}</h3>
                    <p class="caption">
                      {{ $t('settings.user.upsert-user-dialog.subtitle') }}
                    </p>
                    <v-radio-group
                      v-model="role"
                      hide-details
                      :rules="[rules.required]"
                      class="role-select"
                    >
                      <v-radio
                        v-for="{id: roleId, name, description} in roles"
                        :key="roleId"
                        :value="roleId"
                        @click="onClick('Role', name)"
                      >
                        <template v-slot:label>
                          <div class="ml-2">
                            <h4>{{ name }}</h4>
                            <p class="caption mb-0">
                              {{ description }}
                            </p>
                          </div>
                        </template>
                      </v-radio>
                    </v-radio-group>
                  </div>
                </template>
                <template v-slot:inactive>
                  <div class="pt-6">
                    <h3>{{ $t('settings.user.upsert-user-dialog.title') }}</h3>
                    <p class="caption">
                      {{ $t('settings.user.upsert-user-dialog.subtitle') }}
                    </p>
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <div
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-radio-group
                            hide-details
                            class="role-select"
                            disabled
                          >
                            <v-radio
                              v-for="{id: roleId, name, description} in roles"
                              :key="roleId"
                              :value="roleId"
                              @click="onClick('Role', name)"
                            >
                              <template v-slot:label>
                                <div class="ml-2">
                                  <h4>{{ name }}</h4>
                                  <p class="caption mb-0">
                                    {{ description }}
                                  </p>
                                </div>
                              </template>
                            </v-radio>
                          </v-radio-group>
                        </div>
                      </template>
                      <span>{{ $t('settings.user.upsert-user-dialog.activation-hint') }}</span>
                    </v-tooltip>
                  </div>
                </template>
              </Feature>
            </v-form>
          </div>
        </v-card-text>

        <v-card-actions class="pb-4">
          <v-btn
            text
            @click="close"
          >
            {{ $t('labels.cancel') }}
          </v-btn>
          <v-spacer />

          <v-btn
            color="primary"
            class="px-4 mr-2 mb-2"
            :disabled="isLoading"
            :loading="isLoading"
            @click="submit"
          >
            {{ create ? $t('settings.user.upsert-user-dialog.create-account') : $t('labels.save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import UPDATE_USER from './queries/UpdateUser.gql'
import CREATE_USER from './queries/CreateUser.gql'
import USERS from './queries/Users.gql'
import PhoneField from '@/components/PhoneField.vue'
import ROLES from './queries/Roles.gql'
import eventBus, { eventNames } from '@/lib/eventBus'
import { email, required } from '@/lib/validation'
import feature from '@/mixins/feature'
import { Feature } from 'feature-config'

export default {
  components: { PhoneField, Feature },
  mixins: [feature],
  props: {
    user: { type: Object, default: () => ({}) },
    create: { type: Boolean, default: false },
    show: { type: Boolean, default: false }
  },
  data () {
    return {
      rules: { email, required },
      isLoading: false,
      valid: false,
      defaultRole: null,
      id: this.user?.id,
      firstname: this.user?.firstname,
      lastname: this.user?.lastname,
      email: this.user?.email,
      role: this.user?.role?.id || this.defaultRole,
      phone: this.user?.phone
    }
  },
  watch: {
    user: {
      handler (user) {
        this.id = user?.id
        this.firstname = user?.firstname
        this.lastname = user?.lastname
        this.email = user?.email
        this.role = user?.role?.id || this.defaultRole
        this.phone = user?.phone
        if (this.$refs.form) {
          this.$refs.form.resetValidation()
        }
      },
      deep: true
    }
  },
  methods: {
    onClick (label, value) {
      this.$tracking.event('Settings', 'Clicked', label, value)
    },
    async close () {
      this.$emit('close')
    },
    async submit () {
      this.$refs.form.validate()
      if (!this.valid) {
        return
      }
      if (this.create) {
        await this.createUser()
      } else {
        await this.editUser()
      }
    },
    async editUser () {
      try {
        this.isLoading = true
        await this.$apollo.mutate({
          mutation: UPDATE_USER,
          variables: {
            input: {
              id: this.user.id,
              firstname: this.firstname,
              lastname: this.lastname,
              email: this.email,
              phone: this.phone,
              ...(this.isFeatureActive(this.featureNames.AUTHORIZATION) && { role: this.role })
            }
          },
          refetchQueries: [{
            query: USERS,
            variables: { companyId: this.$auth.user.companyId }
          }]
        })
        this.$tracking.event('Settings', 'Clicked', 'Edit User')
        eventBus.$emit(eventNames.SHOW_SNACKBAR, { color: 'success', text: this.$t('alerts.settings.user.success') })
        this.close()
      } catch (e) {
        eventBus.$emit(eventNames.SHOW_SNACKBAR, { color: 'error', text: this.$t('alerts.settings.user.error') })
      } finally {
        this.isLoading = false
      }
    },
    async createUser () {
      try {
        this.isLoading = true
        await this.$apollo.mutate({
          mutation: CREATE_USER,
          variables: {
            input: {
              companyId: this.$auth.user.companyId,
              firstname: this.firstname,
              lastname: this.lastname,
              email: this.email,
              phone: this.phone,
              ...(this.isFeatureActive(this.featureNames.AUTHORIZATION) && { role: this.role }),
              receiveNotificationEmails: {
                leadAction: true
              }
            }
          },
          refetchQueries: [{
            query: USERS,
            variables: { companyId: this.$auth.user.companyId }
          }]
        })
        this.$tracking.event('Settings', 'Clicked', 'Create User')
        eventBus.$emit(eventNames.SHOW_SNACKBAR, {
          color: 'success',
          text: this.$t('alerts.settings.user.upsert-texts.0'),
          texts: [this.$t('alerts.settings.user.upsert-texts.1')],
          time: 10000
        })
        this.close()
        this.firstname = null
        this.lastname = null
        this.email = null
        this.role = null
        this.phone = null
      } catch (e) {
        eventBus.$emit(eventNames.SHOW_SNACKBAR, { color: 'error', text: this.$t('alerts.settings.user.error') })
      } finally {
        this.isLoading = false
      }
    }
  },
  apollo: {
    roles: {
      query: ROLES,
      update ({ roles }) {
        this.defaultRole = this.role || roles.find(role => role.isDefault)?.id
        return roles.filter(role => role.isAssignable)
      }
    }
  }
}
</script>

<style scoped>
.user-form {
  max-height: 600px;
  overflow: auto;
}

.role-select .v-item--active {
  background-color: rgb(230, 230, 230);
}
.role-select .v-radio {
  border-radius: 8px;
  padding: 8px;
}
</style>
