<template>
  <div>
    <v-chip
      :color="state.color"
      text-color="white"
    >
      <v-icon left>
        {{ state.icon }}
      </v-icon>
      <span class="font-weight-bold">{{ $t('labels.synchronisation-state.status') }} <b>{{ $t(state.label) }}</b></span>
    </v-chip>
  </div>
</template>

<script>

const states = {
  ACTIVE: {
    icon: 'mdi-sync',
    color: 'success',
    label: 'labels.synchronisation-state.active'
  },
  INACTIVE: {
    icon: 'mdi-sync-off',
    color: 'warning',
    label: 'labels.synchronisation-state.inactive'
  }
}

export default {
  props: {
    isActive: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    state () {
      return this.isActive ? states.ACTIVE : states.INACTIVE
    }
  }
}
</script>
