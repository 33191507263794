<template>
  <SettingsCard
    :title="$t('settings.email.salutation-settings.title')"
    :subtitle="$t('settings.email.salutation-settings.subtitle')"
    icon="mdi-chat"
    :is-default-open="false"
  >
    <v-row class="ma-2">
      <v-col
        cols="12"
        md="6"
      >
        <div class="font-weight-bold">
          {{ $t('settings.email.salutation-settings.body-texts.0') }}
        </div>
        <div class="grey--text">
          {{ $t('settings.email.salutation-settings.body-texts.1') }}
        </div>
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <TextForm
          :fields="salutationFields"
          @update="updateCustomWording"
        />
        <div class="w-50 d-flex justify-end">
          <v-btn
            outlined
            :disabled="!hasCustomWording()"
            @click="() => updateCustomWording({ salutation: '', farewell: '' })"
          >
            {{ $t('labels.reset') }}
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </SettingsCard>
</template>

<script>
import UPDATE_COMPANY_CUSTOM_WORDING from './queries/UpdateCompanyCustomWording.gql'
import SettingsCard from '@/components/SettingsCard.vue'
import TextForm from '@/components/TextForm.vue'
import featureMixin from '@/mixins/feature'
import eventBus, { eventNames } from '@/lib/eventBus'

export default {
  components: { SettingsCard, TextForm },
  mixins: [featureMixin],
  props: {
    company: {
      type: Object,
      default: () => ({})
    },
    refetchCompany: {
      type: Function,
      default: () => {}
    }
  },
  data () {
    return {
      isLoading: false,
      customWording: {
        salutation: this.company.customWording?.salutation || '',
        farewell: this.company.customWording?.farewell || ''
      }
    }
  },
  computed: {
    salutationFields () {
      return [{
        label: this.$t('settings.email.salutation-settings.labels.salutation'),
        name: 'salutation',
        placeholder: '',
        required: false,
        value: this.customWording.salutation
      },
      {
        label: this.$t('settings.email.salutation-settings.labels.farewell'),
        name: 'farewell',
        placeholder: '',
        required: false,
        value: this.customWording.farewell
      }]
    }
  },
  watch: {
    company: {
      deep: true,
      handler () {
        this.customWording.farewell = this.company.customWording.farewell
        this.customWording.salutation = this.company.customWording.salutation
      }

    }
  },
  methods: {
    hasCustomWording () {
      return Boolean(this.customWording.salutation || this.customWording.farewell)
    },
    async updateCustomWording (value) {
      this.isLoading = true
      try {
        await this.$apollo.mutate({
          mutation: UPDATE_COMPANY_CUSTOM_WORDING,
          variables: {
            input: {
              companyId: this.$auth.user.companyId,
              salutation: this.customWording.salutation,
              farewell: this.customWording.farewell,
              ...value
            }
          }
        })
        this.$tracking.event(
          'Settings',
          'Clicked',
          'Update Company Custom Wording')
        eventBus.$emit(eventNames.SHOW_SNACKBAR, { color: 'success', text: this.$t('alerts.settings.email.salutation-settings.success') })
        this.refetchCompany()
      } catch (err) {
        eventBus.$emit(eventNames.SHOW_SNACKBAR, { color: 'error', text: this.$t('alerts.settings.email.salutation-settings.error') })
      } finally {
        this.isLoading = false
      }
    }
  }
}
</script>
