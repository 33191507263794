<template>
  <v-dialog
    v-model="dialog"
    width="600"
    color="white"
    persistent
  >
    <div class="dialog pa-5">
      <div
        v-if="isLoading"
        class="d-flex justify-center align-center"
        style="min-height: 250px"
      >
        <ProgressCircular />
      </div>
      <template v-if="!isLoading && !hasError">
        <div class="text-center">
          <span
            class="mdi mdi-check-circle success-icon"
            :style="successIconColor"
          />
        </div>
        <h1 class="text-center">
          {{ $t('payment.package-update.success.title', {updatedPackageType}) }}!
        </h1>
        <p
          v-if="isPackageDowngraded"
          class="text-center mb-0"
        >
          {{ $t('payment.package-update.success.description-downgrade', {productType}) }}
        </p>
        <p
          v-else
          class="text-center mb-0"
        >
          {{ $t('payment.package-update.success.description-upgrade', {updatedPackageType}) }}
        </p>
      </template>
      <template v-if="!isLoading && hasError">
        <div class="text-center">
          <span
            class="mdi mdi-alert-circle-outline error-icon"
            :style="errorIconColor"
          />
        </div>
        <h1 class="text-center">
          {{ $t('payment.package-update.error.title') }}!
        </h1>
        <p class="text-center mb-0">
          {{ errorMessage }}
        </p>
      </template>
    </div>
  </v-dialog>
</template>
<script>

import ProgressCircular from '@/components/ProgressCircular.vue'
import { ProductType } from '../enums/ProductType'

export default {
  components: { ProgressCircular },
  props: {
    updatedPackageType: {
      type: String,
      default: ''
    },
    errorMessage: {
      type: String,
      default: ''
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      dialog: true,
      navigatePageTimeout: null
    }
  },
  computed: {
    productType () {
      return this.$auth?.user?.productType
    },
    isPackageDowngraded () {
      return this.productType === ProductType.ADVANCED &&
        this.updatedPackageType === ProductType.BEGINNER
    },
    hasError () {
      return this.errorMessage !== ''
    },
    successIconColor () {
      return {
        color: this.$vuetify.theme.themes.light.primary
      }
    },
    errorIconColor () {
      return {
        color: 'red'
      }
    }
  },
  mounted () {
    this.initPageNavigation()
  },
  unmounted () {
    if (this.navigatePageTimeout) {
      clearTimeout(this.navigatePageTimeout)
    }
  },
  methods: {
    initPageNavigation () {
      this.navigatePageTimeout = setTimeout(() => {
        this.handleNavigation()
      }, 8000)
    },
    handleNavigation () {
      if (!this.hasError) {
        this.navigateToDashboard()
      } else {
        this.$emit('close')
      }
    },
    navigateToDashboard () {
      window.location.href = window.location.origin + '/dashboard'
    }
  }
}
</script>

<style scoped>
.dialog {
  background-color: white;
  z-index: 999;
}
.success-icon, .error-icon {
  font-size: 100px;
}
</style>
