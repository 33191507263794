<template>
  <div class="d-flex align-center flex-column">
    <div class="d-flex align-center">
      <v-icon class="mr-1">
        mdi-check
      </v-icon>
      <span class="text-subtitle-1">{{ $t('settings.delete-account.loading.title') }}</span>
    </div>
    <p class="mt-2">
      {{ selectedVariant === 'free' ? $t('settings.delete-account.loading.text-free') : $t('settings.delete-account.loading.text-non-free') }}
    </p>
    <v-progress-linear
      color="primary"
      class="mt-4 linear-progress"
      size="20"
      indeterminate
    />
  </div>
</template>

<script>
import brandingMixin from '@/mixins/branding'
export default {
  mixins: [brandingMixin],
  props: {
    selectedVariant: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      timeout: null
    }
  },
  unmounted () {
    clearTimeout(this.timeout)
  },
  created () {
    this.timeout = setTimeout(() => {
      this.$emit('handleCancelation')
    }, 3000)
  }

}
</script>
<style scoped>
.linear-progress{
  max-width: 200px;
}
</style>
