<template>
  <SettingsCard
    :title="$t('settings.company.website.title')"
    :subtitle="$t('settings.company.website.subtitle')"
    icon="mdi-web"
    :is-default-open="false"
  >
    <div class="px-4 pt-4">
      <v-row>
        <v-col
          cols="12"
          sm="6"
          class="px-0 px-md-2"
        >
          <div>
            <div class="d-flex align-center">
              <span class="font-weight-bold">
                {{ $t('settings.company.website.body-texts.0') }}
              </span>
            </div>
            <span class="grey--text">
              {{ $t('settings.company.website.body-texts.1') }}<br>
              {{ $t('settings.company.website.body-texts.2') }}
            </span>
          </div>
        </v-col>
        <v-col
          cols="12"
          sm="6"
          class="justify-center px-0 mt-4 d-flex px-md-2"
        >
          <LinkSettings
            :links="links"
            @updateSettings="saveSettings"
          />
        </v-col>
      </v-row>
      <Feature
        v-slot="{feature}"
        :feature-slug="featureNames.COMPANY"
      >
        <v-row v-if="feature.config.isSlugEditable">
          <v-col
            cols="12"
            sm="6"
            class="px-0 px-md-2"
          >
            <div>
              <div class="d-flex align-center">
                <span class="font-weight-bold">
                  {{ $t('settings.company.website.body-texts-light.0') }}
                </span>
              </div>
              <div class="grey--text">
                <p>{{ $t('settings.company.website.body-texts-light.1') }}</p>
                <p
                  v-html="$t('settings.company.website.body-texts-light.2')"
                />
                <p>
                  {{ $t('settings.company.website.body-texts-light.3') }}
                </p>
              </div>
            </div>
          </v-col>
          <v-col
            cols="12"
            sm="6"
            class="justify-center px-0 mt-4 d-flex px-md-2"
          >
            <SlugData
              v-bind="companySlug"
              :parent-error="$t(slugError)"
              :parent-success="$t(slugSuccess)"
              @updateSlug="saveSlug"
            />
          </v-col>
        </v-row>
      </Feature>
    </div>
  </SettingsCard>
</template>

<script>
import SettingsCard from '@/components/SettingsCard.vue'
import LinkSettings from './LinkSettings'
import SlugData from '@/components/forms/SlugData'
import UPDATE_COMPANY_LINKS from './queries/UpdateCompanyLinks.gql'
import UPDATE_COMPANY_SLUG from './queries/UpdateCompanySlug.gql'
import PENDING_CHANGES_FOR_REGENERATION from '@/queries/PendingChangesForRegeneration.gql'
import bus, { eventNames } from '@/lib/eventBus'
import { formatHttpsUrl } from '@/lib/urlFormatter'
import feature from '@/mixins/feature'

export default {
  components: {
    SettingsCard,
    LinkSettings,
    SlugData
  },
  mixins: [feature],
  props: {
    company: {
      type: Object,
      default: () => ({})
    },
    settings: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      slugError: null,
      slugSuccess: null,
      links: this.company.links
    }
  },
  computed: {
    companySlug () {
      const { slug } = this.company
      return { slug }
    }
  },
  watch: {
    company () {
      this.links = this.company.links
    }
  },
  methods: {
    async saveSettings (linksData) {
      const { __typename, ...links } = linksData
      Object.keys(links).forEach(key => {
        links[key] = links[key] ? formatHttpsUrl(links[key]) : null
      })
      try {
        await this.$apollo.mutate({
          mutation: UPDATE_COMPANY_LINKS,
          variables: {
            input: {
              companyId: this.$auth.user.companyId,
              links
            }
          },
          refetchQueries: [{ query: PENDING_CHANGES_FOR_REGENERATION, variables: { companyId: this.company.id } }]
        })
        this.links = links
        bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'success', text: this.$t('alerts.settings.company.success') })
      } catch (e) {
        bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'error', text: this.$t('alerts.settings.company.error') })
      }
    },
    async saveSlug (slug) {
      this.slugError = null
      this.slugSuccess = null
      try {
        await this.$apollo.mutate({
          mutation: UPDATE_COMPANY_SLUG,
          variables: {
            input: {
              companyId: this.$auth.user.companyId,
              newSlug: slug
            }
          },
          refetchQueries: [{ query: PENDING_CHANGES_FOR_REGENERATION, variables: { companyId: this.company.id } }]
        })
        const slugSuccessText = 'alerts.settings.company.slug-data.success'
        this.slugSuccess = slugSuccessText
        bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'success', text: this.$t(slugSuccessText) })
      } catch (e) {
        if (e.message.match(/Missing permission/)) {
          const permissionErrorText = 'alerts.settings.company.slug-data.error-permission'
          this.slugError = permissionErrorText
          bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'error', text: this.$t(permissionErrorText) })
        } else if (e.message.match(/Slug already taken/)) {
          const alreadyTakenErrorText = 'alerts.settings.company.slug-data.error-taken'
          this.slugError = alreadyTakenErrorText
          bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'error', text: this.$t(alreadyTakenErrorText) })
        } else if (e.message.match(/Expected/)) {
          const validationErrorText = 'alerts.settings.company.slug-data.error-blacklist'
          this.slugError = validationErrorText
          bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'error', text: this.$t(validationErrorText) })
        } else {
          const serverErrorText = 'alerts.settings.company.slug-data.error'
          this.slugError = serverErrorText
          bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'error', text: this.$t(serverErrorText) })
        }
      }
    }
  }
}
</script>
