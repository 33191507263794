<template>
  <SettingsCard
    :title="$t('settings.profile.title')"
    :subtitle="$t('settings.profile.subtitle', { readablePlatformName })"
    :is-default-open="false"
  >
    <div class="pa-4">
      <v-row>
        <v-col
          cols="12"
          sm="6"
          class="px-0 px-md-2"
        >
          <div>
            <span class="font-weight-bold">
              {{ $t('settings.profile.body-texts.0') }}<br>
            </span>
            <span class="grey--text">
              {{ $t('settings.profile.body-texts.1', { readablePlatformName }) }}
            </span>
          </div>
        </v-col>
        <v-col
          cols="12"
          sm="6"
          class="px-0 px-md-2"
        >
          <v-form
            ref="userDataForm"
            v-model="isFormValid"
          >
            <v-container class="ma-0 pa-0">
              <v-row>
                <v-col>
                  <v-text-field
                    v-model.lazy="user.firstname"
                    outlined
                    :label="$t('labels.first-name')"
                    :loading="isLoading.firstname"
                    :rules="[rules.required ]"
                    @click="onClick('First Name', user.firstname)"
                    @input="(val) => update('firstname', val)"
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <v-text-field
                    v-model.lazy="user.lastname"
                    outlined
                    :label="$t('labels.last-name')"
                    :loading="isLoading.lastname"
                    :rules="[rules.required ]"
                    @click="onClick('Last Name', user.lastname)"
                    @input="(val) => update('lastname', val)"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-col>
      </v-row>
      <v-divider />
      <!-- EMAIL CHANGE -->
      <v-row class="mt-4">
        <v-col
          cols="12"
          sm="6"
          class="px-0 px-md-2"
        >
          <div>
            <span class="font-weight-bold">
              {{ $t('settings.profile.body-texts.2') }}<br>
            </span>
            <span class="grey--text">
              {{ $t('settings.profile.body-texts.3', { readablePlatformName }) }}<br>
              <b>{{ $t('labels.caution') }}:</b> {{ $t('settings.profile.body-texts.4') }}
            </span>
          </div>
        </v-col>
        <v-col
          cols="12"
          sm="6"
          class="px-0 px-md-2"
        >
          <v-alert
            v-if="showAlert"
            icon="mdi-lock"
            type="warning"
            text
          >
            {{ $t('alerts.settings.profile.confirm', { email: emailChangeRequest.newEmail }) }}
          </v-alert>

          <v-form
            v-model="valid"
            @submit.prevent="submitEmailChangeRequest"
          >
            <v-container class="ma-0 pa-0">
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="user.email"
                    :label="$t('labels.email')"
                    outlined
                    :rules="[rules.email, rules.required]"
                    @click="onClick('E-Mail Address', user.email)"
                  />
                </v-col>
              </v-row>
            </v-container>
            <v-col class="text-right ma-0 pa-0">
              <v-btn
                type="submit"
                :disabled="!isDirty('email')"
                color="primary"
                right
              >
                {{ $t('buttons.settings.profile.cta') }}
              </v-btn>
            </v-col>
          </v-form>
        </v-col>
      </v-row>
    </div>
  </SettingsCard>
</template>

<script>
import { email, required } from '@/lib/validation'
import CREATE_EMAIL_CHANGE_REQUEST from './queries/CreateEmailChangeRequest.gql'
import UPDATE_USER from './queries/UpdateUser.gql'
import EMAIL_CHANGE_REQUEST from './queries/EmailChangeRequest.gql'
import IDENTITY from '@/queries/Identity.gql'
import bus, { eventNames } from '@/lib/eventBus'
import SettingsCard from '@/components/SettingsCard.vue'
import brandingMixin from '@/mixins/branding'

export default {
  components: { SettingsCard },
  mixins: [brandingMixin],
  data () {
    return {
      originalUser: null,
      user: {
        firstname: this.$auth.user.given_name,
        lastname: this.$auth.user.family_name,
        email: this.$auth.user.email
      },
      rules: {
        email,
        required
      },
      error: '',
      emailChangeRequest: {
        hasSendRequest: false,
        newEmail: ''
      },
      valid: false,
      debounceTimeout: null,
      spinnerTimeout: null,
      isLoading: {
        firstname: false,
        lastname: false
      },
      isFormValid: false
    }
  },

  computed: {
    showAlert () {
      return this.emailChangeRequest.hasSendRequest
    }
  },

  unmounted () {
    clearTimeout(this.spinnerTimeout)
    clearTimeout(this.debounceTimeout)
  },

  async mounted () {
    await this.fetchOriginalUser()
    await this.checkEmailChangeRequestExists()
  },

  methods: {
    onClick (label, value) {
      this.$tracking.event('Settings', 'Clicked', label, value)
    },
    async update (name, value) {
      this.user[name] = value
      clearTimeout(this.debounceTimeout)
      this.debounceTimeout = setTimeout(() => {
        this.isLoading[name] = true
        this.spinnerTimeout = setTimeout(async () => {
          const isFormValid = await this.$refs.userDataForm.validate()
          if (isFormValid) {
            this.submit()
          }
          this.isLoading[name] = false
        }, 1000)
      }, 2000)
    },
    isDirty (name) {
      return this.originalUser && this.user[name] !== this.originalUser[name]
    },

    async fetchOriginalUser () {
      try {
        const { data: identity } = await this.$apollo.query({
          query: IDENTITY,
          skip () {
            return this.$auth.loading || !this.$auth.isAuthenticated
          }
        })
        this.originalUser = identity.identity.user
      } catch (e) {
        this.error = e
      }
    },

    async checkEmailChangeRequestExists () {
      try {
        const { data: emailChangeRequest } = await this.$apollo.query({
          query: EMAIL_CHANGE_REQUEST,
          variables: {
            id: this.originalUser.id
          }
        })
        this.$tracking.event('Settings', 'Edited', 'Change Email Address Request Exists')
        this.emailChangeRequest.newEmail = emailChangeRequest.newEmail
        this.emailChangeRequest.hasSendRequest = !!this.emailChangeRequest.newEmail
      } catch (e) {
        this.error = e
      }
    },

    async submit () {
      const hasNewFirstName = this.user.firstname !== this.originalUser.firstname
      const hasNewLastName = this.user.lastname !== this.originalUser.lastname

      // if the name changed, update it right away
      if (hasNewFirstName || hasNewLastName) {
        const firstname = hasNewFirstName ? this.user.firstname : this.originalUser.firstname
        const lastname = hasNewLastName ? this.user.lastname : this.originalUser.lastname
        const { id } = this.originalUser
        try {
          await this.$apollo.mutate({
            mutation: UPDATE_USER,
            variables: {
              input: {
                id,
                firstname,
                lastname
              }
            }
          })
          this.$tracking.event('Settings', 'Edited', 'Update User Profile')
          this.$auth.user.given_name = firstname
          this.$auth.user.family_name = lastname
          this.originalUser.firstname = firstname
          this.originalUser.lastname = lastname
          bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'success', text: this.$t('alerts.settings.profile.success') })
        } catch (e) {
          bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'error', text: this.$t('alerts.settings.profile.error') })
          this.error = e
        }
      }
    },
    async submitEmailChangeRequest () {
    // if the email changed, wait for authentication
      if (this.user.email !== this.$auth.user.email) {
        try {
          const { data: emailChangeRequest } = await this.$apollo.mutate({
            mutation: CREATE_EMAIL_CHANGE_REQUEST,
            variables: {
              input: { id: this.originalUser.id, newEmail: this.user.email }
            }
          })
          this.$tracking.event(
            'Settings',
            'Edited',
            'Email Address Change Request')
          this.emailChangeRequest.newEmail = emailChangeRequest.newEmail
          this.emailChangeRequest.hasSendRequest = !!this.emailChangeRequest.newEmail
          this.user.email = this.originalUser.email
        } catch (e) {
          this.error = e
        }
      }
    }
  }
}
</script>
