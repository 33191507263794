<template>
  <div class="d-flex align-center">
    <v-icon
      class="mr-2"
      :color="state.color"
    >
      {{ state.icon }}
    </v-icon>
    <span>{{ $t('labels.subscription-state.label') }} <b>{{ $t(state.label) }}</b>.</span>
  </div>
</template>

<script>
import SubscriptionState from '../enums/SubscriptionState'

const subscriptionStates = {
  [SubscriptionState.FREE]: {
    icon: 'mdi-account-check',
    color: 'success',
    label: 'labels.subscription-state.active'
  },
  [SubscriptionState.ACTIVE]: {
    icon: 'mdi-account-check',
    color: 'success',
    label: 'labels.subscription-state.active'
  },
  [SubscriptionState.INACTIVE]: {
    icon: 'mdi-account-cancel',
    color: 'error',
    label: 'labels.subscription-state.inactive'
  },
  [SubscriptionState.CANCELED]: {
    icon: 'mdi-account-cancel',
    color: 'info',
    label: 'labels.subscription-state.cancelled'
  }
}

export default {
  props: {
    subscriptionState: {
      type: String,
      default: null
    }
  },
  computed: {
    state () {
      return subscriptionStates[this.subscriptionState || SubscriptionState.FREE]
    }
  }

}
</script>
