<template>
  <div>
    <div class="d-flex justify-space-between flex-md-row flex-column">
      <PackageLogo :current-package="currentPackage" />
      <Permission permission="subscription:change">
        <Feature :feature-slug="featureNames.CHANGE_SUBSCRIPTION">
          <v-btn
            v-if="!isExpert"
            color="primary"
            :class="{'w-full mt-4':$vuetify.breakpoint.smAndDown}"
            :disabled="disabled"
            @click="changeSubscription"
          >
            <v-icon>mdi-swap-horizontal</v-icon>
            {{ $t('buttons.package.cta') }}
          </v-btn>
        </Feature>
      </Permission>
    </div>

    <ChangePackage
      :show-dialog.sync="isMarkeroUpgradeDialogOpen"
      :company="company"
      @downgrade="$emit('downgrade')"
    />

    <ChangeSubscription
      :dialog="isUpgradeDialogOpen"
      :current-package="currentPackage"
      :company="company"
      :hide-downgrade="true"
      @close="isUpgradeDialogOpen = false"
    />
  </div>
</template>

<script>
import { ProductType } from '@/lib/productTypes'
import ChangeSubscription from '../ChangeSubscription'
import ChangePackage from './ChangePackage'
import PackageLogo from '@/components/PackageLogo'
import featureMixin from '@/mixins/feature'
import Permission from '@/components/Permission'
import brandingMixin from '@/mixins/branding'

export default {
  components: {
    ChangePackage,
    ChangeSubscription,
    PackageLogo,
    Permission
  },

  mixins: [featureMixin, brandingMixin],

  props: {
    company: {
      type: Object,
      default: () => {}
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      isUpgradeDialogOpen: false,
      isMarkeroUpgradeDialogOpen: false
    }
  },

  computed: {
    isExpert () {
      return this.company?.productType === ProductType.EXPERT
    },
    currentPackage () {
      return this.company?.productType
    }
  },

  watch: {
    '$route.query.showUpgrade': {
      handler (value) {
        if (value) {
          this.openDialog()
        }
      },
      immediate: true
    },

    isUpgradeDialogOpen (value) {
      if (!value) {
        this.unsetQueryParamShowUpgrade()
      }
    },

    isMarkeroUpgradeDialogOpen (value) {
      if (!value) {
        this.unsetQueryParamShowUpgrade()
      }
    }
  },

  methods: {
    openDialog () {
      if (this.isPlatformMarkero) {
        this.isMarkeroUpgradeDialogOpen = true
      } else {
        this.isUpgradeDialogOpen = true
      }
    },

    unsetQueryParamShowUpgrade () {
      // As of now, this causes the page to reload. This is not ideal, but it's also not a big deal.
      // I couldn't find the real cause, but it's related to the <v-stepper> component in the <ChangePackage> component.
      // When commenting out the <v-stepper> component, the page doesn't reload anymore. 🤷‍♂️
      this.$router.replace({ query: { showUpgrade: undefined } })
    },

    changeSubscription () {
      this.$tracking.event('Plan', 'Clicked', 'Change Subscription')

      if (this.isPlatformMarkero) {
        this.isMarkeroUpgradeDialogOpen = true
      } else {
        this.isUpgradeDialogOpen = true
      }
    }
  }

}
</script>
