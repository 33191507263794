<template>
  <v-slide-y-transition>
    <v-alert
      v-if="downgradeAt"
      icon="mdi-arrow-down-bold-circle-outline"
      outlined
      class="mt-8"
    >
      <span class="font-weight-bold black--text">{{ $t('settings.downgrade-info.title') }}<br></span>

      <div class="black--text">
        <p
          v-html="$t('settings.downgrade-info.warning', { productType: downgradeToProductType, date: formatDate(downgradeAt,'DATE_LONG') })"
        />
        <div class="w-full d-flex flex-column align-center">
          <ul>
            <li
              v-for="(feature,index) in lostFeatures.features"
              :key="index"
            >
              {{ $t(feature) }}
            </li>
          </ul>
          <v-alert
            v-if="lostFeatures.showLpAlert"
            type="warning"
            class="mt-4"
          >
            <b>{{ $t('labels.caution') }}: </b> {{ $t('settings.downgrade-info.info') }}
          </v-alert>
        </div>
      </div>

      <div class="justify-end d-flex mt-4">
        <v-btn
          color="primary"
          :loading="loading"
          :disabled="loading"
          @click="cancelDowngrade"
        >
          <v-icon class="mr-2">
            mdi-account-reactivate
          </v-icon>
          {{ $t('buttons.downgrade-info.cta') }}
        </v-btn>
      </div>
    </v-alert>
  </v-slide-y-transition>
</template>

<script>
import { products } from '@/lib/product'
import { ProductType, isBasicUser, isExpertUser } from '@/lib/productTypes'
import CANCEL_DOWNGRADE from './queries/CancelDowngrade.gql'
import bus, { eventNames } from '@/lib/eventBus'
import branding from '@/mixins/branding'

export default {
  mixins: [branding],
  props: {
    pendingDowngrade: {
      type: Object,
      default: () => {}
    },
    productType: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      products,
      loading: false
    }
  },
  computed: {
    isBasic () {
      return isBasicUser(this.$auth.user)
    },
    isExpert () {
      return isExpertUser(this.$auth.user)
    },
    downgradeAt () {
      return this.pendingDowngrade?.downgradeAt
    },
    downgradeToProductType () {
      return this.pendingDowngrade?.productType
    },
    lostFeatures () {
      const basicFeatures = Object.values(this.products[this.platform]).find(product => product.productName === ProductType.BASIC).features.slice(1)
      const expertFeatures = Object.values(this.products[this.platform]).find(product => product.productName === ProductType.EXPERT).features.slice(1)
      const downgradeFrom = this.productType
      const downgradeTo = this.downgradeToProductType

      return {
        [ProductType.EXPERT]: {
          [ProductType.BASIC]: {
            showLpAlert: true,
            features: expertFeatures
          },
          [ProductType.LIGHT]: {
            showLpAlert: true,
            features: [...basicFeatures, ...expertFeatures]
          }
        },
        [ProductType.BASIC]: {
          [ProductType.LIGHT]: {
            showLpAlert: true,
            features: basicFeatures
          }
        },
        [ProductType.ADVANCED]: {
          [ProductType.BEGINNER]: {
            features: [
              'settings.downgrade.features.0',
              'settings.downgrade.features.1',
              'settings.downgrade.features.2',
              'settings.downgrade.features.3'
            ]
          }
        }
      }[downgradeFrom][downgradeTo]
    }
  },
  methods: {
    async cancelDowngrade () {
      this.loading = true
      await this.$apollo.mutate({
        mutation: CANCEL_DOWNGRADE,
        variables: {
          companyId: this.$auth.user.companyId
        }
      })
      this.$emit('refetch')
      this.loading = false
      bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'success', text: this.$t('alerts.settings.downgrade-info.success') })
      this.$tracking.event('Plan', 'Clicked', 'Cancel Downgrade')
    }
  }
}
</script>

<style>

</style>
