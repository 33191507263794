<template>
  <v-container
    v-if="userWithNotificationSettings"
    class="pa-0 layout-container"
  >
    <ProfileSettings />
    <v-divider class="my-4" />

    <Feature :feature-slug="featureNames.I18N">
      <div>
        <LanguageSettings />
        <v-divider class="my-4" />
      </div>
    </Feature>

    <NotificationSettings
      :user="userWithNotificationSettings"
      :refetch-user="refetchUser"
      :company="company"
    />
  </v-container>
</template>

<script>
import USER_WITH_NOTIFICATION_SETTINGS from './queries/UserWithNotificationSettings.gql'
import ProfileSettings from './ProfileSettings.vue'
import NotificationSettings from './NotificationSettings.vue'
import LanguageSettings from './LanguageSettings.vue'
import COMPANY from '@/modules/company/queries/Company.gql'
import feature from '@/mixins/feature'

export default {
  components: {
    NotificationSettings,
    LanguageSettings,
    ProfileSettings
  },
  mixins: [feature],
  data () {
    return {
      userWithNotificationSettings: null
    }
  },
  methods: {
    async refetchUser () {
      const { data: { userWithNotificationSettings } } = await this.$apollo.query({
        query: USER_WITH_NOTIFICATION_SETTINGS,
        fetchPolicy: 'no-cache'
      })
      this.userWithNotificationSettings = userWithNotificationSettings
    }
  },
  apollo: {
    userWithNotificationSettings: {
      query: USER_WITH_NOTIFICATION_SETTINGS,
      fetchPolicy: 'no-cache'
    },
    company: {
      query: COMPANY,
      variables () {
        return {
          id: this.$auth.user.companyId
        }
      }
    }
  }
}
</script>
