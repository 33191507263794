const KindType = Object.freeze({
  facebookPost: 'facebookPost',
  instagramPost: 'instagramPost',
  linkedinPost: 'linkedinPost',
  facebookAds: 'facebookAds',
  gmbNewsPost: 'gmbNewsPost',
  gmbProductDescription: 'gmbProductDescription',
  customerMailing: 'customerMailing',
  blogPost: 'blogPost',
  websiteText: 'websiteText',
  interview: 'interview'
})

export const ReadableKindType = Object.freeze({
  facebookPost: 'Facebook Post',
  instagramPost: 'Instagram Post',
  linkedinPost: 'LinkedIn Post',
  facebookAds: 'Facebook Ads',
  gmbNewsPost: 'Google Unternehmensprofil Newspost',
  gmbProductDescription: 'Google Unternehmensprofil Produktbeschreibung',
  customerMailing: 'Kundenanschreiben',
  websiteText: 'Webseiten Text',
  interview: 'Interview'
})

export const KindTypeIcon = Object.freeze({
  facebookPost: '/img/kindTypes/facebook.png',
  instagramPost: '/img/kindTypes/instagram.png',
  linkedinPost: '/img/kindTypes/linkedin.png',
  facebookAds: '/img/kindTypes/facebook.png',
  gmbNewsPost: '/img/kindTypes/post.png',
  gmbProductDescription: '/img/kindTypes/product-description.png',
  customerMailing: '/img/kindTypes/email.png',
  blogPost: '/img/kindTypes/blog-post.png',
  websiteText: '/img/kindTypes/website-text.png',
  interview: '/img/kindTypes/interview.png',
  all: '/img/kindTypes/settings.png'
})

export const KindName = Object.freeze({
  facebookPost: 'Facebook',
  instagramPost: 'Instagram',
  linkedinPost: 'LinkedIn'
})

export const TypeToKind = Object.freeze({
  FACEBOOK: 'facebookPost',
  INSTAGRAM: 'instagramPost',
  LINKEDIN: 'linkedinPost'
})

export default KindType
