<template>
  <div>
    <p class="text-subtitle-1">
      {{ $t('settings.delete-account.downgrade.title') }}
    </p>
    <v-card

      class="d-flex elevation-0 blue lighten-4 flex-column flex-md-row"
    >
      <div class="justify-center d-flex">
        <v-img
          max-width="250"
          contain
          src="/img/switch-package-cutout.png"
        />
      </div>

      <div class="d-flex flex-column">
        <v-card-title class="d-flex">
          <h4 style="word-break: break-word">
            {{ $t('settings.delete-account.downgrade.subtitle') }}
          </h4>
        </v-card-title>

        <v-card-text class="mb-4 text-body-1">
          <span>
            {{ greetingText }},<br>
            {{ $t('settings.delete-account.downgrade.text') }}
          </span>
          <v-row class="justify-end mt-2 mr-4 d-flex">
            <v-btn
              class="primary"
              @click="handleDowngrade"
            >
              {{ $t('buttons.delete-account.downgrade') }}
            </v-btn>
          </v-row>
        </v-card-text>
      </div>
    </v-card>
  </div>
</template>

<script>

export default {
  computed: {
    greetingText () {
      return this.$auth.user.name || this.$t('settings.delete-account.downgrade.greeting')
    }
  },
  methods: {
    handleDowngrade () {
      this.$emit('handleDowngrade')
    }
  }
}
</script>
