<template>
  <div
    class="text-center"
    style="margin-top: 30%"
  >
    <div class="d-flex flex-column justify-center">
      <h1 class="justify-center mb-10">
        {{ $t('product-finder.success.title') }}
      </h1>
      <v-icon
        color="primary"
        size="200"
      >
        mdi-check-circle
      </v-icon>
      <p class="mt-10">
        {{ $t('product-finder.success.info', {seconds}) }}
      </p>
    </div>
  </div>
</template>
<script>

import { Routes } from '@/components/product-finder/routes'

export default {
  data () {
    return {
      seconds: 4 // Initial countdown value in seconds
    }
  },
  mounted () {
    this.$router.push({ path: `/signup-markero/${Routes.SUCCESS}` })
    this.startCountdown()
  },
  methods: {
    startCountdown () {
      const countdownInterval = setInterval(() => {
        if (this.seconds > 0) {
          this.seconds--
        } else {
          clearInterval(countdownInterval)
          this.$router.push({ name: 'dashboard' })
        }
      }, 1000)
    }
  }
}

</script>
