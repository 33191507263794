<template>
  <div
    v-if="hasSufficientContrast"
    class="indicator d-flex flex-column justify-center align-stretch"
    :style="{ backgroundColor: selectedColor, color: '#ffffff' }"
  >
    <div
      class="text-center"
      v-html="$t('alerts.contrast-indicator.success')"
    />
    <v-icon
      class="py-4"
      large
      color="white"
    >
      mdi-check
    </v-icon>
  </div>
  <div
    v-else
    class="indicator indicator__failure d-flex flex-column justify-center align-stretch"
  >
    <div
      class="text-center text-body-2"
      v-html="$t('alerts.contrast-indicator.error')"
    />
    <v-icon
      class="py-4"
      large
      color="red"
    >
      mdi-block-helper
    </v-icon>
    <div class="text-center text-body-2">
      {{ $t('alerts.contrast-indicator.info') }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    selectedColor: {
      type: String,
      default: '#000000'
    },
    hasSufficientContrast: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style>
.indicator {
  width: 192px;
  height: 192px;
  border-radius: 50%;
}
.indicator__failure {
  background-color: #d0648136 ;
  border: 2px solid red;
  color: red;
}
</style>
