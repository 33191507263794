<template>
  <div
    class="text-center"
    style="margin-top: 30%"
  >
    <h1 class="row justify-center mb-10">
      {{ $t('product-finder.generate.title') }}<br>
      {{ $t('product-finder.generate.subtitle') }}
    </h1>
    <div class="row justify-center">
      <v-progress-circular
        :rotate="360"
        :value="progress"
        :size="200"
        :width="15"
        color="primary"
      >
        <h2>
          {{ progress.toFixed(0) }} %
        </h2>
      </v-progress-circular>
    </div>
  </div>
</template>

<script>
import { Routes } from '@/components/product-finder/routes'

export default {
  props: {
    completed: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      progress: 0,
      duration: 60000,
      interval: null,
      startTime: null
    }
  },
  watch: {
    completed (newVal) {
      if (newVal) {
        this.completeProgressDelayed()
      }
    }
  },
  mounted () {
    this.$router.push({ path: `/signup-markero/${Routes.SCRAPE}` })
    this.startProgress()
  },
  beforeDestroy () {
    clearInterval(this.interval)
  },
  methods: {
    startProgress () {
      this.startTime = Date.now()
      this.interval = setInterval(this.updateProgress, 100) // Update every 100ms
    },
    updateProgress () {
      const elapsed = Date.now() - this.startTime
      const t = elapsed / this.duration
      this.progress = 100 * (1 - Math.exp(-5 * t)) // Exponential decay function

      if (this.progress >= 100) {
        this.progress = 100
        clearInterval(this.interval)
      }
    },
    completeProgressDelayed () {
      clearInterval(this.interval)
      const startProgress = this.progress
      const startTime = Date.now()
      const duration = 3000

      this.interval = setInterval(() => {
        const elapsed = Date.now() - startTime
        const t = elapsed / duration
        this.progress = startProgress + (100 - startProgress) * t

        if (this.progress >= 100) {
          this.progress = 100
          clearInterval(this.interval)
          this.$emit('next')
        }
      }, 100) // Update every 100ms
    }
  }
}
</script>
