<template>
  <SettingsCard
    :title="$t('settings.company.title')"
    :subtitle="$t('settings.company.subtitle')"
    icon="mdi-account-outline"
    :is-default-open="false"
  >
    <div class="pt-4 px-4">
      <v-row>
        <v-col
          cols="12"
          sm="6"
          class="px-0 px-md-2"
        >
          <div>
            <div class="d-flex align-center">
              <span class="font-weight-bold">
                {{ $t('settings.company.body-texts.0') }}
              </span>
            </div>
            <span class="grey--text">
              {{ $t('settings.company.body-texts.1') }}
            </span>
          </div>
        </v-col>
        <v-col
          cols="12"
          sm="6"
          class="d-flex justify-center px-0 px-md-2 mt-4"
        >
          <AddressData
            v-bind="companyAddressData"
            @saveSettings="saveSettings"
          />
        </v-col>
      </v-row>
      <Feature :feature-slug="featureNames.LANDINGPAGE">
        <v-row class="mt-8">
          <v-col
            cols="12"
            sm="6"
            class="px-0 px-md-2"
          >
            <div>
              <div class="d-flex align-center">
                <span class="font-weight-bold">
                  {{ $t('settings.company.body-texts.2') }}
                </span>
              </div>
              <span class="grey--text">
                {{ $t('settings.company.body-texts.3') }}
              </span>
            </div>
          </v-col>
          <v-col
            cols="12"
            sm="6"
            class="d-flex justify-center px-0 px-md-2 mt-4"
          >
            <AmbassadorData
              v-bind="ambassador"
              @saveSettings="saveAmbassadorSettings"
            />
          </v-col>
        </v-row>
      </Feature>
    </div>
  </SettingsCard>
</template>

<script>

import SettingsCard from '@/components/SettingsCard.vue'
import AddressData from '@/components/forms/AddressData'
import AmbassadorData from '@/components/forms/AmbassadorData'
import UPDATE_COMPANY_NAME from './queries/UpdateCompanyName.gql'
import UPDATE_COMPANY_ADDRESS from './queries/UpdateCompanyAddress.gql'
import UPDATE_COMPANY_AMBASSADOR_DATA from './queries/UpdateCompanyAmbassadorData.gql'
import PENDING_CHANGES_FOR_REGENERATION from '@/queries/PendingChangesForRegeneration.gql'
import bus, { eventNames } from '@/lib/eventBus'
import feature from '@/mixins/feature'

export default {
  components: {
    SettingsCard,
    AddressData,
    AmbassadorData
  },
  mixins: [feature],
  props: {
    company: {
      type: Object,
      default: () => ({})
    },
    settings: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    companyAddressData () {
      const { name, address } = this.company
      return { name, address }
    },
    ambassador () {
      const { ambassador } = this.company
      return { ambassador }
    }
  },
  methods: {
    async saveSettings (addressData) {
      const { __typename, name = '', ...address } = addressData
      try {
        await this.$apollo.mutate({
          mutation: UPDATE_COMPANY_NAME,
          variables: {
            input: {
              companyId: this.$auth.user.companyId,
              name
            }
          },
          refetchQueries: [{ query: PENDING_CHANGES_FOR_REGENERATION, variables: { companyId: this.company.id } }]
        })
        await this.$apollo.mutate({
          mutation: UPDATE_COMPANY_ADDRESS,
          variables: {
            input: {
              companyId: this.$auth.user.companyId,
              address
            }
          },
          refetchQueries: [{ query: PENDING_CHANGES_FOR_REGENERATION, variables: { companyId: this.company.id } }]
        })
        bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'success', text: this.$t('alerts.settings.company.success') })
      } catch (e) {
        bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'error', text: this.$t('alerts.settings.company.error') })
      }
    },
    async saveAmbassadorSettings ({ name, email, phone }) {
      try {
        await this.$apollo.mutate({
          mutation: UPDATE_COMPANY_AMBASSADOR_DATA,
          variables: {
            input: {
              companyId: this.$auth.user.companyId,
              ambassador: { name, email, phone }
            }
          },
          refetchQueries: [{ query: PENDING_CHANGES_FOR_REGENERATION, variables: { companyId: this.company.id } }]
        })
        bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'success', text: this.$t('alerts.settings.company.success') })
      } catch (e) {
        bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'error', text: this.$t('alerts.settings.company.error') })
      }
    }
  }
}
</script>
