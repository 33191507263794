<template>
  <SettingsCard
    :title="$t('settings.features.postal-shipping.title')"
    :subtitle="$t('settings.features.postal-shipping.subtitle')"
    icon="mdi-email-fast-outline"
    :is-default-open="isDefaultOpen"
  >
    <div class="pa-4">
      <v-row>
        <v-col
          cols="12"
          sm="6"
          class="px-0 px-md-2"
        >
          <div>
            <span class="font-weight-bold">
              {{ $t('settings.features.postal-shipping.body-texts.0') }}<br>
            </span>
            <span class="grey--text">
              {{ $t('settings.features.postal-shipping.body-texts.1') }}
            </span>
          </div>
        </v-col>
        <v-col
          cols="12"
          sm="6"
          class="px-0 px-md-2"
        >
          <v-radio-group
            v-model="type"
            :label="$t('settings.features.postal-shipping.labels.mode')"
          >
            <v-radio
              v-for="{ label, value } in shippingOptions"
              :key="value"
              :label="label"
              :value="value"
            />
          </v-radio-group>

          <div
            v-if="loading"
            class="mt-2 d-flex justify-center"
          >
            <v-progress-circular
              indeterminate
              color="primary"
            />
          </div>

          <div
            v-if="setToTypeNone && !loading"
            class="d-flex"
          >
            <v-icon
              color="primary"
            >
              mdi-information
            </v-icon>
            <div
              class="ml-2"
              v-html="$t('settings.features.postal-shipping.ready-text')"
            />
          </div>
        </v-col>
      </v-row>

      <v-row v-if="type === 'MANUAL' && !loading">
        <v-col
          cols="12"
          sm="6"
          class="px-0 px-md-2"
        >
          <div>
            <span class="font-weight-bold">
              {{ $t('settings.features.postal-shipping.body-texts.2') }}<br>
            </span>
            <span class="grey--text">
              {{ $t('settings.features.postal-shipping.body-texts.3') }}
            </span>
          </div>
        </v-col>
        <v-col
          cols="12"
          sm="6"
          class="px-0 px-md-2"
        >
          <div>
            <v-radio-group
              v-model="allGuides"
              hide-details
              :label="$t('settings.features.postal-shipping.labels.guide')"
            >
              <v-radio
                :label="$t('settings.features.postal-shipping.labels.option-all')"
                :value="true"
              />
              <v-radio
                :label="$t('settings.features.postal-shipping.labels.option-select')"
                :value="false"
              />
            </v-radio-group>

            <v-expand-transition>
              <div
                v-if="!allGuides"
                class="pt-2"
              >
                <LabelSwitch
                  v-for="({name, id, slug}) in guides"
                  :key="id"
                  :value="landingpageValues[slug]"
                  class="ma-2 switch"
                  min-height="100px"
                  :label="name"
                  @input="updatePostalShippingSetting($event,slug)"
                />
              </div>
            </v-expand-transition>
          </div>
        </v-col>
      </v-row>

      <v-alert
        icon="mdi-lightbulb-on-20"
        prominent
        text
        type="info"
        class="ma-0 mt-4"
      >
        <p class="ma-0 font-weight-bold">
          {{ $t('labels.hint') }}:
        </p>
        <span class="pb-2 pt-4">{{ $t('settings.features.postal-shipping.info-texts.0') }}: </span>
        <p>
          {{ $t('settings.features.postal-shipping.info-texts.1') }}
        </p>
        <p>
          {{ $t('settings.features.postal-shipping.info-texts.2') }}
        </p>
      </v-alert>
    </div>
  </SettingsCard>
</template>

<script>
import SettingsCard from '@/components/SettingsCard.vue'
import UPDATE_POSTAL_SHIPPING from './queries/UpdatePostalShipping.gql'
import LabelSwitch from '@/components/LabelSwitch.vue'
import bus, { eventNames } from '@/lib/eventBus'

export default {
  components: { SettingsCard, LabelSwitch },
  props: {
    settings: {
      type: Object,
      default: () => ({})
    },
    guides: {
      type: Array,
      default: () => ([])
    },
    refetchSettings: {
      type: Function,
      default: () => {}
    },
    isDefaultOpen: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      type: this.settings.lead.postalShipping.type,
      loading: false,
      setToTypeNone: false,
      allGuides: this.settings.lead.postalShipping.allGuides,
      shippingOptions: [
        { label: this.$t('settings.features.postal-shipping.labels.option-online'), value: 'NONE' },
        { label: this.$t('settings.features.postal-shipping.labels.option-manual'), value: 'MANUAL' }
      ],
      landingpageValues: this.guides
        .reduce((obj, { slug }) => ({ ...obj, [slug]: this.settings.lead.postalShipping.activeLandingpages.includes(slug) }), {})
    }
  },
  watch: {
    async type (value) {
      this.setToTypeNone = value === 'NONE'
      this.loading = true
      await this.saveSettings()
      this.finishLoading(700)
    },
    async allGuides (value) {
      if (!value && Object.entries(this.landingpageValues).filter(([_, value]) => value).length === 0) {
        this.landingpageValues = this.guides.reduce((obj, { slug }) => ({ ...obj, [slug]: true }), {})
      }
      await this.saveSettings()
    }
  },
  methods: {
    updatePostalShippingSetting (value, slug) {
      this.landingpageValues[slug] = value
      this.saveSettings()
    },
    finishLoading (milliseconds) {
      setTimeout(() => { this.loading = false }, milliseconds)
    },
    async saveSettings () {
      try {
        await this.$apollo.mutate({
          mutation: UPDATE_POSTAL_SHIPPING,
          variables: {
            input: {
              companyId: this.$auth.user.companyId,
              type: this.type,
              allGuides: this.allGuides,
              activeLandingpages: Object.entries(this.landingpageValues).filter(([_, value]) => value).map(([slug]) => slug)
            }
          }
        })
        this.refetchSettings()
      } catch (e) {
        this.loading = false
        bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'error', text: this.$t('alerts.settings.features.error') })
      }
    }
  }
}
</script>
<style scoped>
.switch {
  min-height: 40px;
}
</style>
