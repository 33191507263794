<template>
  <div>
    <p class="text-subtitle-1">
      {{ $t('settings.delete-account.information.title', { date: formatDate(nextPossibleTerminationDate,'DATE_LONG') }) }}:
    </p>
    <ul class="text-subtitle-1">
      <li>{{ $t('settings.delete-account.information.list.0') }}</li>
      <li>{{ $t('settings.delete-account.information.list.1') }}</li>
      <li>{{ $t('settings.delete-account.information.list.1', { readablePlatformName }) }}</li>
      <li>{{ $t('settings.delete-account.information.list.3') }}</li>
    </ul>

    <v-alert
      type="info"
      class="mt-8"
    >
      <p class="font-weight-bold">
        {{ $t('settings.delete-account.information.subtitle') }}
      </p>
      <p>
        {{ $t('settings.delete-account.information.info', { readablePlatformName }) }}
      </p>
    </v-alert>
  </div>
</template>

<script>
import brandingMixin from '@/mixins/branding'
export default {
  mixins: [brandingMixin],
  props: {
    nextPossibleTerminationDate: {
      type: Date,
      default: ''
    }
  }
}
</script>
