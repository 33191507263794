<template>
  <SettingsCard
    :title="$t('settings.payment.billing-data.title')"
    :subtitle="$t('settings.payment.billing-data.subtitle')"
    icon="mdi-account-cash"
    :is-default-open="false"
  >
    <div class="pa-4">
      <v-row>
        <v-col
          cols="12"
          sm="6"
          class="px-0 px-md-2"
        >
          <div>
            <span class="font-weight-bold">
              {{ $t('settings.payment.billing-data.body-texts.0') }}<br>
            </span>
            <span class="grey--text">
              {{ $t('settings.payment.billing-data.body-texts.1') }}<br>
              {{ $t('settings.payment.billing-data.body-texts.2') }}
            </span>
          </div>
        </v-col>
        <v-col
          cols="12"
          sm="6"
          class="px-0 px-md-2"
        >
          <div
            v-if="customer"
            class="justify-center d-flex mb-sm-0"
          >
            <CustomerForm
              v-model="customer"
              class="w-full"
              submit-button-text="Aktualisieren"
              :is-submitting="false"
              :show-coupon-field="false"
              show-email
              show-country
              show-coupon
              disable-country-selection
              show-address-line1
              immediate-submit
              validate-on-input
              @submit="saveBillingData"
            />
          </div>
        </v-col>
      </v-row>
    </div>
  </SettingsCard>
</template>

<script>
import bus, { eventNames } from '@/lib/eventBus'
import SettingsCard from '@/components/SettingsCard.vue'
import brandingMixin from '@/mixins/branding'
import featureMixin from '@/mixins/feature'
import CustomerForm from '@/modules/billwerk/subscribe/CustomerForm.vue'
import { billwerkApi } from '@/modules/billwerk/lib'

export default {
  components: { SettingsCard, CustomerForm },
  mixins: [brandingMixin, featureMixin],
  props: {
    token: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      portal: null,
      customer: {
        emailAddress: this.$auth.user.email,
        firstName: this.$auth.user.given_name,
        lastName: this.$auth.user.family_name,
        companyName: '',
        vatId: '',
        couponCode: '',
        paymentMethod: '',
        address: {
          street: '',
          houseNumber: '',
          postalCode: '',
          city: '',
          country: ''
        }
      },
      originalCustomer: {
        emailAddress: this.$auth.user.email,
        firstName: this.$auth.user.given_name,
        lastName: this.$auth.user.family_name,
        companyName: '',
        vatId: '',
        couponCode: '',
        paymentMethod: '',
        address: {
          addressLine1: '',
          street: '',
          houseNumber: '',
          postalCode: '',
          city: '',
          country: ''
        }
      }
    }
  },

  computed: {
    isDirty () {
      const dirty = Object.keys(this.originalCustomer).some(key => {
        if (typeof this.originalCustomer[key] === 'object') {
          // compare children
          const childDirty = Object.keys(this.originalCustomer[key]).some(childKey => {
            return this.originalCustomer[key][childKey] !== this.customer[key][childKey]
          })
          return childDirty
        } else {
          return this.originalCustomer[key] !== this.customer[key]
        }
      })
      return dirty
    }
  },

  watch: {
    token (token) {
      if (token) {
        this.getContractDetails()
      }
    }
  },

  methods: {
    async getContractDetails () {
      try {
        const {
          Customer: {
            CompanyName, FirstName, LastName, VatId, EmailAddress,
            Address: { AddressLine1, Street, HouseNumber, PostalCode, City, Country } = {}
          }
        } = await billwerkApi.getContractDetails(this.token)

        this.originalCustomer = {
          emailAddress: EmailAddress || this.customer.emailAddress,
          companyName: CompanyName || this.customer.companyName,
          firstName: FirstName || this.customer.firstName,
          lastName: LastName || this.customer.lastName,
          vatId: VatId || this.customer.vatId,
          address: {
            addressLine1: AddressLine1 || this.customer.address.addressLine1,
            street: Street || this.customer.address.street,
            houseNumber: HouseNumber || this.customer.address.houseNumber,
            postalCode: PostalCode || this.customer.address.postalCode,
            city: City || this.customer.address.city,
            country: Country?.toUpperCase() || this.customer.address.country
          }
        }
        this.customer = { ...this.originalCustomer, address: { ...this.originalCustomer.address } }
      } catch (error) {
        bus.$emit(
          eventNames.SHOW_SNACKBAR,
          { color: 'error', text: this.$t('alerts.settings.billing-data.error-loading') }
        )
      }
    },

    async saveBillingData () {
      if (!this.isDirty) return
      try {
        await billwerkApi.customerChange({
          token: this.token,
          customer: {
            emailAddress: this.customer.emailAddress,
            firstName: this.customer.firstName,
            lastName: this.customer.lastName,
            vatId: this.customer.vatId,
            companyName: this.customer.companyName,
            address: {
              addressLine1: this.customer.address.addressLine1,
              street: this.customer.address.street,
              houseNumber: this.customer.address.houseNumber,
              postalCode: this.customer.address.postalCode,
              city: this.customer.address.city,
              country: this.customer.address.country.toUpperCase()
            }
          }
        })
      } catch {
        bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'error', text: this.$t('alerts.settings.billing-data.error') })
      }
      this.getContractDetails()
      bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'success', text: this.$t('alerts.settings.billing-data.success') }
      )
    }
  }
}
</script>

<style scoped>

.v-input--selection-controls {
  margin-top: 0;
  max-height: 30px;
}
</style>
