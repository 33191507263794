<template>
  <v-container
    v-if="settings && guides"
    class="layout-container pa-0"
  >
    <Feature
      v-slot="{feature}"
      :feature-slug="featureNames.COMPANY"
    >
      <div v-if="feature.config.isPhoneVerificationEditable">
        <OptionalPhoneSettings
          :settings="settings"
          :refetch-settings="refetchCompanySettings"
        />
        <v-divider class="my-4" />
      </div>
    </Feature>

    <ValuationSettings
      v-if="showValuationSettings"
      :settings="settings"
      :refetch-settings="refetchCompanySettings"
    />
    <v-divider
      v-if="showValuationSettings"
      class="my-4"
    />

    <Feature :feature-slug="featureNames.RETURNCALL_TOPICS">
      <div>
        <ReturnCallSettings />
        <v-divider class="my-4" />
      </div>
    </Feature>

    <div v-if="activeGuides.length > 0">
      <PostalShippingSettings
        :settings="settings"
        :guides="activeGuides"
        :refetch-settings="refetchCompanySettings"
        :is-default-open="!showValuationSettings && !showNavigationSettings"
      />
      <v-divider class="my-4" />
    </div>

    <div v-if="showNavigationSettings">
      <NavigationSettings
        :settings="settings"
        :tools="tools"
        :guides="guides"
        :checklists="checklists"
      />
      <v-divider class="my-4" />
    </div>

    <Feature :feature-slug="featureNames.LANDINGPAGE">
      <div>
        <PublicContactSettings
          v-if="company"
          :company="company"
          :refetch-company="refetchCompany"
        />
        <v-divider class="my-4" />
      </div>
    </Feature>

    <Feature :feature-slug="featureNames.CONTENT_CREATOR">
      <SocialMediaSettings />
    </Feature>
  </v-container>
</template>

<script>
import ValuationSettings from './ValuationSettings.vue'
import NavigationSettings from './NavigationSettings.vue'
import SETTINGS from './queries/Settings.gql'
import COMPANY from './queries/Company.gql'
import LANDINGPAGES_TEMPLATES from '@/queries/LandingpageTemplatesForCompany.gql'
import PostalShippingSettings from './PostalShippingSettings.vue'
import PublicContactSettings from './PublicContactSettings.vue'
import featureMixin from '@/mixins/feature'
import featureNames from '@/lib/featureNames'
import { TOOLS } from '@/lib/tools'
import OptionalPhoneSettings from './OptionalPhoneSettings.vue'
import ReturnCallSettings from './ReturnCallSettings.vue'
import SocialMediaSettings from './SocialMediaSettings.vue'

export default {
  components: {
    ValuationSettings,
    PostalShippingSettings,
    PublicContactSettings,
    NavigationSettings,
    OptionalPhoneSettings,
    ReturnCallSettings,
    SocialMediaSettings
  },
  mixins: [featureMixin],
  computed: {
    showValuationSettings () {
      const {
        isActive: isValuationActive,
        config: { isPhoneNumberVerificationEnabled, canChangePhoneNumberVerification } = {}
      } = this.getFeature(featureNames.VALUATION)
      return this.isFeatureActive(featureNames.PLOT_VALUATION) || (isValuationActive && isPhoneNumberVerificationEnabled && canChangePhoneNumberVerification)
    },

    showNavigationSettings () {
      return this.isFeatureActive(featureNames.LANDINGPAGE_NAVIGATION)
    },

    landingpages () {
      if (!this.company || !this.landingpageTemplates) return []
      const mappedLps = this.landingpageTemplates.map(lp => ({
        ...lp,
        disabled: true
      }))
      return [...this.company.landingpages, ...mappedLps].sort((a, b) => { return a.slug > b.slug ? 1 : -1 })
    },
    tools () {
      return this.landingpages
        .map(lp => ({ ...lp, ...TOOLS.find(tool => tool.type === lp.type) }))
        .filter(lp => lp.order)
        .sort((a, b) => a.order - b.order)
    },
    guides () {
      return this.landingpages.filter(({ type }) => type === 'GUIDE')
    },
    checklists () {
      return this.landingpages.filter(({ type }) => type === 'CHECKLIST')
    },
    activeGuides () {
      return this.guides.filter(({ status }) => status === 'ACTIVE')
    }
  },
  created () {
    this.featureNames = featureNames
  },
  methods: {
    refetchCompanySettings () {
      return this.$apollo.queries.settings.refetch()
    },
    refetchCompany () {
      return this.$apollo.queries.company.refetch()
    }
  },
  apollo: {
    settings: {
      query: SETTINGS,
      variables () {
        return {
          companyId: this.$auth.user.companyId
        }
      }
    },
    company: {
      query: COMPANY,
      variables () {
        return { id: this.$auth.user.companyId }
      }
    },
    landingpageTemplates: {
      query: LANDINGPAGES_TEMPLATES,
      variables () {
        return {
          input: {
            companyId: this.$auth.user.companyId,
            onlyDefault: true
          }
        }
      }
    }
  }
}
</script>
