<template>
  <div class="d-flex justify-center flex-column align-center w-full">
    <div
      class="d-flex flex-column align-center w-full mt-16"
      style="height: 120px"
    >
      <PackageLogo
        v-if="!showNewLogo"
        :current-package="oldPackage"
        :show-label="false"
        class="bounce-out"
        size="large"
      />

      <PackageLogo
        v-if="showNewLogo"
        :current-package="newPackage"
        :show-label="false"
        class="bounce-in"
        :class="{'pulse': showPulse}"
        size="large"
      />
    </div>
    <h2>{{ title }}</h2>
    <p>{{ subtitle }} </p>
  </div>
</template>

<script>
import PackageLogo from '@/components/PackageLogo.vue'
import { isUpgrade } from '@/lib/product'

export default {
  components: {
    PackageLogo
  },
  props: {
    currentPackage: {
      type: String,
      default: ''
    },
    newPackage: {
      type: String,
      default: ''
    },
    isChangeSubscriptionDone: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      oldPackage: this.currentPackage,
      product: {},
      showNewLogo: false,
      showPulse: false,
      animationFinished: false,
      initalTimeout: null,
      newLogoTimeout: null,
      finishedTimeout: null
    }
  },
  computed: {
    title () {
      return isUpgrade(this.oldPackage, this.newPackage)
        ? this.$t('settings.change-animation.titles.0')
        : this.$t('settings.change-animation.titles.1')
    },
    subtitle () {
      return isUpgrade(this.oldPackage, this.newPackage)
        ? this.$t('settings.change-animation.subtitles.0', { package: this.newPackage })
        : this.$t('settings.change-animation.subtitles.1', { package: this.newPackage })
    }
  },
  watch: {
    isChangeSubscriptionDone (value) {
      if (value) this.handleFinished()
    },
    animationFinished (value) {
      if (value) this.handleFinished()
    }
  },
  mounted () {
    this.initalTimeout = setTimeout(() => {
      this.showNewLogo = true
      this.newLogoTimeout = setTimeout(() => {
        this.showPulse = true
        this.finishedTimeout = setTimeout(() => {
          this.animationFinished = true
        }, 5000)
      }, 900)
    }, 1900)
  },
  methods: {
    handleFinished () {
      if (this.isChangeSubscriptionDone && this.animationFinished) {
        this.$emit('finished')
      }
    }
  }

}
</script>

<style>
.bounce-out {
  animation: bounce-out 2s ease ;
}
@keyframes bounce-out {
  0% { transform: scale(1); }
  25% { transform: scale(.95); }
  50% {
    opacity: 1;
    transform: scale(1.1);
  }
  100% {
    opacity: 0;
    transform: scale(.3);
  }
}

.bounce-in {
  animation: bounce-in 1s ease ;
}
@keyframes bounce-in {
  0% {
    opacity: 0;
    transform: scale(.3);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.pulse {
  animation: pulse 2s infinite ease-in-out alternate;
}
@keyframes pulse {
  0% { transform: scale(1); }
  50% { transform: scale(.8); }
  100% {    transform: scale(1.2);}
}
</style>
