<template>
  <v-alert
    :type="type"
    :icon="icon"
    outlined
    class="mt-8"
  >
    <span class="font-weight-bold black--text">{{ title }}<br></span>

    <div class="black--text">
      <slot />
    </div>

    <div class="d-flex justify-end">
      <v-btn
        :color="type"
        text
        :loading="loading"
        :disabled="loading"
        @click="action"
      >
        <v-icon
          v-if="icon"
          class="mr-2"
        >
          {{ icon }}
        </v-icon>
        {{ title }}
      </v-btn>
    </div>
  </v-alert>
</template>

<script>

export default {
  props: {
    action: {
      type: Function,
      required: true
    },
    title: {
      type: String,
      default: null
    },
    icon: {
      type: String,
      required: true
    },
    type: {
      type: String,
      default: null
    },
    loading: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style>

</style>
