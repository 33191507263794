<template>
  <v-alert
    icon="mdi-lightbulb-on-20"
    prominent
    text
    type="info"
    class="my-6 pl-4"
    :class="{'mx-0': $vuetify.breakpoint.smAndDown}"
  >
    <p class="ma-0 font-weight-bold">
      {{ $t('labels.hint') }}:
    </p>
    <span>{{ alertText || $t('alerts.change-settings') }}</span>
  </v-alert>
</template>

<script>
export default {
  props: {
    alertText: {
      type: String,
      default: ''
    }
  }
}
</script>
