<template>
  <SettingsCard
    :title="$t('settings.email.signature-settings.title')"
    :subtitle="$t('settings.email.signature-settings.subtitle')"
    icon="mdi-chat"
    :is-default-open="false"
  >
    <v-row
      v-if="defaultSignatureAvailable"
      class="ma-2"
    >
      <v-col
        cols="12"
        md="6"
      >
        <div class="font-weight-bold">
          {{ $t('settings.email.signature-settings.body-texts.0') }}
        </div>
        <div class="grey--text">
          {{ $t('settings.email.signature-settings.body-texts.1') }}
          <br>
        </div>
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <v-switch
          v-model="company.ambassador.signature.customTemplateSignature"
          @change="updateCustomTemplateSignature"
        />
      </v-col>
    </v-row>
    <v-row
      v-if="!company.ambassador.signature.customTemplateSignature"
      class="ma-2"
    >
      <v-col
        cols="12"
        md="6"
      >
        <div class="font-weight-bold">
          {{ $t('settings.email.signature-settings.body-texts.2') }}
        </div>
        <div class="grey--text">
          {{ $t('settings.email.signature-settings.body-texts.3') }}
          <br>
          <br>
          {{ $t('settings.email.signature-settings.body-texts.4') }}
        </div>
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <signature-preview
          :company="company"
          type="html"
          :custom-signature="company.ambassador.signature.customTemplateSignature ? company.ambassador.signature.html : ''"
        />
      </v-col>
    </v-row>
    <v-row
      v-if="!company.ambassador.signature.customTemplateSignature"
      class="ma-2"
    >
      <v-col
        cols="12"
        md="6"
      >
        <div class="font-weight-bold">
          {{ $t('settings.email.signature-settings.body-texts.5') }}
        </div>
        <div class="grey--text">
          {{ $t('settings.email.signature-settings.body-texts.6') }}
        </div>
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <v-textarea
          :value="company.ambassador.signature.extra"
          class="mb-4"
          hide-details
          outlined
          no-resize
          @change="updateSignatureExtra"
        />

        <div class="w-50 d-flex justify-end">
          <v-btn
            outlined
            :disabled="!hasSignatureExtra()"
            @click="updateSignatureExtra('')"
          >
            {{ $t('labels.reset') }}
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <v-row
      v-if="company.ambassador.signature.customTemplateSignature"
      class="ma-2"
    >
      <v-col
        cols="12"
        md="6"
      >
        <div class="font-weight-bold">
          {{ $t('settings.email.signature-settings.body-texts.7') }}
        </div>
        <div class="grey--text">
          {{ $t('settings.email.signature-settings.body-texts.8') }}
        </div>
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <v-textarea
          :value="company.ambassador.signature.html"
          class="mb-4"
          hide-details
          outlined
          no-resize
          @change="updateSignatureHTML"
        />
      </v-col>
    </v-row>
    <v-row
      v-if="company.ambassador.signature.customTemplateSignature"
      class="ma-2"
    >
      <v-col
        cols="12"
        md="6"
      >
        <div class="font-weight-bold">
          {{ $t('settings.email.signature-settings.body-texts.9') }}
        </div>
        <div class="grey--text">
          {{ $t('settings.email.signature-settings.body-texts.10') }}
        </div>
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <signature-preview
          :company="company"
          type="html"
          :custom-signature="company.ambassador.signature.customTemplateSignature ? company.ambassador.signature.html : ''"
        />
      </v-col>
    </v-row>
    <v-row
      v-if="company.ambassador.signature.customTemplateSignature"
      class="ma-2"
    >
      <v-col
        cols="12"
        md="6"
      >
        <div class="font-weight-bold">
          {{ $t('settings.email.signature-settings.body-texts.11') }}
        </div>
        <div class="grey--text">
          {{ $t('settings.email.signature-settings.body-texts.12') }}
        </div>
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <v-textarea
          :value="company.ambassador.signature.text"
          class="mb-4"
          hide-details
          outlined
          no-resize
          @change="updateSignatureText"
        />
      </v-col>
    </v-row>
    <v-row
      v-if="company.ambassador.signature.customTemplateSignature"
      class="ma-2"
    >
      <v-col
        cols="12"
        md="6"
      >
        <div class="font-weight-bold">
          {{ $t('settings.email.signature-settings.body-texts.13') }}
        </div>
        <div class="grey--text">
          {{ $t('settings.email.signature-settings.body-texts.14') }}
        </div>
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <signature-preview
          :company="company"
          type="text"
          :custom-signature="company.ambassador.signature.customTemplateSignature ? company.ambassador.signature.text : ''"
        />
      </v-col>
    </v-row>
  </SettingsCard>
</template>

<script>
import UPDATE_COMPANY_AMBASSADOR_SIGNATURE_EXTRA from './queries/UpdateCompanyAmbassadorSignatureExtra.gql'
import UPDATE_COMPANY_AMBASSADOR_SIGNATURE_TEXT from './queries/UpdateCompanyAmbassadorSignatureText.gql'
import UPDATE_COMPANY_AMBASSADOR_SIGNATURE_HTML from './queries/UpdateCompanyAmbassadorSignatureHTML.gql'
import UPDATE_COMPANY_AMBASSADOR_SIGNATURE_CUSTOM_TEMPLATE_SIGNATURE from './queries/UpdateCompanyAmbassadorSignatureCustomTemplateSignature.gql'
import SettingsCard from '@/components/SettingsCard.vue'
import featureMixin from '@/mixins/feature'
import eventBus, { eventNames } from '@/lib/eventBus'
import SignaturePreview from '@/components/SignaturePreview.vue'

export default {
  components: { SettingsCard, SignaturePreview },
  mixins: [featureMixin],
  props: {
    company: {
      type: Object,
      default: () => ({})
    },
    refetchCompany: {
      type: Function,
      default: () => {}
    }
  },
  data () {
    return {
      isLoading: {
        updateSignatureExtra: false,
        updateSignatureText: false,
        updateSignatureHTML: false,
        updateCustomTemplateSignature: false
      }
    }
  },
  computed: {
    defaultSignatureAvailable () {
      return this.company.mailProvider === 'HOPPERMATION'
    }
  },
  methods: {
    hasSignatureExtra () {
      return this.company.ambassador.signature.extra !== ''
    },
    async updateSignatureExtra (extra) {
      this.isLoading.updateSignatureExtra = true
      try {
        await this.$apollo.mutate({
          mutation: UPDATE_COMPANY_AMBASSADOR_SIGNATURE_EXTRA,
          variables: {
            input: {
              companyId: this.$auth.user.companyId,
              extra
            }
          }
        })
        this.$tracking.event('Settings', 'Clicked', 'Update Company Ambassador signature EXTRA')
        eventBus.$emit(eventNames.SHOW_SNACKBAR, { color: 'success', text: this.$t('alerts.settings.email.signature-settings.success') })
        this.refetchCompany()
      } catch (err) {
        eventBus.$emit(eventNames.SHOW_SNACKBAR, { color: 'error', text: this.$t('alerts.settings.email.signature-settings.error') })
      } finally {
        this.isLoading.updateSignatureExtra = false
      }
    },

    async updateSignatureText (text) {
      this.isLoading.updateSignatureText = true
      try {
        await this.$apollo.mutate({
          mutation: UPDATE_COMPANY_AMBASSADOR_SIGNATURE_TEXT,
          variables: {
            input: {
              companyId: this.$auth.user.companyId,
              text
            }
          }
        })
        this.$tracking.event('Settings', 'Clicked', 'Update Company Ambassador signature TEXT')
        eventBus.$emit(eventNames.SHOW_SNACKBAR, { color: 'success', text: this.$t('alerts.settings.email.signature-settings.success') })
        this.refetchCompany()
      } catch (err) {
        eventBus.$emit(eventNames.SHOW_SNACKBAR, { color: 'error', text: this.$t('alerts.settings.email.signature-settings.error') })
      } finally {
        this.isLoading.updateSignatureText = false
      }
    },

    async updateSignatureHTML (html) {
      this.isLoading.updateSignatureHTML = true
      try {
        await this.$apollo.mutate({
          mutation: UPDATE_COMPANY_AMBASSADOR_SIGNATURE_HTML,
          variables: {
            input: {
              companyId: this.$auth.user.companyId,
              html
            }
          }
        })
        this.$tracking.event('Settings', 'Clicked', 'Update Company Ambassador signature HTML')
        eventBus.$emit(eventNames.SHOW_SNACKBAR, { color: 'success', text: this.$t('alerts.settings.email.signature-settings.success') })
        this.refetchCompany()
      } catch (err) {
        eventBus.$emit(eventNames.SHOW_SNACKBAR, { color: 'error', text: this.$t('alerts.settings.email.signature-settings.error') })
      } finally {
        this.isLoading.updateSignatureHTML = false
      }
    },
    async updateCustomTemplateSignature (customTemplateSignature) {
      this.isLoading.updateCustomTemplateSignature = true
      try {
        await this.$apollo.mutate({
          mutation: UPDATE_COMPANY_AMBASSADOR_SIGNATURE_CUSTOM_TEMPLATE_SIGNATURE,
          variables: {
            input: {
              companyId: this.$auth.user.companyId,
              customTemplateSignature
            }
          }
        })
        this.$tracking.event(
          'Settings',
          'Clicked',
          'Update Company Ambassador signature, custom template signature')
        eventBus.$emit(eventNames.SHOW_SNACKBAR, { color: 'success', text: this.$t('alerts.settings.email.signature-settings.success') })
        this.refetchCompany()
      } catch (err) {
        eventBus.$emit(eventNames.SHOW_SNACKBAR, { color: 'error', text: this.$t('alerts.settings.email.signature-settings.error') })
      } finally {
        this.isLoading.updateCustomTemplateSignature = false
      }
    }
  }
}
</script>
