<template>
  <div
    class="d-flex justify-center flex-column align-center w-full"
  >
    <div
      class="d-flex flex-column align-center w-full mt-16"
      style="height: 120px"
    >
      <PackageLogo
        v-if="!showDoneLogo"
        :current-package="newPackage"
        :show-label="false"
        class="bounce-out-done"
        size="large"
      />

      <div
        v-if="showDoneLogo"
        class="d-flex bounce-in-done"
        :class="{'pulse-done': showPulse}"
      >
        <div
          style="position:relative"
          class="mr-8"
        >
          <v-icon
            class="mr-2 static-gradient-background background-clip-icon"
            style="position:absolute"
            :style="{left: '-25px', top: '-18px' }"
            :size="100"
          >
            mdi-check-decagram
          </v-icon>
        </div>
      </div>
    </div>
    <h2>{{ title }} </h2>
    <p class="text-center">
      {{ subtitle }}
    </p>
  </div>
</template>

<script>
import PackageLogo from '@/components/PackageLogo.vue'

export default {
  components: {
    PackageLogo
  },
  props: {
    isUpgrade: {
      type: Boolean,
      default: true
    },
    newPackage: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      product: {},
      showDoneLogo: false,
      showPulse: false
    }
  },
  computed: {
    title () {
      return this.isUpgrade
        ? this.$t('settings.finished-animation.titles.0')
        : this.$t('settings.finished-animation.titles.1')
    },
    subtitle () {
      return this.isUpgrade
        ? this.$t('settings.finished-animation.subtitles.0', { package: this.newPackage })
        : this.$t('settings.finished-animation.subtitles.1', { package: this.newPackage })
    }
  },
  mounted () {
    setTimeout(() => {
      this.showDoneLogo = true
      setTimeout(() => {
        this.showPulse = true
        setTimeout(() => {
          this.$emit('finished')
        }, 5000)
      }, 900)
    }, 1900)
  }

}
</script>

<style>
.bounce-out-done {
  animation: bounce-out-done 2s ease ;
}
@keyframes bounce-out-done {
  0% { transform: scale(1); }
  25% { transform: scale(.95); }
  50% {
    opacity: 1;
    transform: scale(1.1);
  }
  100% {
    opacity: 0;
    transform: scale(.3);
  }
}

.bounce-in-done {
  animation: bounce-in-done 1s ease ;
}
@keyframes bounce-in-done {
  0% {
    opacity: 0;
    transform: scale(.3);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.pulse-done {
  animation: pulse-done 2s infinite ease-in-out alternate;
}
@keyframes pulse-done {
  0% { transform: scale(1); }
  50% { transform: scale(.8); }
  100% {    transform: scale(1.2);}
}
</style>
