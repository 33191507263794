<template>
  <div>
    <ChangePackage
      :show-dialog.sync="dialog"
      :company="company"
      @downgrade="fetchPendingDowngrade"
    />

    <v-alert
      type="info"
      icon="mdi-swap-horizontal"
      color="primary"
      outlined
      class="mt-8"
    >
      <span class="font-weight-bold black--text">
        {{ $t('settings.delete-account.step-downgrade.title') }}
        <br></span>

      <div class="black--text">
        {{ $t('settings.delete-account.step-downgrade.body-texts.0', { readablePlatformName }) }} <br>
        {{ $t('settings.delete-account.step-downgrade.body-texts.1') }}<br>
        <span
          :class="isPlatformMarkero ? 'd-block mb-1 font-weight-bold' : ''"
        >
          {{ $t('settings.delete-account.step-downgrade.body-texts.2') }}</span><br>
        {{ $t('settings.delete-account.step-downgrade.body-texts.3') }}

        <ul
          v-if="isPlatformMarkero"
          class="no-bullets"
        >
          <li>{{ $t('settings.delete-account.step-downgrade.list.0') }}</li>
          <li>{{ $t('settings.delete-account.step-downgrade.list.1') }}</li>
        </ul>
      </div>

      <div class="d-flex justify-end">
        <v-btn
          color="primary"
          text
          :disabled="!!pendingDowngrade?.downgradeAt"
          @click="dialog = true"
        >
          <v-icon
            class="mr-2"
          >
            mdi-swap-horizontal
          </v-icon>
          {{ $t('buttons.step-downgrade.label') }}
        </v-btn>
      </div>
    </v-alert>
  </div>
</template>

<script>
import brandingMixin from '@/mixins/branding'
import ChangePackage from '../../account/ChangePackage.vue'
import GET_PENDING_DOWNGRADE from '../../account/queries/GetPendingDowngrade.gql'

export default {
  components: { ChangePackage },
  mixins: [brandingMixin],
  props: {
    company: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      dialog: false
    }
  },
  methods: {
    async fetchPendingDowngrade () {
      this.$apollo.queries.pendingDowngrade.refetch()
    }
  },
  apollo: {
    pendingDowngrade: {
      query: GET_PENDING_DOWNGRADE,
      variables () {
        return {
          companyId: this.$auth.user.companyId
        }
      }
    }
  }
}
</script>
<style scoped>
.no-bullets {
  list-style-type: none;
  padding-left: 0;
}
</style>
