<template>
  <v-dialog
    v-model="dialog"
    transition="dialog-bottom-transition"
    max-width="350"
    persistent
  >
    <template #activator="{ on }">
      <div class="d-flex">
        <v-btn
          class="primary white--text"
          :rounded="false"
          icon
          small
          v-on="on"
        >
          <v-icon>
            mdi-pencil
          </v-icon>
        </v-btn>
      </div>
    </template>
    <v-card>
      <v-card-title
        class="pa-0"
      >
        <v-toolbar
          color="primary"
          dark
          flat
        >
          <v-toolbar-title>
            {{ title }}
          </v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn
              icon
              dark
              @click="onAbort"
            >
              <v-icon large>
                mdi-close
              </v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
      </v-card-title>

      <v-card-text
        class="mt-4"
      >
        <v-color-picker
          v-model.lazy="model"
          mode="hexa"
          hide-mode-switch
          v-on="$listeners"
        />

        <v-alert
          v-if="!hasSufficientContrast"
          type="error"
        >
          {{ $t('alerts.color-dialog.error') }}
        </v-alert>
      </v-card-text>

      <v-card-actions class="justify-end">
        <v-btn
          text
          @click="onAbort"
        >
          {{ $t('labels.cancel') }}
        </v-btn>
        <v-btn
          color="primary"
          :disabled="!hasSufficientContrast"
          @click="saveColor"
        >
          {{ $t('labels.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: '#000000'
    },
    title: {
      type: String,
      default: 'Farbe anpassen'
    },
    abort: {
      type: Function,
      default: () => {}
    },
    save: {
      type: Function,
      default: () => {}
    },
    showDialog: {
      type: Boolean,
      default: false
    },
    hasSufficientContrast: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      model: this.value,
      dialog: this.showDialog
    }
  },
  watch: {
    showDialog () {
      this.dialog = this.showDialog
    },
    value () {
      this.model = this.value
    }
  },
  methods: {
    async saveColor () {
      await this.save(this.model)
      this.dialog = false
    },
    async onAbort () {
      await this.abort()
      this.dialog = false
    }
  }
}
</script>
