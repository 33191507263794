<template>
  <v-alert>
    <span class="font-weight-bold">{{ $t('settings.delete-account.title') }}<br></span>

    <div class="black--text">
      <p>
        {{ $t('settings.delete-account.subtitle') }}
      </p>
      <p v-if="!canDeleteAccount">
        <b>{{ $t('settings.delete-account.body-texts.0') }}: </b>{{ $t('settings.delete-account.body-texts.1', { date: formatDate(endDate,'DATE_LONG') }) }}
      </p>
    </div>

    <div class="d-flex justify-end">
      <Dialog
        v-if="canDeleteAccount"
        action="delete"
        :title="$t('settings.delete-account.dialog.title')"
        :content="$t('settings.delete-account.dialog.content', { readablePlatformName })"
        :submit="() => deleteAccount()"
        color="error"
      >
        <v-btn
          color="error"
          small
          :disabled="!canDeleteAccount"
          @click="deleteAccount"
        >
          <v-icon
            small
            class="mr-2"
          >
            mdi-delete
          </v-icon>
          {{ $t('buttons.delete-account.cta') }}
        </v-btn>
      </Dialog>
    </div>
  </v-alert>
</template>

<script>
import SubscriptionState from '../enums/SubscriptionState'
import brandingMixin from '@/mixins/branding'

import Dialog from '@/components/forms/Dialog.vue'

export default {
  components: { Dialog },
  mixins: [brandingMixin],
  props: {
    subscriptionState: {
      type: String,
      default: ''
    },
    endDate: {
      type: Date,
      default: null
    }
  },
  computed: {
    canDeleteAccount () {
      return this.subscriptionState === SubscriptionState.INACTIVE
    }
  },
  methods: {
    deleteAccount () {
      // TODO send delete prompt to bottimmo-service
    }
  }
}
</script>
