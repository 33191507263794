<template>
  <v-row class="mx-0 my-2 mx-md-2">
    <v-col
      cols="12"
      sm="6"
    >
      <div>
        <span class="font-weight-bold">
          {{ $t('settings.crm.flowfact-filter.title') }}<br>
        </span>
        <p class="pa-0 ma-0 grey--text">
          {{ $t('settings.crm.flowfact-filter.body-texts.0') }}<br>
          {{ $t('settings.crm.flowfact-filter.body-texts.1') }}
        </p>
        <p class="mt-2 grey--text">
          <b>{{ $t('labels.caution') }}:</b> {{ $t('settings.crm.flowfact-filter.body-texts.2') }}
          {{ $t('settings.crm.flowfact-filter.body-texts.3') }}<br>
        </p>
      </div>
    </v-col>
    <v-col
      cols="12"
      offset-md="1"
      md="5"
      class="pt-2 pt-md-8"
    >
      <v-select
        v-model="selectedContactTypes"
        :items="contactTags"
        :label="$t('labels.tags')"
        multiple
        outlined
        chips
        :hint="$t('settings.crm.flowfact-filter.labels.select')"
        persistent-hint
        :disabled="countIsLoading"
        @blur="updateContactTypes"
      />
      <v-alert
        v-if="countIsLoading || selectedContactTypes.length > 0"
        type="info"
        dense
        text
      >
        <span v-if="countIsLoading">
          <v-progress-circular
            :size="20"
            :width="3"
            class="px-4"
            indeterminate
          />
          {{ $t('settings.crm.flowfact-filter.info-texts.0') }}
        </span>
        <div v-else>
          <p>{{ $t('settings.crm.flowfact-filter.info-texts.1', { max: flowfactContactsCount.maxCount }) }}</p>

          <p v-if="moreTagsThanContacts">
            {{ $t('settings.crm.flowfact-filter.info-texts.2') }}
          </p>
          <p>
            {{ $t('settings.crm.flowfact-filter.info-texts.3') }}<br>
            {{ $t('settings.crm.flowfact-filter.info-texts.4', { readablePlatformName }) }}<br>
            {{ $t('settings.crm.flowfact-filter.info-texts.5') }}
          </p>
        </div>
      </v-alert>
    </v-col>
  </v-row>
</template>

<script>
import bus, { eventNames } from '@/lib/eventBus'
import SAVE_FLOWFACT_SELECTED_CONTACT_STATUSES from './queries/SaveFlowfactSelectedContactStatuses.gql'
import FLOWFACT_CONTACT_STATUSES from './queries/FlowfactContactTags.gql'
import GET_FLOWFACT_CONFIG from './queries/FlowfactConfig.gql'
import FLOWFACT_CONTACT_COUNT from './queries/FlowfactContactCount.gql'
import brandingMixin from '@/mixins/branding'

export default {
  mixins: [brandingMixin],
  data () {
    return {
      isLoading: false,
      contactStatuses: [],
      selectedContactTypes: []
    }
  },
  computed: {
    countIsLoading () {
      return !this.flowfactContactsCount
    },
    totalCount () {
      return Object.values(this.flowfactContactsCount?.countPerStatus || []).reduce((acc, cur) => acc + cur, 0)
    },
    contactTags () {
      return [
        { text: this.$t('settings.crm.flowfact-filter.labels.all'), value: 'ALL' },
        { divider: true },
        { text: this.$t('settings.crm.flowfact-filter.labels.no-tags'), value: 'UNDEFINED' },
        ...this.contactStatuses
      ].map(this.addCount)
    },
    moreTagsThanContacts () {
      return this.flowfactContactsCount?.count > this.flowfactContactsCount?.maxCount
    }
  },
  watch: {
    selectedContactTypes: {
      handler (newValue, oldValue) {
        if (!oldValue.includes('ALL') && newValue.includes('ALL')) {
          this.selectedContactTypes = ['ALL']
        }
        if (oldValue.includes('ALL') && newValue.length > 1) {
          this.selectedContactTypes = this.selectedContactTypes.filter(item => item !== 'ALL')
        }
      },
      deep: true
    }
  },
  methods: {
    addCount ({ text, value }) {
      if (!value) return { divider: true }
      const count = value === 'ALL'
        ? this.flowfactContactsCount?.countPerStatus.ALL
        : (this.flowfactContactsCount?.countPerStatus[value] || 0)

      return {
        text: Number.isInteger(this.flowfactContactsCount?.count) ? `${text} (${count})` : `${text} (...)`,
        value
      }
    },
    async updateContactTypes () {
      this.isLoading = true
      try {
        await this.$apollo.mutate({
          mutation: SAVE_FLOWFACT_SELECTED_CONTACT_STATUSES,
          variables: {
            input: {
              selectedContactStatusIds: this.selectedContactTypes,
              companyId: this.$auth.user.companyId
            }
          }
        })
        bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'success', text: this.$t('alerts.settings.crm.flowfact-filter.success') })
      } catch (e) {
        await this.$apollo.queries.flowfactConfig.refetch()
        bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'error', text: this.$t('alerts.settings.crm.flowfact-filter.error') })
      } finally {
        this.$emit('isFilterSelected', this.selectedContactTypes.length > 0)
        this.isLoading = false
      }
    }
  },
  apollo: {
    contactStatuses: {
      query: FLOWFACT_CONTACT_STATUSES,
      variables () {
        return {
          companyId: this.$auth.user.companyId
        }
      },
      update: ({ flowfactContactTags }) => flowfactContactTags.map(({ id, name }) => ({ text: name, value: id }))
    },
    flowfactConfig: {
      query: GET_FLOWFACT_CONFIG,
      variables () {
        return {
          companyId: this.$auth.user.companyId
        }
      },
      update ({ flowfactConfig }) {
        this.selectedContactTypes = flowfactConfig.selectedContactStatusIds
        this.$emit('isFilterSelected', this.selectedContactTypes && this.selectedContactTypes.length > 0)
        return flowfactConfig
      }
    },
    flowfactContactsCount: {
      query: FLOWFACT_CONTACT_COUNT,
      debounce: 1000,
      variables () {
        return {
          input: {
            selectedContactStatusIds: this.selectedContactTypes,
            companyId: this.$auth.user.companyId
          }
        }
      }
    }
  }
}
</script>
