var render = function render(){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"currency",class:{ 'col-4': _vm.isMobile },style:(_vm.cssProps)},[_c('v-autocomplete',{style:({
      'width': _vm.isMobile ? '' : '85px',
      'border-radius': _vm.isMobile ? '8px !important' : '',
      'background-color': 'white',
      'border': `1px solid ${_vm.$vuetify.theme.themes.light.markero.blue}`,
      'color': _vm.$vuetify.theme.themes.light.markero.blue,
    }),attrs:{"items":_vm.currenciesSymbol,"outlined":"","flat":"","hide-details":""},on:{"change":_vm.updateCurrencySymbol,"mousedown":_vm.onClick,"blur":_vm.onBlur},model:{value:(_vm.selectedCurrencySymbol),callback:function ($$v) {_vm.selectedCurrencySymbol=$$v},expression:"selectedCurrencySymbol"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }