<template>
  <v-select
    v-model="selectedContactTypes"
    :items="contactStatusItems"
    :label="label || $t('labels.contact-filter.label')"
    :hint="hint || $t('labels.contact-filter.hint')"
    :disabled="countIsLoading"
    multiple
    outlined
    chips
    persistent-hint
    @blur="handleBlur"
  />
</template>

<script>
export default {
  props: {
    addCount: {
      type: Function,
      default: null
    },
    countIsLoading: {
      type: Boolean,
      default: false
    },
    hint: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    initialSelectedContactTypes: {
      type: Array,
      default: () => []
    },
    contactStatus: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      selectedContactTypes: this.initialSelectedContactTypes
    }
  },
  computed: {
    contactStatusItems () {
      return [
        { text: this.$t('labels.contact-filter.all'), value: 'ALL' },
        { divider: true },
        { text: this.$t('labels.contact-filter.no-status'), value: 'UNDEFINED' },
        ...this.contactStatus
      ].map(this.addCount)
    }
  },
  watch: {
    selectedContactTypes: {
      handler (newValue, oldValue) {
        if (!oldValue.includes('ALL') && newValue.includes('ALL')) {
          this.selectedContactTypes = ['ALL']
        }
        if (oldValue.includes('ALL') && newValue.length > 1) {
          this.selectedContactTypes = this.selectedContactTypes.filter(item => item !== 'ALL')
        }
      },
      deep: true
    },
    initialSelectedContactTypes: {
      handler (newValue) {
        this.selectedContactTypes = newValue
      },
      deep: true
    }
  },
  methods: {
    prepareTagName (name) {
      try {
        return name.split(':')[2]
      } catch (e) {
        return name
      }
    },
    handleBlur () {
      this.$emit('blur', this.selectedContactTypes)
    }
  }

}
</script>
