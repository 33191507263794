<template>
  <div>
    <BaseHeader
      :title="$t('settings.title')"
      :style-variant="6"
      icon="mdi-cog"
    >
      <template v-slot:design>
        <HeaderDesign />
      </template>

      <template v-slot:desc>
        <p>
          {{ $t('settings.subtitle') }}
        </p>
      </template>
    </BaseHeader>

    <v-alert
      v-if="showRegeneration"
      border="left"
      type="warning"
    >
      <v-row
        class="py-1"
        :class="{'pl-4':$vuetify.breakpoint.smAndDown}"
      >
        {{ $t('alerts.settings.show-regeneration') }}
      </v-row>
      <v-row
        class="justify-end"
        :class="{'justify-center':$vuetify.breakpoint.smAndDown}"
      >
        <Feature :feature-slug="featureNames.BILLWERK">
          <template>
            <Regenerate
              v-if="company"
              :company="company"
            />
          </template>
          <template v-slot:inactive>
            <v-btn
              class="mx-4 mb-4"
              :width="$vuetify.breakpoint.mdAndDown ? '100%': '350px' "
              color="primary"
              to="/support"
            >
              {{ $t('buttons.settings.contact-support') }}
            </v-btn>
          </template>
        </Feature>
      </v-row>
    </v-alert>

    <v-card
      outlined
      class="px-2 pt-2 px-md-4"
    >
      <div>
        <v-tabs
          v-model="section"
          show-arrows
        >
          <v-tab
            v-for="{name} in sections"
            :key="name"
          >
            {{ $t(name) }}
          </v-tab>
        </v-tabs>

        <v-divider />

        <v-tabs-items
          v-model="section"
          class="pt-4"
        >
          <v-tab-item
            v-for="{name, component} in sections"
            :key="name"
          >
            <component :is="component" />
          </v-tab-item>
        </v-tabs-items>
      </div>
    </v-card>
  </div>
</template>

<script>
import FunctionalSettings from './functionalSettings'
import CompanySettings from './companySettings'
import AccountSettings from './accountSettings'
import PaymentSettings from './paymentSettings'
import EmailSettings from './emailSettings'
import CrmSettings from './crmSettings'
import BaseHeader from '@/components/BaseHeader.vue'
import HeaderDesign from './HeaderDesign.vue'
import Regenerate from '@/modules/company/dialogs/Regenerate.vue'

import PENDING_CHANGES_FOR_REGENERATION from '@/queries/PendingChangesForRegeneration.gql'
import COMPANY from '@/modules/company/queries/Company.gql'

import featureMixin from '@/mixins/feature'
import Permission from '@/components/Permission'
import featureNames from '@/lib/featureNames'
import UserSettings from './userSettings'
import ProfileSettings from './profileSettings'

export default {
  components: {
    FunctionalSettings,
    CompanySettings,
    AccountSettings,
    PaymentSettings,
    EmailSettings,
    CrmSettings,
    BaseHeader,
    HeaderDesign,
    Permission,
    Regenerate
  },
  mixins: [featureMixin],
  data () {
    return {
      section: null,
      pendingChangesForRegeneration: {},
      sectionConfig: [
        {
          path: 'company',
          name: 'settings.company.name',
          permission: 'settings-company:read',
          component: CompanySettings
        },
        {
          path: 'features',
          name: 'settings.features.name',
          permission: 'settings-features:read',
          component: FunctionalSettings
        },
        {
          path: 'email',
          name: 'settings.email.name',
          permission: 'settings-email:read',
          feature: featureNames.HOPPERMATION,
          component: EmailSettings
        },
        {
          path: 'crm',
          name: 'settings.crm.name',
          permission: 'settings-crm:read',
          feature: featureNames.CRM,
          component: CrmSettings
        },
        {
          path: 'user',
          name: 'settings.user.name',
          permission: 'settings-user:read',
          component: UserSettings
        },
        {
          path: 'profile',
          name: 'settings.profile.name',
          permission: 'profile:read',
          component: ProfileSettings
        },
        {
          path: 'payment',
          name: 'settings.payment.name',
          permission: 'settings-subscription:read',
          feature: featureNames.BILLWERK,
          component: PaymentSettings
        },
        {
          path: 'contract',
          name: 'settings.contract.name',
          permission: 'settings-subscription:read',
          feature: featureNames.BILLWERK,
          component: AccountSettings
        }
      ]
    }
  },
  inject: ['role'],
  computed: {
    sections () {
      return this.sectionConfig
        .filter(({ show }) => show === undefined || show())
        .filter(({ permission }) => this.role.isAuthorizedFor(permission))
        .filter(({ feature }) => feature === undefined || this.isFeatureActive(feature))
    },
    paths () {
      return this.sections.map(section => section.path)
    },
    showRegeneration () {
      return this.isFeatureActive(this.featureNames.REGENERATION) && Object.keys(this.pendingChangesForRegeneration).length > 0
    }
  },
  watch: {
    section (section) {
      if (this.paths[section] !== this.$route.params.section) {
        this.$router.replace('/settings/' + this.paths[section] || this.paths[0])
      }
    },
    $route () {
      const index = this.paths.indexOf(this.$route.params.section)
      this.section = index > 0 ? index : 0
    }
  },
  created () {
    const index = this.paths.indexOf(this.$route.params.section)
    this.section = index > 0 ? index : 0
  },
  apollo: {
    company: {
      query: COMPANY,
      variables () {
        return {
          id: this.$auth.user.companyId
        }
      }
    },
    pendingChangesForRegeneration: {
      query: PENDING_CHANGES_FOR_REGENERATION,
      variables () {
        return { companyId: this.$auth.user.companyId }
      },
      skip () {
        return !this.role.isAuthorizedFor('settings-company:read')
      }
    }
  }
}
</script>
