export const Industries = [
  {
    header: 'product-finder.industries.beauty'
  },
  { value: 'Hairdresser / Barber', text: 'product-finder.industries.hairdresser-barber' },
  { value: 'Beautician / Cosmetologist', text: 'product-finder.industries.cosmetologist' },
  { value: 'Permanent Makeup', text: 'product-finder.industries.permanent-makeup' },
  { value: 'Tattoo and Piercing', text: 'product-finder.industries.tattoo-and-piercing' },
  { value: 'Hair Removal and Laser', text: 'product-finder.industries.hair-removal-and-laser' },
  { value: 'Nail Design', text: 'product-finder.industries.nail-design' },
  { value: 'Makeup Artist', text: 'product-finder.industries.makeup-artist' },
  { value: 'Cosmetic Surgeon', text: 'product-finder.industries.cosmetic-surgeon' },
  { value: 'Tanning Salon / Solarium', text: 'product-finder.industries.tanning-salon-solarium' },
  { value: 'Dental Hygienist', text: 'product-finder.industries.dental-hygienist' },
  { value: 'Other beauty', text: 'product-finder.industries.other' },
  {
    divider: true
  },
  {
    header: 'product-finder.industries.educational-services'
  },
  { value: 'School', text: 'product-finder.industries.school' },
  { value: 'College / University', text: 'product-finder.industries.college-university' },
  { value: 'Kindergarten', text: 'product-finder.industries.kindergarten' },
  { value: 'Childcare', text: 'product-finder.industries.childcare' },
  { value: 'Boarding School', text: 'product-finder.industries.boarding-school' },
  { value: 'Private Further and Continuing Education', text: 'product-finder.industries.private-further-continuing-education' },
  { value: 'Vocational Training', text: 'product-finder.industries.vocational-training' },
  { value: 'Online Courses & E-Learning', text: 'product-finder.industries.online-courses-e-learning' },
  { value: 'Tutoring and Private Lessons', text: 'product-finder.industries.tutoring-and-private-lessons' },
  { value: 'Music, Dance, and Drama Schools', text: 'product-finder.industries.music-dance-and-drama-schools' },
  { value: 'Language School, Language Trips', text: 'product-finder.industries.language-school-language-trips' },
  { value: 'Other educational services', text: 'product-finder.industries.other' },
  {
    divider: true
  },

  {
    header: 'product-finder.industries.coaching'
  },

  { value: 'Personality Coaching', text: 'product-finder.industries.personality-coaching' },
  { value: 'Business Coaching', text: 'product-finder.industries.business-coaching' },
  { value: 'Life Coaching', text: 'product-finder.industries.life-coaching' },
  { value: 'Health and Wellness Coaching', text: 'product-finder.industries.health-and-wellness-coaching' },
  { value: 'Career Coaching', text: 'product-finder.industries.career-coaching' },
  { value: 'Executive Coaching', text: 'product-finder.industries.executive-coaching' },
  { value: 'Team Coaching', text: 'product-finder.industries.team-coaching' },
  { value: 'Relationship Coaching', text: 'product-finder.industries.relationship-coaching' },
  { value: 'Financial Coaching', text: 'product-finder.industries.financial-coaching' },
  { value: 'Other coaching', text: 'product-finder.industries.other' },
  {
    divider: true
  },
  {
    header: 'product-finder.industries.power-engineering-renewable-energies'
  },

  { value: 'Renewable Energies', text: 'product-finder.industries.renewable-energies' },
  { value: 'Electrical Energy Engineering', text: 'product-finder.industries.electrical-energy-engineering' },
  { value: 'Energy Efficiency and Management', text: 'product-finder.industries.energy-efficiency-and-management' },
  { value: 'Network Technology and Management', text: 'product-finder.industries.network-technology-and-management' },
  { value: 'Environmental Technology', text: 'product-finder.industries.environmental-technology' },
  { value: 'Other', text: 'product-finder.industries.other' },
  {
    divider: true
  },
  {
    header: 'product-finder.industries.financial-services'
  },

  { value: 'Financial Advisory', text: 'product-finder.industries.financial-advisory' },
  { value: 'Accounting', text: 'product-finder.industries.accounting' },
  { value: 'Insurance', text: 'product-finder.industries.insurance' },
  { value: 'Mortgages', text: 'product-finder.industries.mortgages' },
  { value: 'Debt Counseling and Insolvency Services', text: 'product-finder.industries.debt-counseling-and-insolvency-services' },
  { value: 'Lawyer', text: 'product-finder.industries.lawyer' },
  { value: 'Other financial services', text: 'product-finder.industries.other' },
  {
    divider: true
  },
  {
    header: 'product-finder.industries.fitness-wellness'
  },
  { value: 'Gym', text: 'product-finder.industries.gym' },
  { value: 'Personal Training', text: 'product-finder.industries.personal-training' },
  { value: 'Day Spa', text: 'product-finder.industries.day-spa' },
  { value: 'Health and Fitness Courses', text: 'product-finder.industries.health-and-fitness-courses' },
  { value: 'Sports and Fitness Events', text: 'product-finder.industries.sports-and-fitness-events' },
  { value: 'Other fitness wellness', text: 'product-finder.industries.other' },
  {
    divider: true
  },
  {
    header: 'product-finder.industries.gastronomy'
  },

  { value: 'Restaurant / Bar / Café', text: 'product-finder.industries.restaurant-bar-café' },
  { value: 'Food Truck / Snack Bar', text: 'product-finder.industries.food-truck-snack-bar' },
  { value: 'Bakery / Confectionery', text: 'product-finder.industries.bakery-confectionery' },
  { value: 'Catering', text: 'product-finder.industries.catering' },
  { value: 'Canteen', text: 'product-finder.industries.canteen' },
  { value: 'Club / Disco', text: 'product-finder.industries.club-disco' },
  { value: 'Other gastronomy', text: 'product-finder.industries.other' },
  {
    divider: true
  },
  {
    header: 'product-finder.industries.health-care'
  },
  { value: 'Private Clinic', text: 'product-finder.industries.private-clinic' },
  { value: 'Nursing Home', text: 'product-finder.industries.nursing-home' },
  { value: 'Assisted Living', text: 'product-finder.industries.assisted-living' },
  { value: 'Alternative Medicine and Naturopath', text: 'product-finder.industries.alternative-medicine-and-naturopath' },
  { value: 'Pharmacy', text: 'product-finder.industries.pharmacy' },
  { value: 'Medical Products Supplier', text: 'product-finder.industries.medical-products-supplier' },
  { value: 'Sanitary Products Supplier', text: 'product-finder.industries.sanitary-products-supplier' },
  { value: 'Medical Supplies Store', text: 'product-finder.industries.medical-supplies-store' },
  { value: 'Home Care Service', text: 'product-finder.industries.home-care-service' },
  { value: 'Physiotherapy', text: 'product-finder.industries.physiotherapy' },
  { value: 'Occupational Therapy', text: 'product-finder.industries.occupational-therapy' },
  { value: 'Osteopathy', text: 'product-finder.industries.osteopathy' },
  { value: 'Chiropractic', text: 'product-finder.industries.chiropractic' },
  { value: 'Acupuncture', text: 'product-finder.industries.acupuncture' },
  { value: 'Prenatal Classes / Midwife', text: 'product-finder.industries.prenatal-classes-midwife' },
  { value: 'Other health care', text: 'product-finder.industries.other' },
  {
    divider: true
  },
  {
    header: 'product-finder.industries.ngos'
  },
  { value: 'General Aid Organization', text: 'product-finder.industries.general-aid-organization' },
  { value: 'Environmental Protection Organization', text: 'product-finder.industries.environmental-protection-organization' },
  { value: 'Animal Aid Organization', text: 'product-finder.industries.animal-aid-organization' },
  { value: "Children's Aid Organization", text: 'product-finder.industries.children-aid-organization' },
  { value: 'Humanitarian Aid Organization', text: 'product-finder.industries.humanitarian-aid-organization' },
  { value: 'Emergency Relief and Preparedness', text: 'product-finder.industries.emergency-relief-and-preparedness' },
  { value: 'Development Organization', text: 'product-finder.industries.development-organization' },
  { value: 'Other ngos', text: 'product-finder.industries.other' },
  {
    divider: true
  },
  {
    header: 'product-finder.industries.legal-services'
  },
  { value: 'Media and Copyright Law', text: 'product-finder.industries.media-and-copyright-law' },
  { value: 'Real Estate Law', text: 'product-finder.industries.real-estate-law' },
  { value: 'Inheritance Law', text: 'product-finder.industries.inheritance-law' },
  { value: 'Family Law', text: 'product-finder.industries.family-law' },
  { value: 'Labor Law', text: 'product-finder.industries.labor-law' },
  { value: 'Administrative Law', text: 'product-finder.industries.administrative-law' },
  { value: 'Civil Law', text: 'product-finder.industries.civil-law' },
  { value: 'Criminal Law', text: 'product-finder.industries.criminal-law' },
  { value: 'Constitutional Law', text: 'product-finder.industries.constitutional-law' },
  { value: 'Other legal services', text: 'product-finder.industries.other' },
  {
    divider: true
  },
  {
    header: 'product-finder.industries.travel-hospitality'
  },
  { value: 'Sports Hotel', text: 'product-finder.industries.sports-hotel' },
  { value: 'Wellness Hotel', text: 'product-finder.industries.wellness-hotel' },
  { value: 'Theme Hotel', text: 'product-finder.industries.theme-hotel' },
  { value: 'Family Hotel', text: 'product-finder.industries.family-hotel' },
  { value: 'Adults Only Hotel', text: 'product-finder.industries.adults-only-hotel' },
  { value: 'Spa / Bath', text: 'product-finder.industries.spa-bath' },
  { value: 'Amusement Park', text: 'product-finder.industries.amusement-park' },
  { value: 'Outdoor / Indoor Playground', text: 'product-finder.industries.outdoor-indoor-playground' },
  { value: 'Adventure Park', text: 'product-finder.industries.adventure-park' },
  { value: 'Other Leisure Facility', text: 'product-finder.industries.other-leisure-facility' },
  { value: 'Cinema', text: 'product-finder.industries.cinema' },
  { value: 'Diving School / Water Sports School', text: 'product-finder.industries.diving-school-water-sports-school' },
  { value: 'Travel Agency', text: 'product-finder.industries.travel-agency' },
  { value: 'Tour Operator', text: 'product-finder.industries.tour-operator' },
  { value: 'Other travel hospitality', text: 'product-finder.industries.other' },
  {
    divider: true
  },
  {
    header: 'product-finder.industries.theater-or-museum'
  },
  { value: 'Museum', text: 'product-finder.industries.museum' },
  { value: 'Gallery', text: 'product-finder.industries.gallery' },
  { value: 'Theater / Opera House', text: 'product-finder.industries.theater-opera-house' },
  { value: 'Auction House', text: 'product-finder.industries.auction-house' },
  { value: 'Artist Collective or Independent Artist', text: 'product-finder.industries.artist-collective-or-independent-artist' },
  { value: 'Arts and Crafts Design', text: 'product-finder.industries.arts-and-crafts-design' },
  { value: 'Other theater or museum', text: 'product-finder.industries.other' },
  {
    divider: true
  },
  {
    header: 'product-finder.industries.events'
  },
  { value: 'Wedding Planner', text: 'product-finder.industries.wedding-planner' },
  { value: 'Event Planner', text: 'product-finder.industries.event-planner' },
  { value: 'Event Location', text: 'product-finder.industries.event-location' },
  { value: 'CoWorking Space', text: 'product-finder.industries.coworking-space' },
  { value: 'Photographer', text: 'product-finder.industries.photographer' },
  { value: 'Funeral Director', text: 'product-finder.industries.funeral-director' },
  { value: 'Wedding Officiant', text: 'product-finder.industries.wedding-officiant' },
  { value: 'Funeral Speaker', text: 'product-finder.industries.funeral-speaker' },
  { value: 'Other events', text: 'product-finder.industries.other' },
  {
    divider: true
  },
  {
    header: 'product-finder.industries.npos'
  },
  { value: 'Sports Club', text: 'product-finder.industries.sports-club' },
  { value: 'Music Association', text: 'product-finder.industries.music-association' },
  { value: 'Leisure Club', text: 'product-finder.industries.leisure-club' },
  { value: 'Animal / Nature Conservation Association', text: 'product-finder.industries.animal-nature-conservation-association' },
  { value: 'Social Club', text: 'product-finder.industries.social-club' },
  { value: 'Rescue and Social Services Association', text: 'product-finder.industries.rescue-and-social-services-association' },
  { value: 'Cultural Association', text: 'product-finder.industries.cultural-association' },
  { value: 'Supporting / Sponsoring Association', text: 'product-finder.industries.supporting-sponsoring-association' },
  { value: 'Religious Association', text: 'product-finder.industries.religious-association' },
  { value: 'Hobby Club', text: 'product-finder.industries.hobby-club' },
  { value: 'Other npos', text: 'product-finder.industries.other' },
  {
    divider: true
  },
  {
    header: 'product-finder.industries.other'
  },
  { value: 'Crafts', text: 'product-finder.industries.crafts' },
  { value: 'Kitchen Construction', text: 'product-finder.industries.kitchen-construction' },
  { value: 'Photovoltaic Systems', text: 'product-finder.industries.photovoltaic-systems' },
  { value: 'Heating Installation', text: 'product-finder.industries.heating-installation' },
  { value: 'Plumbing', text: 'product-finder.industries.plumbing' },
  { value: 'Landscaping', text: 'product-finder.industries.landscaping' },
  { value: 'Architect', text: 'product-finder.industries.architect' }
]
